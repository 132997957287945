import { Box, Button, Dialog, Typography, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

interface Props {
  showModal: boolean;
  handleAction: (value: 'save' | 'cancel' | 'discard') => void;
  message: string;
}

const UnSavedChanges = ({ showModal, handleAction, message }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={showModal}
      onClose={undefined}
      maxWidth="xl"
      PaperProps={{ style: styles.dialogStyle }}
    >
      <Typography className={classes.header}>Unsaved Changes</Typography>
      <Typography className={classes.label}>{message}</Typography>
      <Box className={classes.footerRow}>
        <Button
          variant="contained"
          onClick={() => handleAction('cancel')}
          className={classes.cancel}
          disableElevation
        >
          Cancel
        </Button>
        <Box className={classes.rightContent}>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => handleAction('save')}
            className={classes.saveButton}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleAction('discard')}
            className={classes.discardButton}
          >
            Leave & Discard
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles({
  header: {
    fontSize: 18,
    color: colors.black,
    fontWeight: 600,
    paddingBottom: 12,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    paddingBottom: 28,
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightContent: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 6,
  },
  cancel: {
    fontSize: 13,
    color: colors.black,
    fontWeight: 500,
    borderRadius: 2,
    height: 32,
  },
  saveButton: {
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 2,
    height: 32,
  },
  discardButton: {
    fontSize: 13,
    color: colors.white,
    fontWeight: 500,
    borderRadius: 2,
    height: 32,
  },
});

const styles = {
  dialogStyle: { minWidth: 524, height: 160, padding: '20px 21px 20px 23px' },
};

export default UnSavedChanges;
