import React from 'react';
import { Modal, Box, Button, Grid, Typography, TextField, createStyles, makeStyles, Theme } from '@material-ui/core';

interface AddLabelModalProps {
  open: boolean;
  onClose: () => void;
  newLabel: string;
  setNewLabel: (value: string) => void;
  addLabel: () => void;
}

const AddLabelModal: React.FC<AddLabelModalProps> = ({ open, onClose, newLabel, setNewLabel, addLabel }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
        <Grid item xs={8} sm={5} md={3}>
          <Box className={classes.modalContent}>
            <Typography variant="h6" component="h2" gutterBottom>
              Add New Label
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="Label"
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
              className={classes.input}
            />
            <Box className={classes.buttonContainer}>
              <Button onClick={onClose} color="primary" className={classes.cancelButton}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={addLabel}>
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      minHeight: '100vh',
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      width: '100%',
    },
    input: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      marginRight: theme.spacing(2),
    },
  })
);

export default AddLabelModal;
