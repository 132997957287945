import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { ChangeEvent, ReactNode, useState } from 'react';
import { colors } from 'utils/colors';

interface Props {
  header: string;
  children: ReactNode;
  autoHeight?: boolean;
  styles?: React.CSSProperties;
}

const AccordionCard = ({ header, children, autoHeight, styles }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleChange = (event: ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion
      elevation={0}
      className={classes.accordion}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
        className={classes.accordionSummary}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.headerText}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={
          autoHeight
            ? [classes.accordionDetails, classes.layoutDetails].join(' ')
            : classes.accordionDetails
        }
        style={styles}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles({
  accordion: {
    backgroundColor: colors.gray99,
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    pointerEvents: 'none',
    gap: 4,
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: '16px 0',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
    },
  },
  headerText: {
    fontWeight: 500,
    color: colors.black,
    fontSize: 15,
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    marginLeft: 26,
    paddingBottom: 20,
  },
  layoutDetails: {
    paddingTop: 0,
    marginLeft: 24,
    paddingBottom: 0,
  },
  expandIcon: {
    pointerEvents: 'auto',
  },
  iconButton: {
    marginLeft: 10,
    pointerEvents: 'auto',
  },
});

export default AccordionCard;
