import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import {
  Button,
  Tooltip,
  makeStyles,
  Typography,
  Box,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import styled from 'styled-components';
import { useConfig } from '../../../../context/ConfigContext';
import { CheckboxInput } from '../../../../components/FormElements';
import { errorMsg, successMsg, warningMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import { MaterialTableRef } from '../../../../components/EditableTable';
import { useBlocker, useParams } from 'react-router-dom';
import { useCustomPageAPI } from '../../../../context/fakeAPIHooks/useCustomPageAPI';
import { V2GroupComponentDef, V2PageTemplate } from '@terragotech/page-renderer';
import { PageEditor, pageEditorRef } from '../components/CustomPageEditor/PageEditor';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCommonStyles from 'views/useCommonStyles';
import {
  faSave,
  faXmark,
  faDisplayCode,
  faTableRows,
  faTableColumns,
  faFilePlus,
  faFileMinus,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import useRouteBlocker from 'common/useBlocker';
import { TextInputTG } from 'views/components/formElements';
import { useConfirmDialog } from 'context/ConfirmContext';
import CustomPagesActions from '../components/CustomPagesActions';
import { FabAction } from 'utils/types';
import { CONFIRMATION } from 'utils/Utils';
import usePageRedirect from 'components/PageDialog/hooks/usePageRedirect';

interface PromiseResult {
  success: boolean;
}

export interface CustomPageEditorRef {
  save: () => Promise<PromiseResult>;
  handleCancelChanges: () => Promise<PromiseResult>;
  onClose: (value: { handleSubmit: () => Promise<void> }) => Promise<void>;
}
// @ts-ignore:next-line
export const CustomPageEditor: React.FC<{
  editPage?: V2PageTemplate;
  aggType?: string;
  fullPage?: V2PageTemplate;
  ref?: React.Ref<CustomPageEditorRef | undefined>;
  modalView?: boolean;
}> = React.forwardRef(({ editPage, aggType, fullPage, modalView = false }, ref) => {
  const { config } = useConfig();
  const CustomPageAPI = useCustomPageAPI();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [fabExpanded, setFabExpanded] = useState(false);
  const useClasses = useCommonStyles();
  const { customPage: pageName } = useParams() as {
    customPage: string;
  };
  const tableRef = useRef<MaterialTableRef>(null);
  const [reset, setReset] = useState<number>(0);
  const dataMapRef = useRef<pageEditorRef>();
  const page = editPage ? cloneDeep(editPage) : config.pagesConfig?.[pageName]; // TODO: API get? Requires async.
  const { openConfirmation } = useConfirmDialog();
  const { isForceLeaveConfirmed } = usePageRedirect();

  const pageDefault = {
    rows: 1,
    columns: 1,
    allowDynamicSizing: true,
    elements: {},
    fabActions: [],
    isPublic: false,
  };
  const [pageDefinition, setPageDefinition] = useState<V2PageTemplate>(
    cloneDeep(page || pageDefault)
  );
  const [rows, setRows] = useState(editPage?.rows || pageDefault.rows);
  const [columns, setColumns] = useState(editPage?.columns || pageDefault.columns);
  const [allowDynamicSizing, setAllowDynamicSizing] = useState(
    editPage?.allowDynamicSizing || pageDefault.allowDynamicSizing
  );
  const [aggregateType, setAggregateType] = useState(aggType || '');
  const [fabActions, setFabActions] = useState(editPage?.fabActions || pageDefault.fabActions);
  const [firstRun, setFirstRun] = useState(true);
  const [isPublic, setIsPublic] = useState(page?.isPublic || pageDefault.isPublic);
  const [infoPopover, setInfoPopover] = useState(page?.infoPopover || '');
  const setPropertyStates = (state: {
    rows: number;
    columns: number;
    allowDynamicSizing: boolean;
    aggregateType?: string;
    fabActions?: FabAction[];
    page: V2PageTemplate;
    isPublic?: boolean;
    infoPopover?: string;
  }) => {
    const {
      rows,
      columns,
      allowDynamicSizing,
      aggregateType,
      fabActions,
      page,
      isPublic,
      infoPopover,
    } = state;
    setRows(rows);
    setColumns(columns);
    setAllowDynamicSizing(allowDynamicSizing);
    setAggregateType(aggregateType ?? '');
    setFabActions(fabActions ?? []);
    setPageDefinition(page);
    setIsPublic(isPublic ?? false);
    setInfoPopover(infoPopover ?? '');
  };

  useEffect(() => {
    if (page && !editPage) {
      setPropertyStates({ ...page, page: (page && cloneDeep(page)) || pageDefault });
      setReset((prev) => prev + 1);
    }
  }, [pageName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page?.rows && !firstRun) {
      setPageDefinition((p) => {
        const copy = cloneDeep(p);
        copy.rows = rows;
        copy.columns = columns;
        copy.allowDynamicSizing = allowDynamicSizing;
        copy.aggregateType = aggregateType;
        copy.fabActions = fabActions;
        copy.isPublic = isPublic;
        copy.infoPopover = infoPopover;
        return copy;
      });
    } else {
      setFirstRun(false);
    }
  }, [
    rows,
    columns,
    allowDynamicSizing,
    aggregateType,
    fabActions,
    setPageDefinition,
    setFirstRun,
    isPublic,
    firstRun,
    page?.rows,
    infoPopover,
  ]);

  const hasMap = (template?: V2PageTemplate) => {
    if (!template) {
      return false;
    }
    const keys = Object.keys(template.elements);
    for (let i = 0; i < keys.length; i++) {
      const comp = template.elements[keys[i]].component;
      if (comp.type === 'aggregateMap') {
        return true;
      }
      if (comp.type === 'group' && hasMap(comp.template)) {
        return true;
      }
    }
    return false;
  };

  const save = async () => {
    const result: PromiseResult = { success: true };
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow) {
      warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );
      result.success = false;
      return result;
    }

    const elementEntries = Object.entries(pageDefinition.elements);
    const hasEmptyGridAreas = [...Array(pageDefinition.rows).keys()].reduce(
      (rAcc, gaRow) =>
        [...Array(pageDefinition.columns).keys()].reduce((cAcc, gaColumn) => {
          return (
            cAcc ||
            // No element in this grid area
            (!elementEntries.find(([_, e]) => e.row === gaRow && e.column === gaColumn) &&
              // No element spanning this grid area (does not account for rowSpan)
              !elementEntries.find(
                ([_, e]) =>
                  e.row === gaRow && e.column < gaColumn && e.column + e.columnSpan > gaColumn
              ) &&
              // One or more elements after this grid area
              elementEntries.some(
                ([_, e]) => e.row > gaRow || (e.row === gaRow && e.column > gaColumn)
              ))
          );
        }, rAcc),
      false
    );
    if (hasEmptyGridAreas) {
      errorMsg('The page editor cannot have empty spaces before elements.');
      result.success = false;
      return result;
    }

    if (!editPage) {
      const { error } = await CustomPageAPI.updatePage(
        pageName,
        cleanPageTemplate({
          ...pageDefinition,
          columns,
          rows,
          isPublic,
          infoPopover,
        })
      );
      if (error) {
        result.success = false;
        return result;
      }
      successMsg('The custom page has been saved');
    } else {
      editPage.columns = columns;
      editPage.rows = rows;
      editPage.elements = pageDefinition.elements;
    }
    return result;
  };

  const cleanPageTemplate = (template: V2PageTemplate): V2PageTemplate => {
    //Given a page template, recusively remove any components that aren't used (based on the row and column attributes)
    const keptElements: V2PageTemplate['elements'] = {};
    // cycle through elements and add any within range found there to the keptElements
    Object.entries(template.elements).forEach(([key, e]) => {
      if (e.component.type === 'group') {
        const newElement = { ...e, component: e.component as V2GroupComponentDef };
        newElement.component.template = cleanPageTemplate(e.component.template);
        keptElements[key] = newElement;
      } else if (e.row < template.rows && e.column < template.columns) {
        keptElements[key] = { ...e };
      }
    });
    // if the component is a group type, run its template through this

    return {
      rows: template.rows,
      columns: template.columns,
      isPublic: template.isPublic,
      infoPopover:
        hasMap(template) && template.fabActions?.length ? template.infoPopover || '' : '',
      allowDynamicSizing: template.allowDynamicSizing,
      ...(aggregateType && { aggregateType }),
      ...(template.errorMaps && { errorMaps: template.errorMaps }),
      ...(template.warningMaps && { warningMaps: template.warningMaps }),
      ...(template.initialDataMap && { initialDataMap: template.initialDataMap }),
      elements: keptElements,
      fabActions: template.fabActions || [],
    };
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname && page && !editPage) {
      const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
      const result =
        !isEqual(cleanPageTemplate(pageDefinition), cleanPageTemplate(page)) ||
        Boolean(lastEditingRow || showAddRow);
      return result;
    }
    return false;
  });

  useRouteBlocker({ blocker, onSave: save });

  const handleCancelChanges = async () => {
    const result: PromiseResult = { success: true };
    try {
      const status = await openConfirmation(CONFIRMATION.commonCancel);
      if (status === 'confirm') {
        if (page) {
          setPropertyStates({ ...page, page });
        }
        setReset((prev) => prev + 1);
      } else {
        result.success = false;
      }
    } catch (error) {
      result.success = false;
    } finally {
      return result;
    }
  };

  const handleClearDataMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      if (dataMapRef.current) {
        dataMapRef.current.handleClearInitialDataMapper();
      }
    }
  };

  const isModalDirty = () => {
    if (editPage) {
      return (
        !isEqual(editPage.columns, columns) ||
        !isEqual(editPage.rows, rows) ||
        !isEqual(editPage.elements, pageDefinition.elements)
      );
    }
    return false;
  };

  const onClose = async (props: { handleSubmit: () => Promise<void> }) => {
    isModalDirty() &&
      (await isForceLeaveConfirmed({
        handleSubmit: async () => {
          await save();
          await props.handleSubmit();
        },
      }));
  };

  React.useImperativeHandle(ref, () => ({
    save,
    handleCancelChanges,
    onClose,
  }));

  const handleChange = (event: ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <AggregateGeneralInfoContainer style={modalView ? styles.modalContainer : {}}>
      {!editPage ? (
        <>
          <Box className={useClasses.titleBarContainer}>
            <Box>
              <Typography className={classes.title}>{pageName}</Typography>
              <Typography className={classes.pageUrl}>
                Page URL: /page/{pageName + '/{id}'}
              </Typography>
            </Box>
            <Box className={classes.btnContainer}>
              <Button
                variant="outlined"
                onClick={() => {}}
                className={[classes.simulateBtn, classes.btnStyle].join(' ')}
                startIcon={<FontAwesomeIcon icon={faDisplayCode} />}
              >
                Simulate
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelChanges}
                className={[classes.cancelBtn, classes.btnStyle, classes.text].join(' ')}
                startIcon={<FontAwesomeIcon icon={faXmark} />}
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faSave} />}
                onClick={save}
                className={[classes.saveBtn, classes.btnStyle, classes.text].join(' ')}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Accordion className={classes.accordianRoot} expanded={expanded} onChange={handleChange}>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
              className={classes.accordianSummaryIcon}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={styles.accordianSummary}
            >
              <Typography style={styles.headerTxt}>Options</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ ...styles.accordianDetails }}>
              <Grid container className={classes.optionContainer}>
                <Grid item xs={12} md={modalView ? 12 : 4} className={classes.options}>
                  <Box className={classes.inputOptionsContainer}>
                    <>
                      <FontAwesomeIcon icon={faTableRows} style={styles.icon} />
                      <Typography className={classes.rowInputTitle}>Rows</Typography>
                    </>
                    <TextInputTG
                      type="number"
                      id="rows"
                      onChange={(value) => setRows(+value)}
                      value={rows}
                      containerStyle={classes.countInput}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.optionsContainer}>
                    <>
                      <FontAwesomeIcon icon={faTableColumns} style={styles.icon} />
                      <Typography className={classes.inputTitle}>Columns</Typography>
                    </>
                    <TextInputTG
                      type="number"
                      id="columns"
                      onChange={(value) => setColumns(+value)}
                      value={columns}
                      containerStyle={classes.countInput}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={modalView ? 12 : 5} className={classes.options}>
                  <Box className={classes.optionsContainer}>
                    <CheckboxInput
                      title="Allow Dynamic Sizing"
                      labelStyle={styles.label}
                      checked={allowDynamicSizing}
                      onChange={(value) => setAllowDynamicSizing(value)}
                    />
                    <Tooltip
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                      className={classes.tooltipIcon}
                      title={
                        'When using a small screen, allows custom page to display items vertically, regardless of page layout.'
                      }
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </Tooltip>
                  </Box>
                  <Box className={classes.optionsContainer}>
                    <CheckboxInput
                      title="Public"
                      labelStyle={styles.label}
                      checked={isPublic}
                      onChange={(value) => setIsPublic(value)}
                    />
                    <Tooltip
                      placement="top"
                      className={classes.tooltipIcon}
                      classes={{ tooltip: classes.tooltip }}
                      title={
                        'If the page is public, you will be able to view this page without logging in at the URL below.'
                      }
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={12} md={modalView ? 12 : 3} className={classes.options}>
                  <Button
                    startIcon={<FontAwesomeIcon icon={faFilePlus} className={classes.icon} />}
                    onClick={() => {
                      if (dataMapRef.current) {
                        dataMapRef.current.handleDataMap();
                      }
                    }}
                    className={classes.mapBtn}
                  >
                    Initial Data Map
                  </Button>
                  <Button
                    startIcon={<FontAwesomeIcon icon={faFileMinus} className={classes.icon} />}
                    onClick={handleClearDataMapper}
                    className={classes.mapBtn}
                  >
                    Clear Data Map
                  </Button>
                </Grid>
                {hasMap(pageDefinition) && fabActions.length > 0 && (
                  <Grid item xs={12} className={classes.options}>
                    <TextInputTG
                      label="Location Selection Informational Popup"
                      value={infoPopover}
                      helperText='$__SELECT_METHOD__$ will be replaced with "Right click the map" or "Long press the map" depending on device type'
                      onChange={(value) => {
                        if (!value || value === '$__SELECT_METHOD__$') {
                          setInfoPopover('');
                        } else if (value.includes('$__SELECT_METHOD__$')) {
                          setInfoPopover(value);
                        } else {
                          setInfoPopover('$__SELECT_METHOD__$ ' + value);
                        }
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordianRoot}
            expanded={fabExpanded}
            onChange={(_, expanded) => setFabExpanded(expanded)}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
              className={classes.accordianSummaryIcon}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={styles.accordianSummary}
            >
              <Typography style={styles.headerTxt}>FAB Actions</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ ...styles.accordianDetails }}>
              <CustomPagesActions fabActions={fabActions} setFabActions={setFabActions} />
            </AccordionDetails>
          </Accordion>
          <PageEditor
            ref={dataMapRef}
            pageDefinition={pageDefinition}
            setPageDefinition={setPageDefinition}
            isGroup={!!editPage}
            fullPageDefinition={fullPage || pageDefinition}
            reset={reset}
          />
        </>
      ) : (
        <>
          <Grid container className={classes.optionContainer}>
            <Grid item xs={12} md={modalView ? 12 : 4} className={classes.option}>
              <Box className={classes.inputOptionsContainer}>
                <>
                  <Typography className={classes.row}>Rows</Typography>
                </>
                <TextInputTG
                  type="number"
                  id="rows"
                  onChange={(value) => setRows(+value)}
                  value={rows}
                  containerStyle={classes.countInput}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </Box>
              <Box className={classes.optionsContainer}>
                <>
                  <Typography className={classes.column}>Columns</Typography>
                </>
                <TextInputTG
                  type="number"
                  id="columns"
                  onChange={(value) => setColumns(+value)}
                  value={columns}
                  containerStyle={classes.countInput}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <PageEditor
            ref={dataMapRef}
            pageDefinition={pageDefinition}
            setPageDefinition={setPageDefinition}
            isGroup={!!editPage}
            fullPageDefinition={fullPage || pageDefinition}
            reset={reset}
            isEditor
          />
        </>
      )}
    </AggregateGeneralInfoContainer>
  );
});

const AggregateGeneralInfoContainer = styled.div`
  flex: 1;
  padding: 35px 51px;
  padding-top: 0px;
  max-width: 100%;
  overflow: auto;
  background-color: ${colors.white};
`;
const styles = {
  accordianDetails: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
    background: colors.gray99,
    padding: '0px 33px 16px 49px',
    position: 'relative' as const,
    bottom: 10,
  },
  label: { color: colors.black, fontSize: 14, fontWeight: 400, marginRight: 5 },
  icon: {
    height: 17.5,
    width: 20,
    color: colors.black54,
  },
  headerTxt: {
    fontWeight: 500,
    fontSize: 15,
    color: colors.black,
    fontStyle: 'normal',
    lineHeight: '100%',
  },
  accordianSummary: {
    display: 'flex' as const,
    flexDirection: 'row-reverse' as const,
    background: colors.gray99,
  },
  modalContainer: {
    padding: 0,
    paddingTop: 4,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      marginBottom: '1px',
      maxWidth: '200px',
    },
    column: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      marginLeft: '30px',
      marginRight: '10px',
    },
    row: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      marginLeft: '2px',
      marginRight: '10px',
    },
    tooltipIcon: {
      fontSize: 16,
      color: colors.black,
    },
    accordianSummaryIcon: {
      pointerEvents: 'none',
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    accordianRoot: {
      '&.MuiAccordion-root': {
        position: 'static',
        margin: '10px 0px',
        marginTop: 0,
      },
      '&.MuiPaper-elevation1': {
        boxShadow: 'none',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 48,
        margin: 0,
      },
      '& .MuiAccordionSummary-root': {
        paddingLeft: 5,
      },
      '& .MuiIconButton-edgeEnd': {
        marginRight: -5,
      },
    },
    title: {
      fontSize: '24px',
      fontWeight: 500,
    },
    pageUrl: {
      fontSize: '15px',
      fontWeight: 400,
      color: colors.black60,
    },
    btnContainer: {
      display: 'flex',
      gap: '8px',
    },
    btnStyle: {
      height: '40px',
      padding: ' 11px 23px 10px 20px',
      borderRdius: '5px',
      border: `1px solid ${colors.greySeaShell}`,
      backgroundColor: colors.white,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    },
    saveBtn: {
      color: theme.palette.primary.main,
    },
    cancelBtn: {
      color: colors.black54,
    },
    text: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    simulateBtn: {
      color: colors.black54,
      display: 'none',
    },
    mapBtn: {
      width: 193,
      height: 35,
      fontSize: '15px',
      fontWeight: 400,
    },
    optionContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputOptions: {
      display: 'flex',
      flexDirection: 'column',
      padding: '7px 0px',
    },

    options: {
      display: 'flex',
      flexDirection: 'column',
    },
    option: {
      display: 'flex',
      flexDirection: 'row',
    },
    optionsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    inputOptionsContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '7px 0px',
    },
    countInput: {
      '& .MuiInputBase-root': {
        width: 70,
        height: 30,
        '& input': {
          width: 70,
          height: 30,
          padding: '0px 5px 0px 10px',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.grayd9}`,
      },
    },
    inputTitle: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      marginLeft: '12px',
      marginRight: '15px',
    },
    rowInputTitle: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      marginLeft: '12px',
      marginRight: '38px',
    },
    icon: {
      color: colors.black54,
    },
    expandIcon: {
      pointerEvents: 'auto',
    },
    addFabActionBtn: {
      color: theme.palette.primary.main,
    },
  })
);
