import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import {
  AggregateConfig,
  BuiltinWebActions,
  CommandAction,
  CommandVersionDefinition,
  ScheduleConfig,
  WebActionButton,
  WebCardDefinition,
} from '@terragotech/gen5-config-lib';
import { ConfigContext } from 'context/ConfigContext';
import { get, last, chain, cloneDeep } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { colors } from 'utils/colors';
import { ROOT_UI_ROUTE, UI_MENU_ACTIONS, UI_MENU_RECORDS } from 'utils/Utils';
import AccordionView from 'views/components/AccordionView';

type Action = Array<{ aggregateName?: string | undefined; button: WebActionButton }>;

interface ConfigData {
  cardDefinition: WebCardDefinition;
  editorWorkflows: Array<CommandAction<BuiltinWebActions>>;
  multiSelectActions: WebActionButton[];
  tableActions: WebActionButton[];
  mapLabelProperties?: string[];
  detailPage?: string;
  showMapLabelTitles?: boolean;
  showEmptyMapLabels?: boolean;
  hiddenFromMapView?: boolean;
  hiddenFromTableView?: boolean;
  fabActions?: Action;
  importActions?: Action;
  schedule?: ScheduleConfig[];
}

interface Record {
  action: {
    commandName: string;
    commandVersion: number;
  };
}

const keys = [
  { path: 'cardDefinition.buttons', key: 'cardDefinition', label: 'Card View', actions: false },
  {
    path: 'editorWorkflows',
    key: 'editorWorkflows',
    label: 'Editor Workflows',
    actions: false,
  },
  {
    path: 'multiSelectActions',
    key: 'multiSelectActions',
    label: 'Multi Select Actions',
    actions: false,
  },
  { path: 'tableActions', key: 'editorActions', label: 'Editor/Table Actions', actions: false },
  { path: 'fabActions', key: 'fabActions', label: 'FAB Actions', actions: true },
  { path: 'importActions', key: 'importActions', label: 'Import Actions', actions: true },
  { path: 'schedule', key: 'schedule', label: 'Schedule', actions: true },
];

const CommandUtilization = ({
  command,
  aggregateInfo,
}: {
  command: string;
  aggregateInfo: AggregateConfig;
}) => {
  const { config } = useContext(ConfigContext);
  const classes = useStyles();
  const typeName = aggregateInfo.typeName;
  const versions = get(aggregateInfo, `commands.${command}.versions`);
  const version = last(versions as CommandVersionDefinition[])?.version;

  const verifySchedule = useCallback(
    (value: string | undefined) => {
      if (typeof value === 'string') {
        const [commandName, versionStr] = value.split(' ');
        const [, versionName] = versionStr.split(':');
        return commandName === command && version?.toString() === versionName;
      }
      return false;
    },
    [command, version]
  );

  const verifyRecord = useCallback(
    ({ records, isAction, key }: { records: Record[]; isAction: boolean; key: string }) => {
      const path = (obj: Record) =>
        get(
          obj,
          key === 'schedule' ? 'timer' : `${isAction ? 'button.' : ''}action.commandName`,
          undefined
        );
      const cmdVersionPath = (obj: Record) =>
        get(obj, `${isAction ? 'button.' : ''}action.commandVersion`);
      return records.filter(
        (rd: Record) =>
          version &&
          (key === 'schedule'
            ? verifySchedule(path(rd))
            : path(rd) === command && cmdVersionPath(rd) === version)
      );
    },
    [version, command, verifySchedule]
  );

  const utilizations = useMemo(() => {
    const configs = ['webUIConfig', 'mobileUIConfig'];

    // Clean up configs that have fabActions, importActions, and schedules added to the selected record's aggregateUICustomizations
    const cleanRecords = configs.map(
      (o) => (get(config, `${o}.aggregateUICustomizations.${typeName}`) as unknown) as ConfigData
    )
    cleanRecords.map((record, i) => {
      if (record) {
        for (const key in record) {
          if (key === 'fabActions' || key === 'importActions' || key === 'schedule') {
            delete record[key];
          }
        }
      }
    });

    const configClone = cloneDeep(config);
    const records = configs.map(
      (o) => (get(configClone, `${o}.aggregateUICustomizations.${typeName}`) as unknown) as ConfigData
    );
    records.map((record, i) => {
      if (record) {
        record.fabActions = ((get(config, `${configs[i]}.fabActions`) as unknown) as Action).filter((fabAction) => { return fabAction.aggregateName === typeName });
        if (i === 0) {
          record.importActions = ((get(
            config,
            `${configs[i]}.importActions`,
            []
          ) as unknown) as Action).filter((importAction) => { return importAction.aggregateName === typeName });
          record.schedule = (get(config, `schedules.${typeName}`) as unknown) as ScheduleConfig[];
        }
      }
    });
    const finalKeys = keys.map((o) => {
      const objs = records.map((record, i) => {
        const recordData = get(
          record,
          o.key === 'editorActions' && i === 1 ? 'editorActions' : o.path,
          []
        );
        const datafound = verifyRecord({ records: recordData, isAction: o.actions, key: o.key });
        return datafound.length ? o : '';
      });
      return chain(objs).compact().first().value();
    });
    return chain(finalKeys).flatten().compact().value();
  }, [typeName, config, verifyRecord]);

  const Header = () => {
    return (
      <Box className={classes.headerContainer}>
        <Typography className={classes.headerText}>Command Utilization</Typography>
      </Box>
    );
  };
  const Body = () => {
    return (
      <Box className={classes.body}>
        {utilizations?.length > 0 ? (
          <Box className={classes.utils}>
            {utilizations.map((utilization) => {
              return (
                <Link
                  key={utilization.label}
                  className={classes.utilBox}
                  href={`${ROOT_UI_ROUTE}${
                    utilization.actions ? UI_MENU_ACTIONS : `${UI_MENU_RECORDS}/${typeName}`
                  }/${utilization.key}`}
                  target="_blank"
                >
                  <Typography className={classes.utilLabel}>{utilization.label}</Typography>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.icon} />
                </Link>
              );
            })}
          </Box>
        ) : (
          <Typography className={classes.emptyContent}>
            The command has not been utilized.
          </Typography>
        )}
      </Box>
    );
  };
  return (
    <Box className={classes.container}>
      <AccordionView header={<Header />} body={<Body />} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: 38,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
    width: '100%',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 500,
    color: colors.black,
  },
  emptyContent: {
    color: colors.black34,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '100%',
  },
  body: {
    marginTop: 12,
  },
  utils: {
    display: 'flex',
    gap: '12px 10px',
    flexWrap: 'wrap',
  },
  utilBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 14px',
    gap: 10,
    borderRadius: 30,
    backgroundColor: colors.black2,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  utilLabel: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '100%',
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.primary.main,
  },
}));

export default CommandUtilization;
