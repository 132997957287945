import React, { Fragment, ReactNode } from 'react';
import { faLayerGroupPlus, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell as TCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { colors } from 'utils/colors';
import _ from 'lodash';
import Condition from './Condition';
import { ConjunctionConst } from './defaults/defaultConjunctions';
import { Translations } from './defaults/defaultTranslations';
import {
  QueryBuilderSchema,
  AttributeBasedFilterWithID,
  AttributeBasedFilterSimpleFiltersWithID,
} from './QueryBuilder';
import { isConditionGroupWithId } from './utils/isConditionGroup';
import { createCondition } from './utils/createCondition';
import { createConditionGroup } from './utils/createConditionGroup';
import { AttributeBasedFilterEqualType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';

interface ConditionGroupProps {
  id: string;
  parentId: string | null;
  conjunction: ConjunctionConst;
  condition: Array<AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID>;
  translations: Translations;
  schema: QueryBuilderSchema;
  not?: boolean;
  allowValue?: boolean;
  panelTitle: string;
  panelTitleStyle?: string;
  aggregateType: ReactNode | null;
  onRemove?: () => void;
  panelHeaderStyle?: string;
}

const TableCell = styled(TCell)({
  borderBottom: `1px solid ${colors.black10}`,
});

const ConditionGroup: React.FC<ConditionGroupProps> = ({
  id,
  parentId,
  conjunction,
  condition,
  translations,
  schema,
  not,
  allowValue,
  panelTitle,
  panelTitleStyle = '',
  aggregateType,
  onRemove,
  panelHeaderStyle,
}) => {
  const classes = useStyles();
  const {
    keys,
    controls: {
      ConjunctionSelector,
      NotToggle,
      AddConditionAction,
      AddGroupAction,
      RemoveGroupAction,
    },
    conjunctions,
    onGroupAdd,
    onGroupRemove,
    onConditionAdd,
    onPropChange,
    showNotToggle,
  } = schema;

  const hasParentGroup = !!parentId;
  const tableHeader = ['Actions', 'Attributes', 'Type', 'Value'].map((label) => ({ label }));

  const onConjunctionChange = (conjunction: ConjunctionConst) => {
    onPropChange('conjunction', conjunction, id);
  };

  const onNotToggleChange = (checked: boolean) => {
    onPropChange('not', checked, id);
  };

  const addGroup = () => {
    const newGroup = createConditionGroup();
    onGroupAdd(newGroup, id);
  };

  const removeGroup = () => {
    if (Boolean(onRemove)) {
      onRemove!();
    } else {
      onGroupRemove(id, parentId);
    }
  };

  const addCondition = () => {
    const newCondition = createCondition(keys);
    onConditionAdd(newCondition, id);
  };
  const [groupCondition, rowItems] = _.partition(condition, (o) => isConditionGroupWithId(o));

  return (
    <Box className={classes.container}>
      <Grid container className={`${classes.panelHeader} ${panelHeaderStyle || ''}`}>
        <Grid item xs={6} className={classes.leftContent}>
          <Typography className={`${classes.panelTxt} ${panelTitleStyle}`}>{panelTitle}</Typography>
          <ConjunctionSelector
            options={conjunctions}
            option={conjunction}
            handleOnChange={onConjunctionChange}
          />
          {showNotToggle && (
            <NotToggle
              title={translations.NotToggle.title}
              checked={not}
              handleOnChange={onNotToggleChange}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.rightContent}>
          <AddConditionAction icon={faPlus} handleOnClick={addCondition} />
          <AddGroupAction icon={faLayerGroupPlus} handleOnClick={addGroup} />
          {(hasParentGroup || Boolean(onRemove)) && (
            <RemoveGroupAction icon={faTrashCan} handleOnClick={removeGroup} />
          )}
        </Grid>
      </Grid>
      {aggregateType}
      <Box className={classes.panelBody}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {tableHeader.map((rlpHeader) => (
                  <TableCell key={rlpHeader.label} className={classes.headerCell}>
                    <Typography className={classes.headerCellTxt}>{rlpHeader.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowItems?.length > 0 ? (
                (rowItems as AttributeBasedFilterSimpleFiltersWithID[]).map((r) => (
                  <Fragment key={r.id}>
                    <Condition
                      id={r.id}
                      _key={r.key}
                      options={(r as AttributeBasedFilterEqualType).options}
                      operator={r.operator}
                      schema={schema}
                      parentId={id}
                      translations={translations}
                      allowValue={allowValue}
                    />
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={tableHeader.length || 4} align="center">
                    <Typography className={classes.emptyText}>No records to display</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {(groupCondition as AttributeBasedFilterWithID[]).map((r, index) => (
          <Box key={r.id} className={classes.childContainer}>
            <ConditionGroup
              id={r.id}
              schema={schema}
              parentId={id}
              conjunction={r.conjunction}
              translations={translations}
              condition={r.condition}
              allowValue={allowValue}
              panelTitle={`${panelTitle}-${1 + index}`}
              aggregateType={aggregateType}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    overflow: 'hidden',
  },
  panelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.black5,
    height: 46,
    paddingRight: 10,
    paddingLeft: 28,
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  panelTxt: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.black,
    marginRight: 16,
  },
  panelBody: {
    backgroundColor: colors.white,
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    width: '100%',
  },
  headerCell: {
    height: 50,
    paddingTop: 22,
    paddingBottom: 8,
    paddingLeft: 0,
    '&:first-child': {
      paddingLeft: 28,
    },
  },
  headerCellTxt: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
  },
  childContainer: {
    padding: '18px 27px',
  },
  tableHeaderRow: {
    '&:last-child': {
      padding: 10,
    },
  },
  emptyText: {
    color: colors.black,
    fontSize: 14,
    fontWeight: 400,
  },
});

export default ConditionGroup;
