import React from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { colors } from 'utils/colors';
import { grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToSquare,
  faBars,
  faPenToSquare,
  faSliders,
  faTrashCan,
  faAdd,
} from '@fortawesome/pro-regular-svg-icons';
import MaterialTable, { MTableBodyRow } from 'material-table';
import styled from 'styled-components';
import { PropertyListUpload } from '../PropertyListUpload';
import cloneDeep from 'lodash/cloneDeep';
import { AggrPropertyRow } from '../../utils/types';
import FilterModal from 'views/pages/records/AggregateProperties/propertyFilterModal';
import PropertyModal from 'views/pages/records/AggregateProperties/ModalPropertyFields';
import { isArray, isUndefined, orderBy } from 'lodash';
import { ConfigContext } from 'context/ConfigContext';
import { propertiesArrToObj } from 'pages/aggregates/utils/propertiesArrayToObject';
import { getAggregateIndex } from 'utils/navigationUtils';
import { useParams, useNavigate } from 'react-router-dom';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';
import { CONFIRMATION, PROPERTIES_FILTER_TITLE } from '../../utils/Utils';
import PageHeader from 'views/components/PageHeader';
import _ from 'lodash';
import { useConfirmDialog } from 'context/ConfirmContext';
import { RelationalPropertyType } from '@terragotech/gen5-config-lib';
import { ROOT_RECORDS_ROUTE } from 'utils/Utils';


export interface MaterialTableRef {
  state: {
    lastEditingRow: object;
    showAddRow: boolean;
  };
}
export interface CSVResult {
  newRecordCount?: number;
  existingRecordCount?: number;
  badRecordCount?: number;
  afterImportCount?: number;
  badRecords?: string | undefined;
}

interface EditableTableProps {
  title?: string;
  data?: object[] | undefined;
  columns: Array<{ title: string }> | object[];
  hideFilter?: boolean;
  hideExport?: boolean;
  options?: object;
  fileType?: string;
  onExport?: (columns: object[], data: object[]) => void;
  onFileImport?: (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => void;
  actions?: object[];
  fabActions?: boolean;
  onAdd: (row: object, resolve: (data: AggrPropertyRow | null) => void, reject: () => void) => void;
  onUpdate: (
    row: object,
    oldRow: object,
    resolve: (data: object | null) => void,
    reject: () => void
  ) => void;
  onDelete: (row: object) => void;
  toolbarStyle?: React.CSSProperties;
  toolbarItem?: React.ReactElement;
  tableRef?: React.RefObject<MaterialTableRef>;
  enableBlurBackground?: boolean;
  configKey?: string;
  AggregateProperties?: boolean;
  reOrderData?: (value: unknown) => void;
  headerStyle?: string;
}

export type PartialAggrPropertyRowWithOrder = Partial<AggrPropertyRow> & { order?: number };

export const PropertyEditableTable: React.FC<EditableTableProps> = React.memo(
  ({
    title,
    data,
    hideFilter,
    hideExport,
    columns,
    onFileImport,
    onExport,
    fileType,
    onAdd,
    onUpdate,
    onDelete,
    fabActions,
    options,
    tableRef,
    enableBlurBackground = false,
    configKey,
    AggregateProperties,
    reOrderData,
    headerStyle,
  }) => {
    const onCSVFileImport = (
      rows: object[],
      uploadedResults: CSVResult,
      resolve: (data: AggrPropertyRow | null) => void,
      reject: () => void
    ) => {
      if (onFileImport) {
        onFileImport(rows, uploadedResults, resolve, reject);
      }
    };
    const { config, setAggregateProperties } = React.useContext(ConfigContext);
    const classes = useStyles();
    const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
    const [header, setHeader] = React.useState<string>('');
    const [editingData, setEditingData] = React.useState({});
    const [filterModal, setFilterModal] = React.useState<boolean>(false);
    const [customizedColumns, setCustomizedColumns] = React.useState<object[]>([]);
    const [tableData, setTableData] = React.useState<any[]>(data as object[]);
    const { aggregate: aggrName } = useParams() as { aggregate: string };
    const aggrIndex = getAggregateIndex(config, aggrName);
    const listRef = React.useRef<object[] | undefined>(tableData);

    const { openConfirmation } = useConfirmDialog();
    const navigate = useNavigate();

    React.useEffect(() => {
      listRef.current = data;
      setTableData(listRef.current as object[]);
    }, [data]);
    const DragState = {
      row: -1,
      dropIndex: -1,
    };
    const offsetIndex = (from: number, to: number, arr = []) => {
      if (from < to) {
        let start = arr.slice(0, from),
          between = arr.slice(from + 1, to + 1),
          end = arr.slice(to + 1);
        return [...start, ...between, arr[from], ...end];
      }
      if (from > to) {
        let start = arr.slice(0, to),
          between = arr.slice(to, from),
          end = arr.slice(from + 1);
        return [...start, arr[from], ...between, ...end];
      }
      return arr;
    };

    const reOrderRow = (from: number, to: number) => {
      let t = tableData as never[];
      let modifiedTableData = offsetIndex(from, to, t);
      setTableData(modifiedTableData);
      if (AggregateProperties) {
        setAggregateProperties(aggrIndex, propertiesArrToObj(modifiedTableData));
      } else {
        if (!isUndefined(reOrderData)) {
          reOrderData(modifiedTableData);
        }
      }
    };
    const hasActions = columns.findIndex((o) => (o as { field: string }).field === 'action');
    const updatedColumns = [...columns];
    if (hasActions !== -1) {
      const actions = columns[hasActions];
      updatedColumns.splice(hasActions, 1);
      updatedColumns.splice(3, 0, actions);
    }

    const sortedColumns = orderBy(updatedColumns, 'mOrder');

    React.useEffect(() => {
      if (AggregateProperties) {
        const parsedColumns = StorageHelper.getByKey(StorageKeys.propertyColumn);
        if (isArray(parsedColumns)) {
          const filteredData = columns.filter((item: object) => {
            const temp = item as { field: string };
            return parsedColumns.includes(temp.field);
          });
          setCustomizedColumns(filteredData);
        } else {
          setCustomizedColumns(columns);
        }
      } else {
        setCustomizedColumns(columns);
      }
    }, [columns, data, AggregateProperties]);

    const getCellStyle = (tableColumn: { title: string; type: string }) => {
      const commonStyles = { ...styles.tableColumn };
      const titleStyles =
        tableColumn.title === 'Icon' || tableColumn.type === 'boolean'
          ? { ...commonStyles, ...styles.tableTitle }
          : commonStyles;

      return titleStyles;
    };

    const onHandleDelete = async (data: AggrPropertyRow) => {
      const props = CONFIRMATION.properties({ label: data.label || 'timer' });
      const status = await openConfirmation(props);
      if (status === 'confirm') {
        if (data.relation === 'ONE-TO-ONE') {
          const foreignRelation = await getForeignRelation(data);
          if (foreignRelation) {
            const foreignRelationStatus = await openConfirmation(CONFIRMATION.foreignRelation({property: foreignRelation.name, recordType: foreignRelation.typeName, action: 'Delete'}));
            if (foreignRelationStatus === 'confirm') {
              const href = `${ROOT_RECORDS_ROUTE}${foreignRelation.typeName}/properties`;
              navigate(href);
            }
          } else {
            onDelete(data);
          }
        } else {
          onDelete(data);
        }
      }
    };

    const getForeignRelation = async (data: AggrPropertyRow) => {
      const allProperties = config.aggregates
        .flatMap(aggregate => 
          Object.entries(aggregate.properties)
          .map(([key, property]) => ({
            ...property,
            typeName: aggregate.typeName,
            name: key,
          }))
        );
      const foreignRelation = allProperties.filter(item =>
        (item as RelationalPropertyType).foreignColumn === data.name && aggrName === item.type
      );
      return foreignRelation[0];
    };

    return (
      <>
        {filterModal && (
          <FilterModal
            filterModal={filterModal}
            setFilterModal={setFilterModal}
            data={columns}
            setCustomizedColumns={setCustomizedColumns}
            customizedColumns={customizedColumns}
          />
        )}
        {openAddModal && (
          <PropertyModal
            openAddModal={openAddModal}
            setOpenAddModal={setOpenAddModal}
            onAdd={onAdd}
            data={sortedColumns}
            title={header}
            type={header}
            onUpdate={onUpdate}
            editingData={editingData}
            fabActions={fabActions}
            enableBlurBackground={enableBlurBackground}
            showProperties={AggregateProperties}
            viewTitle={title}
            getForeignRelation={getForeignRelation}
          />
        )}
        {!AggregateProperties && (
          <PageHeader
            title={title as string}
            enableSaveButton
            openModals={setOpenAddModal}
            setHeader={setHeader}
            enableAddButton
            configKey={configKey}
            showLinkUnlink={_.isEmpty(configKey)}
            headerStyle={[classes.headerRootContainer, headerStyle].filter((x) => !!x).join(' ')}
          />
        )}
        <Box className={classes.header1}>
          {AggregateProperties && (
            <Box className={classes.titleContainer}>
              <Typography component={'h2'} className={classes.header}>
                {title || 'Properties'}
              </Typography>
            </Box>
          )}
          <Box className={classes.buttonContainer}>
            {!hideFilter && (
              <Button
                variant="outlined"
                disableElevation
                color="default"
                className={`${classes.button} ${classes.buttonBorder}`}
                onClick={() => setFilterModal(true)}
                startIcon={<FontAwesomeIcon icon={faSliders} className={classes.buttonIcon} />}
              >
                <Typography className={classes.text}>{PROPERTIES_FILTER_TITLE}</Typography>
              </Button>
            )}
            {!hideExport && (
              <Button
                disableElevation
                variant="outlined"
                color="default"
                className={`${classes.button} ${classes.buttonBorder}`}
                onClick={() => onExport && onExport(columns, data as object[])}
                startIcon={
                  <FontAwesomeIcon icon={faArrowDownToSquare} className={classes.buttonIcon} />
                }
              >
                <Typography className={classes.text}>Export</Typography>
              </Button>
            )}
            {onFileImport && fileType === 'propertyList' && (
              <PropertyListUpload
                data={data as PartialAggrPropertyRowWithOrder[]}
                fileImportResults={onCSVFileImport}
              />
            )}
            {AggregateProperties && (
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                classes={{ root: classes.btn }}
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                onClick={() => {
                  setOpenAddModal(true);
                  setHeader('Add');
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
        <PropertiesContainer>
          <MaterialTable
            title={title || ''}
            options={{
              ...options,
              exportCsv: (columns: object[], data: object[]) => {
                if (onExport) {
                  onExport(columns, data);
                }
              },
            }}
            columns={_.orderBy(customizedColumns, 'sort')}
            data={cloneDeep(tableData)}
            components={{
              Container: (props) => (
                <Paper {...props} elevation={0} className={classes.tableRootContainer} />
              ),
              Header: (props) => (
                <TableHead {...props}>
                  <TableRow className={classes.row1}>
                    <TableCell>
                      <div style={styles.rootElement}>Actions</div>
                    </TableCell>
                    {_.orderBy(customizedColumns, 'sort').map((item: object, index: number) => {
                      const tableColumn = item as { title: string; type: string };
                      return (
                        <TableCell align="left" key={index}>
                          <div style={getCellStyle(tableColumn)}>{tableColumn?.title}</div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              ),
              Row: (props) => (
                <MTableBodyRow
                  {...props}
                  draggable
                  className={classes.tableRow}
                  onDragStart={() => {
                    DragState.row = props.data.tableData.id;
                  }}
                  onDragEnter={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    if (props.data.tableData.id !== DragState.row) {
                      DragState.dropIndex = props.data.tableData.id;
                    }
                  }}
                  onDragEnd={() => {
                    if (DragState.dropIndex !== -1) {
                      reOrderRow(DragState.row, DragState.dropIndex);
                    }
                    DragState.row = -1;
                    DragState.dropIndex = -1;
                  }}
                />
              ),
            }}
            actions={[
              {
                icon: () => (
                  <FontAwesomeIcon
                    icon={faBars}
                    color={colors.black54}
                    className={classes.icon}
                    style={styles.drag}
                  />
                ),
                tooltip: 'Drag',
                onClick: () => {},
              },
              {
                icon: () => (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    color={colors.black54}
                    className={classes.icon}
                  />
                ),
                tooltip: 'Edit',
                onClick: (event, data) => {
                  setEditingData(data);
                  setOpenAddModal(true);
                  setHeader('Edit');
                },
              },
              {
                icon: () => (
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    color={colors.black54}
                    className={classes.icon}
                  />
                ),
                tooltip: 'Delete',
                onClick: (event, data: AggrPropertyRow) => {
                  console.log('title', title);
                  onHandleDelete(data);
                },
              },
            ]}
            tableRef={tableRef}
          />
        </PropertiesContainer>
      </>
    );
  }
);

const styles = {
  tableColumn: {
    display: 'flex',
    gap: 10,
  },
  tableTitle: {
    justifyContent: 'center',
  },
  rootElement: {
    padding: '0 10px',
    textAlign: 'center' as const,
  },
  drag: {
    cursor: 'grab',
  },
  iconContainer: {
    borderRadius: '25px',
    border: `1px solid ${colors.greySeaShell}`,
    background: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    padding: '8px 6px 8px 6px',
    height: '36px',
    width: '36px',
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row1: {
      '& .MuiTableCell-head': {
        position: 'sticky',
        top: 0,
        textAlign: 'left',
        background: colors.greyBackground,
        height: 45,
        color: colors.black,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 15,
        lineHeight: '100%',
        zIndex: 10,
        padding: '5px 16px',
        borderBottom: `1px solid ${colors.black10}`,
      },
    },
    tableRootContainer: {
      borderBottom: 'none',
      borderRadius: '5px 5px 0px 0px',
      '& ::-webkit-scrollbar-thumb': {
        border: 'none',
        backgroundColor: colors.black15,
      },
    },
    headerRootContainer: {
      paddingBottom: '0px !important',
    },
    tableRow: {
      '& .MuiIconButton-root': {
        padding: 6,
      },
      '& .MuiTableCell-body': {
        padding: '12px 16px !important',
        borderBottom: `1px solid ${colors.black10}`,
      },
    },
    btn: {
      height: 40,
      width: 110,
      fontSize: 16,
      fontWeight: 500,
    },
    innerContainer: {
      padding: 40,
      backgroundColor: colors.white,
    },
    header: {
      fontSize: 24,
      color: colors.black,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    header1: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      paddingBottom: 21,
    },
    para: {
      fontSize: 15,
      fontWeight: 400,
      color: grey[700],
    },
    table: {
      minWidth: 650,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '19px',
    },
    button: {
      backgroundColor: colors.white,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      height: 40,
      paddingLeft: 15,
    },
    buttonBorder: {
      color: colors.greyText,
      border: `1px solid ${colors.greyBorder}`,
    },
    headerContainer: {
      display: 'flex',
      gap: 3,
      alignItems: 'center',
    },
    titleIcon: {
      width: 24,
      height: 20,
    },
    buttonIcon: {
      height: 18,
      width: 18,
      color: colors.greyText,
    },
    buttonContainer: {
      display: 'flex',
      gap: 8,
    },
    actionIconContainer: {
      width: 110,
    },
    actionIconButton: {
      padding: 8,
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 15,
      fontStyle: 'normal',
      color: colors.black,
      width: 'max-content',
    },
    tableRows: {
      display: 'flex',
    },
    mainContainer: {
      height: '100%',
      backgroundColor: colors.white,
    },
    tableCellText: {
      fontFamily: 'Inter',
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 400,
      color: colors.black,
    },
    icon: {
      height: 18,
      width: 18,
    },
    text: {
      fontSize: 16,
      fontStyle: 'normal',
      color: colors.greyText,
      fontWeight: 500,
      lineHeight: '100%',
    },
    linkMenuHead: {
      width: 250,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      opacity: '1 !important',
    },
    linkMenuTitle: {
      color: colors.black,
      fontSize: '18px',
      fontWeight: 500,
    },
    linkMenuIcon: {
      width: 21,
      height: 14,
      padding: '4px',
      borderRadius: '15px',
      backgroundColor: colors.black5,
      color: colors.iconBlue,
    },
  })
);
const PropertiesContainer = styled(Box)`
  flex: 1;
  overflow-x: auto;
  border: 1px solid ${colors.black10};
  border-radius: 5px;
`;
