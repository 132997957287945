import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Box, CircularProgress, Paper, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { grey } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPenToSquare, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import MaterialTable, { MTableBodyRow } from 'material-table';
import cloneDeep from 'lodash/cloneDeep';
import { ActionButtons, AggrPropertyRow } from '../utils/types';
import PropertyModal from 'views/pages/records/AggregateProperties/ModalPropertyFields';
import PageHeader from 'views/components/PageHeader';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';
import { convertStringToCommandRef } from '../utils/jsonPartsGenerators';
import { BuiltInActions, CommandReference } from '@terragotech/gen5-config-lib';

export interface EditableTableAction {
  icon: () => React.ReactElement;
  tooltip: string;
  onClick: (row: object, rowData: object) => void;
}

export interface MaterialTableRef {
  state: {
    lastEditingRow: object;
    showAddRow: boolean;
  };
}

export interface CSVResult {
  newRecordCount?: number;
  existingRecordCount?: number;
  badRecordCount?: number;
  afterImportCount?: number;
  badRecords?: string | undefined;
}

interface EditableTableProps {
  title?: string;
  data?: object[];
  columns: object[];
  options?: object;
  fileType?: string;
  onExport?: (columns: object[], data: object[]) => void;
  onFileImport?: (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: AggrPropertyRow) => void,
    reject: () => void
  ) => void;
  actions?: object[];
  onAdd?: (row: object, resolve: (data: AggrPropertyRow | null) => void, reject: () => void) => void;
  onUpdate?: (
    row: object,
    oldRow: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => void;
  onDelete?: (row: object) => void;
  toolbarStyle?: React.CSSProperties;
  toolbarItem?: React.ReactElement;
  tableRef?: React.RefObject<MaterialTableRef>;
  configKey?: string;
  onReOrder?: (value: unknown) => void;
  style?: string;
  showDivider?: boolean;
  hasHeader?: boolean;
  onOpenAddModal?: boolean;
  onCloseAddModal?: Dispatch<SetStateAction<boolean>>;
  containerClass?: string;
  tableContainer?: string;
  showLinkUnlink?: boolean;
  isLanguageOptions?: boolean;
  isLoading?: boolean;
  customTableHeight?: string;
  confirmOnDelete?: boolean;
}

export type PartialAggrPropertyRowWithOrder = Partial<AggrPropertyRow> & { order?: number };

export interface BadRecords {
  name?: string;
  rowIndex?: number;
}
const TABLE_HEIGHT = 230;
export const EditableTable: React.FC<EditableTableProps> = React.memo(
  ({
    title,
    data,
    columns,
    onExport,
    onAdd = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    options,
    tableRef,
    configKey,
    onReOrder,
    style,
    showDivider,
    hasHeader = true,
    onOpenAddModal,
    onCloseAddModal,
    containerClass = '',
    tableContainer = '',
    showLinkUnlink,
    isLanguageOptions = false,
    isLoading,
    customTableHeight,
    confirmOnDelete = false
  }) => {
    const classes = useStyles();
    const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
    const [header, setHeader] = React.useState<string>('');
    const [editingData, setEditingData] = React.useState<object>([]);
    const [customizedColumns, setCustomizedColumns] = React.useState<object[]>([]);
    const [tableData, setTableData] = React.useState<object[] | undefined>(data);
    const tableMaxHeight = `calc(100vh - ${TABLE_HEIGHT}px)`;
    const listRef = React.useRef(tableData);

    React.useEffect(() => {
      setCustomizedColumns([]);
    }, []);

    React.useEffect(() => {
      listRef.current = data;
      setTableData(listRef.current);
    }, [data]);

    const { openConfirmation } = useConfirmDialog();

    //Drag & Drop Start
    const dragState = {
      row: -1,
      dropIndex: -1,
    };
    const offsetIndex = (from: number, to: number, arr = []) => {
      if (from < to) {
        let start = arr.slice(0, from),
          between = arr.slice(from + 1, to + 1),
          end = arr.slice(to + 1);
        return [...start, ...between, arr[from], ...end];
      }
      if (from > to) {
        let start = arr.slice(0, to),
          between = arr.slice(to, from),
          end = arr.slice(from + 1);
        return [...start, arr[from], ...between, ...end];
      }
      return arr;
    };

    const reOrderRow = (from: number, to: number) => {
      let newTableData = offsetIndex(from, to, tableData as never);
      setTableData(newTableData);
      if (onReOrder) {
        let reorderedActionButtons: ActionButtons = [];
        for (const item of newTableData as ActionButtons) {
          if (item.action) {
            reorderedActionButtons = [
              ...reorderedActionButtons,
              {
                ...item,
                action: convertStringToCommandRef(item.action as string) as
                  | CommandReference
                  | BuiltInActions,
              },
            ];
          } else {
            reorderedActionButtons = [...reorderedActionButtons, { ...item }];
          }
        }
        onReOrder(reorderedActionButtons);
      }
    };
    // Drag & Drop End
    const tableColumns = customizedColumns.length !== 0 ? customizedColumns : columns;

    const hasActions = columns.findIndex((o) => (o as { field: string }).field === 'action');
    const updatedColumns = [...columns];
    if (hasActions !== -1) {
      const actions = columns[hasActions];
      updatedColumns.splice(hasActions, 1);
      updatedColumns.splice(2, 0, actions);
    }
    const getCellStyle = (tableColumn: { title: string }) => {
      const commonStyles = { ...styles.tableColumn };
      const titleStyles =
        tableColumn.title === 'Icon' || tableColumn.title === 'Primary'
          ? { ...commonStyles, ...styles.tableTitle }
          : commonStyles;

      return titleStyles;
    };

    const headerText = onOpenAddModal ? 'Add' : header;
    const toggleOpenAddModal = onOpenAddModal ? onCloseAddModal! : setOpenAddModal;
    const canShowModal = openAddModal || !!onOpenAddModal;

    const onHandleDelete = async (event: any, data: object) => {
      const label = data as { label: string; itemLabel: string };
      return new Promise((resolve) => {
        const props = CONFIRMATION.commonDelete({ name: label.label || label.itemLabel });
        openConfirmation(props).then((value) => {
          if (value === 'confirm' && onDelete) {
            onDelete(data);
          }
        });
        resolve(null);
      });
    };

    const actionIcons = useMemo(() => {
      const drag = {
        icon: () => (
          <FontAwesomeIcon
            icon={faBars}
            color={colors.black54}
            className={classes.icon}
            style={styles.dragIconContainer}
          />
        ),
        tooltip: 'Drag',
        onClick: (event: any, rowData: any) => {},
      };
    
      const edit = {
        icon: () => (
          <FontAwesomeIcon
            icon={faPenToSquare}
            color={colors.black54}
            className={classes.icon}
          />
        ),
        tooltip: 'Edit',
        onClick: (event: any, data: object) => {
          setEditingData(data);
          setOpenAddModal(true);
          setHeader('Edit');
        },
      };
    
      const defaultDelete = {
        icon: () => (
          <FontAwesomeIcon
            icon={faTrashCan}
            color={colors.black54}
            className={classes.icon}
          />
        ),
        tooltip: 'Delete',
        onClick: onHandleDelete,
      };
    
      const translationDelete = {
        icon: () => (
          <FontAwesomeIcon
            icon={faTrashCan}
            color={colors.black54}
            className={classes.icon}
          />
        ),
        tooltip: 'Delete',
        onClick: (event: any, data: object) =>
          confirmOnDelete ? onDelete(data) : () => {},
      };
    
      return isLanguageOptions ? [translationDelete] : [drag, edit, defaultDelete];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLanguageOptions]);
    
    

    return (
      <Box className={`${classes.container} ${containerClass}`}>
        {canShowModal && (
          <PropertyModal
            openAddModal={canShowModal}
            setOpenAddModal={toggleOpenAddModal}
            onAdd={onAdd}
            data={updatedColumns}
            title={headerText}
            type={headerText}
            onUpdate={onUpdate}
            editingData={editingData}
            viewTitle={title}
          />
        )}
        {hasHeader && (
          <PageHeader
            title={title as string}
            openModals={setOpenAddModal}
            setHeader={setHeader}
            enableAddButton
            icon={undefined}
            buttonText={''}
            enableSaveButton
            configKey={configKey}
            style={style}
            showDivider={showDivider}
            showLinkUnlink={showLinkUnlink}
            titleStyle={classes.titleStyle}
          />
        )}
        <Box className={`${classes.propertiesContainer} ${tableContainer}`}>
          {isLoading && (
            <Box className={classes.loadingOverlay}>
              <Typography style={{ marginRight: 20 }}>Loading...</Typography>
              <CircularProgress size={30} />
            </Box>
          )}
          <MaterialTable
            title={title || ''}
            options={{
              maxBodyHeight: customTableHeight ? customTableHeight : tableMaxHeight,
              paging: false,
              showTitle: false,
              toolbar: false,
              search: false,
              exportCsv: (columns: object[], data: object[]) => {
                if (onExport) {
                  onExport(columns, data);
                }
              },
              actionsCellStyle: styles.actionsCell,
              ...options,
            }}
            columns={customizedColumns.length !== 0 ? customizedColumns : columns}
            data={cloneDeep(tableData as object[])}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              Header: (props) => (
                <TableHead {...props}>
                  <TableRow className={classes.headerRow}>
                    <TableCell>
                      <div style={styles.rootElement}>Actions</div>
                    </TableCell>
                    {tableColumns.map((item: object, index: number) => {
                      const tableColumn = item as { title: string };
                      return (
                        <TableCell align="left" key={index}>
                          <div style={getCellStyle(tableColumn)}>{tableColumn.title}</div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              ),
              Row: (props) => (
                <MTableBodyRow
                  {...props}
                  draggable
                  className={[classes.tableRows, classes.tableCell].join(' ')}
                  onDragStart={() => {
                    dragState.row = props.data.tableData.id;
                  }}
                  onDragEnter={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    if (props.data.tableData.id !== dragState.row) {
                      dragState.dropIndex = props.data.tableData.id;
                    }
                  }}
                  onDragEnd={() => {
                    if (dragState.dropIndex !== -1) {
                      reOrderRow(dragState.row, dragState.dropIndex);
                    }
                    dragState.row = -1;
                    dragState.dropIndex = -1;
                  }}
                />
              ),
            }}
            actions={actionIcons}
            tableRef={tableRef}
          />
        </Box>
      </Box>
    );
  }
);

const styles = {
  tableColumn: {
    display: 'flex',
    gap: 10,
  },
  tableTitle: {
    justifyContent: 'center',
  },
  rootElement: {
    padding: '0 10px',
    textAlign: 'center' as const,
  },
  deviceIcon: {
    height: 20,
    width: 20,
  },
  deviceIconContainer: {
    height: 40,
    width: 40,
    background: colors.white,
    borderRadius: '50%',
    boxShadow: `${colors.black78} 0px 3px 8px`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragIconContainer: {
    cursor: 'grab',
  },
  menuItem: {
    display: 'flex',
    gap: 10,
  },
  menuContainer: {
    maxHeight: 200,
    width: '20ch',
  },
  actionsCell: {
    padding: '20px 16px',
  },
};
const useStyles = makeStyles({
  container: {},
  headerRow: {
    '& .MuiTableCell-head': {
      position: 'sticky',
      top: 0,
      textAlign: 'left',
      background: colors.greyBackground,
      height: 45,
      color: colors.black,
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 15,
      lineHeight: '100%',
      zIndex: 10,
      padding: '5px 16px',
    },
  },
  tableCell: {
    '& .MuiTableCell-body': {
      padding: '0px !important',
      paddingInline: '16px !important',
      height: 55,
    },
  },
  header: {
    fontSize: 24,
    color: colors.black,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  header1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingInline: 5,
  },
  para: {
    fontSize: 15,
    fontWeight: 400,
    color: grey[700],
  },
  table: {
    minWidth: 650,
  },
  button: {
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.blueBorder,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    height: 40,
  },
  headerContainer: {
    display: 'flex',
    gap: 3,
    alignItems: 'center',
  },
  buttonIcon: {
    marginRight: 10,
    height: 21,
    width: 21,
    color: colors.blueBorder,
  },
  buttonContainer: {
    display: 'flex',
    gap: 8,
  },
  actionIconContainer: {
    width: 110,
  },
  actionIconButton: {
    padding: 8,
  },
  tableRows: {
    backgroundColor: colors.white1,
    '& .MuiIconButton-root': {
      padding: 9,
    },
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${colors.black10}`,
    },
    '&:last-child td.MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  mainContainer: {
    height: '100%',
    backgroundColor: colors.white,
  },
  tableCellText: {
    fontFamily: 'Inter',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 400,
    color: colors.black,
  },
  icon: {
    height: 18,
    width: 18,
  },
  text: {
    fontSize: 16,
    fontStyle: 'normal',
    color: colors.blueBorder,
    fontWeight: 500,
    lineHeight: '100%',
  },
  titleContainer: {
    display: 'flex',
    gap: 10,
  },
  propertiesContainer: {
    flex: 1,
    overflowX: 'auto',
    marginTop: 20,
    border: `1px solid ${colors.black5}`,
    borderRadius: 5,
    position: 'relative',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: adds a semi-transparent white background
    zIndex: 2, // Ensures overlay is on top of table content
  },
  titleStyle: {
    fontSize: 21,
  },
});
