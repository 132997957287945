import React, { createContext, useState, useContext, useEffect, useRef, useCallback } from 'react';
import {
  Gen5Config,
  AggregateConfig,
  EventDefinition,
  CommandDefinition,
  CommandVersionDefinition,
  PropertyCollection,
  MobileUIConfig,
  WebUIConfig,
  PagesConfig,
  MobileCardDefinition,
  WebCardDefinition,
  MobileActionButton,
  TimerAction,
} from '@terragotech/gen5-config-lib';
import { FunctionDefinition } from '@terragotech/gen5-datamapping-lib';
import { configExample } from '../utils/configExample';
import { cloneDeep } from 'lodash';
import {
  CardDefinition,
  UIConfigType,
  Events,
  EventVersion,
  MobileAggrUICustomization,
  WebAggrUICustomization,
  AggregateUICustomizations,
  FabAction,
} from '../utils/types';
import { Commands, FabActions, ActionButtons } from '../utils/types';
import { JSONSchema6 } from 'json-schema';
import { ActionButtonType } from '../components/ActionButtonEditor/ActionButtonEditor';
import { extractActionButtons, setExtractedActionButtons } from './contextUtils';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import {
  generateMapServiceAggregateData,
  removeMapServiceAggregateData,
} from 'utils/aggregatesGenerators';
import _ from 'lodash';
import { CARD_DEFINITION_KEY, MOBILE_ONLY_CARD_DEFINITION_KEYS, configType } from 'utils/Utils';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';

interface Props {
  children: React.ReactNode;
}

type ConfigDetail = { type: string; config: UIConfigType };
export type dynamicConfigDetails = {
  [key: string]: ConfigDetail;
};
export type ExtendedGen5Config = Gen5Config & {
  mobileUIConfig: MobileUIConfig &
    Record<string, any> & {
      aggregateUICustomizations?: Record<string, any>; //The index can be of type boolean, number , undefined
    };
  webUIConfig: WebUIConfig &
    Record<string, any> & {
      aggregateUICustomizations?: Record<string, any>; //The index can be of type boolean, number , undefined
    };
};
export interface ContextProps {
  config: ExtendedGen5Config;
  getConfig(): ExtendedGen5Config;
  setConfig(config: ExtendedGen5Config): void;

  getAggregates(): AggregateConfig[];
  setAggregates(aggregates: AggregateConfig[]): void;

  getFunctions(): Record<string, FunctionDefinition>;
  setFunctions(functions: Record<string, FunctionDefinition>): void;

  getCustomPages(): PagesConfig;
  setCustomPages(customPages: PagesConfig): void;

  setLegalDisclaimer(disclaimerTxt: string): void;
  getLegalDisclaimer(): string | '';

  setMapOptions(options: { nearMaps?: boolean; mapServices?: boolean }): void;
  getNearMaps(): boolean | false;
  getMapServices(): boolean | false;

  setGeographicMobile(
    isMandatoryClusteringEnabled: boolean,
    isRefocusToCurrentLocationAfterDirections: boolean
  ): void;
  getGeographicMobile(): MobileUIConfig;

  setGeographicWeb(
    polylineUnitOfMeasurement?: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers',
    polylineRoundingPrecision?: 'ones' | 'tenths' | 'hundredths' | 'thousandths'
  ): void;
  getGeographicWeb(): WebUIConfig;

  getAggregate(aggrIndex: number): AggregateConfig;
  setAggregate(aggrIndex: number, aggregate: AggregateConfig): void;

  getFunction(functionName: string): FunctionDefinition;
  setFunction(functionName: string, func: FunctionDefinition): void;

  getCustomPage(pageName: string): PagesConfig[keyof PagesConfig];
  setCustomPage(pageName: string, page: PagesConfig[keyof PagesConfig]): void;

  getAggregateProperties(aggrIndex: number): PropertyCollection;
  setAggregateProperties(aggrIndex: number, aggregateProperties: PropertyCollection): void;

  getDerivedPropertyMapping(aggrIndex: number): NodeMapDefinition | undefined;
  setDerivedPropertyMapping(aggrIndex: number, derivedPropertyMapping: NodeMapDefinition): void;

  getEvent(aggrIndex: number, eventName: string): EventDefinition | undefined;
  setEvent(aggrIndex: number, eventName: string, event: EventDefinition): void;

  getEvents(aggrIndex: number): Events | undefined;
  setEvents(aggrIndex: number, events: Events): void;

  getEventVersions(aggrIndex: number, eventName: string): EventVersion[] | undefined;
  setEventVersions(aggrIndex: number, eventName: string, versions: EventVersion[]): void;

  getEventVersion(
    aggrIndex: number,
    eventName: string,
    versionIndex: number
  ): EventVersion | undefined;
  setEventVersion(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    version: EventVersion
  ): void;

  setEventInternalDescription(aggrIndex: number, eventName: string, comment: string): void;

  setCommandInternalDescription(aggrIndex: number, eventName: string, comment: string): void;

  setFunctionInternalDescription(name: string, comment: string): void;

  getEventSchema(
    aggrIndex: number,
    eventName: string,
    versionIndex?: number
  ): JSONSchema6 | undefined;
  setEventSchema(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    eventSchema: JSONSchema6
  ): void;

  getAggregateMap(
    aggrIndex: number,
    eventName: string,
    versionIndex: number
  ): NodeMapDefinition | undefined;
  setAggregateMap(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    aggregateMap: NodeMapDefinition
  ): void;
  setFunctionMap(name: string, versionIndex: number, aggregateMap: NodeMapDefinition): void;

  getCommands(aggrIndex: number): Commands | undefined;
  setCommands(aggrIndex: number, commands: Commands): void;

  getCommand(aggrIndex: number, commandName: string): CommandDefinition | undefined;
  setCommand(aggrIndex: number, commandName: string, commands: CommandDefinition): void;

  getCommandVersions(
    aggrIndex: number,
    commandName: string
  ): CommandVersionDefinition[] | undefined;
  setCommandVersions(
    aggrIndex: number,
    commandName: string,
    versions: CommandVersionDefinition[]
  ): void;

  getCommandVersion(
    aggrIndex: number,
    commandName: string,
    versionIndex: number
  ): CommandVersionDefinition | undefined;
  setCommandVersion(
    aggrIndex: number,
    commandName: string,
    versionIndex: number,
    version: CommandVersionDefinition
  ): void;

  getUICustomizations(UIConfigType: UIConfigType): AggregateUICustomizations | undefined;

  setUICustomizations(
    UIConfigType: UIConfigType,
    aggrUICustomizations: AggregateUICustomizations
  ): void;

  getUICustomization(
    UIConfigType: UIConfigType,
    aggrUIName: string
  ): MobileAggrUICustomization | WebAggrUICustomization | undefined;

  setUICustomization(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    aggrUICustomization:
      | MobileAggrUICustomization
      | WebAggrUICustomization
      | {
          mapLabelProperties: string[];
          showMapLabelTitles: boolean;
          showEmptyMapLabels: boolean;
        }
  ): void;

  getFabActions(UIConfigType: UIConfigType): FabActions;
  setFabActions(UIConfigType: UIConfigType, fabActions: FabActions): void;

  getImportActions(UIConfigType: UIConfigType): FabActions;
  setImportActions(UIConfigType: UIConfigType, fabActions: FabActions): void;

  getActionButtons(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType
  ): ActionButtons | undefined;
  setActionButtons(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    fabActions: ActionButtons,
    isMergedConfig?: boolean
  ): void;

  getCardDefinition(UIConfigType: UIConfigType, aggrName: string): CardDefinition | undefined;
  setCardDefinition(
    UIConfigType: UIConfigType,
    aggrName: string,
    webCardDefinition: WebCardDefinition,
    mobileCardDefinition: MobileCardDefinition
  ): void;

  configName: string;
  setConfigName(name: string): void;

  configDetails: dynamicConfigDetails;
  getLinkUnLinkStatus: (
    webConfig: WebUIConfig,
    mobileConfig: MobileUIConfig,
    definitionName: string
  ) => boolean;
  handleConfigSave: (
    key: string,
    selectedConfig: string,
    modifiedConfig: ExtendedGen5Config
  ) => void;
  handleConfigTypeChange: (
    key: string,
    type: { type: string; config: UIConfigType },
    prevType: string
  ) => void;

  setConfigDetails: (key: string, value: ConfigDetail) => void;

  getCustomPageFabActions(pageName: string): FabAction[];
  setCustomPageFabActions(pageName: string, fabActions: FabAction[]): void;

  getScheduleActions(aggregateName: string): TimerAction[];
  setScheduleActions(aggregateName: string, newSchedule: TimerAction[]): void;

  getDefaultLanguage(): string;
  setDefaultLanguage(language: string): void;

  getSupportedLanguages(): string[];
  setSupportedLanguages(languages: string[]): void;

  getTranslationsDictionary: () => {[key: string]: {[language: string]: string }};
  setTranslationsDictionary(translations: { [label: string]: { [language: string]: string } }): void;

  setFullTranslations(language: string, languages: string[], translations: { [label: string]: { [language: string]: string } }): void;
}

export const ConfigContext = createContext<ContextProps>({} as ContextProps);

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const [configName, setConfigName] = useState('example.json');

  const [conf, setConf] = useState(configExample as ExtendedGen5Config);
  const config = useRef(configExample as ExtendedGen5Config);

  const setConfig = useCallback((newVal: ExtendedGen5Config) => {
    config.current = newVal;
    setConf(newVal);
    StorageHelper.setByKey(StorageKeys.config, newVal);
  }, []);

  useEffect(() => {
    const configData = StorageHelper.getByKey(StorageKeys.config);
    if (configData) {
      setConfig(configData);
    }
  }, [setConfig]);

  const getConfig = useCallback(() => cloneDeep(config.current), []);

  const setLegalDisclaimer = useCallback(
    (disclaimerTxt: string) => {
      const configCopy = cloneDeep(config.current);
      if (_.isString(disclaimerTxt) && _.trim(disclaimerTxt).length > 0) {
        setConfig({ ...configCopy, legalDisclaimer: _.trim(disclaimerTxt) });
      } else {
        delete configCopy.legalDisclaimer;
        setConfig({ ...configCopy });
      }
    },
    [setConfig]
  );

  const getLegalDisclaimer = useCallback(
    () => cloneDeep(config.current).legalDisclaimer as string,
    []
  );

  const getNearMaps = useCallback(
    () => cloneDeep(config.current).webUIConfig?.integrations?.nearMaps as boolean,
    []
  );
  const getMapServices = useCallback(
    () => cloneDeep(config.current).webUIConfig?.integrations?.mapServices as boolean,
    []
  );
  const setMapOptions = useCallback(
    (options: { nearMaps?: boolean; mapServices?: boolean }) => {
      const configCopy = cloneDeep(config.current);

      // Update integrations flags.
      configCopy.webUIConfig.integrations = configCopy.webUIConfig.integrations ?? {};
      const currentIntegrations = { ...configCopy.webUIConfig.integrations };
      configCopy.webUIConfig.integrations.nearMaps =
        options.nearMaps ?? currentIntegrations.nearMaps;
      configCopy.webUIConfig.integrations.mapServices =
        options.mapServices ?? currentIntegrations.mapServices;

      // Update Map Service aggregate and related configurations.
      if (options.mapServices && !currentIntegrations.mapServices) {
        generateMapServiceAggregateData(configCopy);
      } else if (!options.mapServices && currentIntegrations.mapServices) {
        removeMapServiceAggregateData(configCopy);
      }

      setConfig(configCopy);
    },
    [setConfig]
  );
  const [configDetails, setConfDetails] = useState<dynamicConfigDetails>({});

  const setConfigDetails = useCallback((key: string, value: ConfigDetail) => {
    let newValue = {};
    setConfDetails((prev) => {
      newValue = { ...prev, [key]: value };
      return newValue;
    });
  }, []);

  const getLinkUnLinkStatus = (
    webConfig: WebUIConfig,
    mobileConfig: MobileUIConfig,
    definitionName: string
  ) => {
    let skipping_keys: string[] = [];
    if (definitionName.endsWith(`.${CARD_DEFINITION_KEY}`)) {
      skipping_keys = MOBILE_ONLY_CARD_DEFINITION_KEYS;
    }
    const isLinked = _.isEqual(
      _.omit(webConfig, skipping_keys),
      _.omit(mobileConfig, skipping_keys)
    );
    setConfigDetails(definitionName, isLinked ? configType.ALL : configType.WEB);
    return isLinked;
  };
  const handleConfigTypeChange = (
    key: string,
    type: { type: string; config: UIConfigType },
    prevType: string
  ) => {
    const configCopy = cloneDeep(conf);
    if (type.type === configType.ALL.type || prevType === configType.ALL.type) {
      configCopy.mobileUIConfig[key] = configCopy.webUIConfig[key];
      setConfig(configCopy);
      setConfigDetails(key, type);
    } else {
      setConfigDetails(key, type);
    }
  };

  const handleConfigSave = (
    key: string,
    selectedConfig: string,
    modifiedConfig: ExtendedGen5Config
  ) => {
    if (selectedConfig === configType.ALL.type) {
      modifiedConfig.mobileUIConfig[key] = modifiedConfig.webUIConfig[key];
      setConfig(modifiedConfig);
    } else {
      setConfig(modifiedConfig);
    }
  };
  const setGeographicMobile = useCallback(
    (isMandatoryClusteringEnabled: boolean, isRefocusToCurrentLocationAfterDirections: boolean) => {
      const configCopy = cloneDeep(config.current);
      if (!configCopy.mobileUIConfig.mapSettings)
        configCopy.mobileUIConfig.mapSettings = { isMandatoryClusteringEnabled: false };

      configCopy.mobileUIConfig.mapSettings.isMandatoryClusteringEnabled = isMandatoryClusteringEnabled;
      if (configCopy.mobileUIConfig.enabledFeatures)
        configCopy.mobileUIConfig.enabledFeatures.refocusToCurrentLocationAfterDirections = isRefocusToCurrentLocationAfterDirections;
      else
        configCopy.mobileUIConfig.enabledFeatures = {
          refocusToCurrentLocationAfterDirections: isRefocusToCurrentLocationAfterDirections,
        };

      setConfig(configCopy);
    },
    [setConfig]
  );

  //Called webUIConfig mapSettings, name not specified
  const setGeographicWeb = useCallback(
    (
      polylineUnitOfMeasurement?: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers',
      polylineRoundingPrecision?: 'ones' | 'tenths' | 'hundredths' | 'thousandths'
    ) => {
      const configCopy = cloneDeep(config.current);
      configCopy.webUIConfig.geographic = {
        polylineUnitOfMeasurement: 'feet',
        polylineRoundingPrecision: 'ones',
      };
      if (polylineUnitOfMeasurement) {
        configCopy.webUIConfig.geographic.polylineUnitOfMeasurement = polylineUnitOfMeasurement;
      }
      if (polylineRoundingPrecision) {
        configCopy.webUIConfig.geographic.polylineRoundingPrecision = polylineRoundingPrecision;
      }
      setConfig(configCopy);
    },
    [setConfig]
  );

  const getGeographicMobile = useCallback(
    () => cloneDeep(config.current).mobileUIConfig as MobileUIConfig,
    []
  );
  const getGeographicWeb = useCallback(
    () => cloneDeep(config.current).webUIConfig as WebUIConfig,
    []
  );

  const getAggregates = useCallback(() => cloneDeep(config.current.aggregates), []);
  const setAggregates = useCallback(
    (aggregates: AggregateConfig[]) => setConfig({ ...cloneDeep(config.current), aggregates }),
    [setConfig]
  );

  const getFunctions = useCallback(() => cloneDeep(config.current.functions ?? {}), []);
  const setFunctions = useCallback(
    (funcs: Record<string, FunctionDefinition>) =>
      setConfig({ ...cloneDeep(config.current), functions: funcs }),
    [setConfig]
  );

  const getCustomPages = useCallback(() => cloneDeep(config.current.pagesConfig ?? {}), []);
  const setCustomPages = useCallback(
    (pages: PagesConfig) => setConfig({ ...cloneDeep(config.current), pagesConfig: pages }),
    [setConfig]
  );

  const getAggregate = useCallback(
    (aggrIndex: number) => cloneDeep(config.current.aggregates[aggrIndex]),
    []
  );
  const setAggregate = useCallback(
    (aggrIndex: number, aggregate: AggregateConfig) =>
      setAggregates(getAggregates().map((item, index) => (index === aggrIndex ? aggregate : item))),
    [getAggregates, setAggregates]
  );

  const getFunction = useCallback(
    (functionName: string) =>
      cloneDeep(
        config.current.functions
          ? config.current.functions[functionName]
          : ({} as FunctionDefinition)
      ),
    //Need this dependency for getting updated functional map
    // for reference https://github.com/facebook/react/issues/16291#issuecomment-890858870
    [config.current] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const setFunction = useCallback(
    (functionName: string, func: FunctionDefinition) => {
      const f = getFunctions();
      f[functionName] = func;
      setFunctions(f);
    },
    [getFunctions, setFunctions]
  );

  const getCustomPage = useCallback(
    (pageName: string) =>
      cloneDeep(config.current.pagesConfig?.[pageName] ?? ({} as PagesConfig[keyof PagesConfig])),
    []
  );
  const setCustomPage = useCallback(
    (pageName: string, page: PagesConfig[keyof PagesConfig]) => {
      const pages = getCustomPages();
      pages[pageName] = page;
      setCustomPages(pages);
    },
    [getCustomPages, setCustomPages]
  );

  const getAggregateProperties = useCallback(
    (aggrIndex: number) => cloneDeep(config.current.aggregates[aggrIndex].properties),
    []
  );
  const setAggregateProperties = useCallback(
    (aggrIndex: number, aggregateProperties: PropertyCollection) => {
      const aggregate = getAggregate(aggrIndex);
      aggregate.properties = aggregateProperties;
      setAggregate(aggrIndex, aggregate);
    },
    [getAggregate, setAggregate]
  );

  const getDerivedPropertyMapping = useCallback(
    (aggrIndex: number) =>
      cloneDeep(config.current.aggregates?.[aggrIndex]?.derivedPropertyMapping),
    []
  );
  const setDerivedPropertyMapping = useCallback(
    (aggrIndex: number, derivedPropertyMapping: NodeMapDefinition) =>
      setAggregate(aggrIndex, {
        ...getAggregate(aggrIndex),
        ...{ derivedPropertyMapping },
      }),
    [getAggregate, setAggregate]
  );

  const getEvents = useCallback(
    (aggrIndex: number) => cloneDeep(config.current.aggregates[aggrIndex]?.events),
    []
  );
  const setEvents = useCallback(
    (aggrIndex: number, events: Events) => {
      const aggregate = getAggregate(aggrIndex);
      aggregate.events = events;
      setAggregate(aggrIndex, aggregate);
    },
    [getAggregate, setAggregate]
  );

  const getEvent = useCallback(
    (aggrIndex: number, eventName: string) =>
      cloneDeep(config.current.aggregates[aggrIndex]?.events?.[eventName]),
    []
  );
  const setEvent = useCallback(
    (aggrIndex: number, eventName: string, event: EventDefinition) =>
      setEvents(aggrIndex, { ...getEvents(aggrIndex), [eventName]: event }),
    [getEvents, setEvents]
  );

  const getEventVersions = useCallback(
    (aggrIndex: number, eventName: string) =>
      cloneDeep(config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions),
    []
  );

  const setEventVersions = useCallback(
    (aggrIndex: number, eventName: string, versions: EventVersion[]) => {
      const event = cloneDeep(config.current).aggregates[aggrIndex].events?.[eventName];
      if (event) {
        event.versions = versions;
        setEvent(aggrIndex, eventName, event);
      }
    },
    [setEvent]
  );

  const getEventVersion = useCallback(
    (aggrIndex: number, eventName: string, versionIndex: number) =>
      cloneDeep(config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]),
    []
  );

  const setEventVersion = useCallback(
    (aggrIndex: number, eventName: string, versionIndex: number, version: EventVersion) => {
      const versions = cloneDeep(config.current).aggregates[aggrIndex].events?.[eventName]
        ?.versions;
      if (versions) {
        versions[versionIndex] = version;
        setEventVersions(aggrIndex, eventName, versions);
      }
    },
    [setEventVersions]
  );

  const getEventSchema = useCallback(
    (aggrIndex: number, eventName: string, versionIndex?: number) => {
      if (versionIndex === 0 || versionIndex) {
        return cloneDeep(
          config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]
            ?.eventSchema
        );
      } else {
        const eventVersions = config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions;
        if (eventVersions) {
          const lastVersionIndex = eventVersions.length - 1;
          return cloneDeep(
            config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions[lastVersionIndex]
              ?.eventSchema
          );
        }
      }
    },
    []
  );

  const setEventInternalDescription = useCallback(
    (aggrIndex: number, eventName: string, comment: string) => {
      const event = cloneDeep(config.current).aggregates[aggrIndex].events?.[eventName];
      if (event) {
        event.$internalDescription = comment;
        setEvent(aggrIndex, eventName, event);
      }
    },
    [setEvent]
  );

  const setFunctionInternalDescription = useCallback(
    (name: string, comment: string) => {
      const func = getFunction(name);
      if (func) {
        func.$internalDescription = comment;
        setFunction(name, func);
      }
    },
    [getFunction, setFunction]
  );
  const setEventSchema = useCallback(
    (aggrIndex: number, eventName: string, versionIndex: number, eventSchema: JSONSchema6) => {
      const eventVersion = getEventVersion(aggrIndex, eventName, versionIndex);
      if (eventVersion)
        setEventVersion(aggrIndex, eventName, versionIndex, {
          ...eventVersion,
          ...{ eventSchema },
        });
    },
    [getEventVersion, setEventVersion]
  );

  const getAggregateMap = useCallback(
    (aggrIndex: number, eventName: string, versionIndex: number) =>
      cloneDeep(
        config.current.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]
          ?.aggregateMap
      ),
    []
  );

  const setAggregateMap = useCallback(
    (
      aggrIndex: number,
      eventName: string,
      versionIndex: number,
      aggregateMap: NodeMapDefinition
    ) => {
      const eventVersion = getEventVersion(aggrIndex, eventName, versionIndex);
      if (eventVersion)
        setEventVersion(aggrIndex, eventName, versionIndex, {
          ...eventVersion,
          ...{ aggregateMap },
        });
    },
    [getEventVersion, setEventVersion]
  );

  const setFunctionMap = useCallback(
    (name: string, versionIndex: number, aggregateMap: NodeMapDefinition) => {
      const func = getFunction(name);
      const ver = func?.versions[versionIndex];
      if (ver) func.versions[versionIndex].aggregateMap = aggregateMap;
      setFunction(name, func);
    },
    [getFunction, setFunction]
  );

  const getCommands = useCallback(
    (aggrIndex: number) => cloneDeep(config.current.aggregates[aggrIndex]?.commands),
    []
  );
  const setCommands = useCallback(
    (aggrIndex: number, commands: Commands) =>
      setAggregate(aggrIndex, { ...getAggregate(aggrIndex), commands }),
    [getAggregate, setAggregate]
  );

  const getCommand = useCallback(
    (aggrIndex: number, commandName: string) =>
      cloneDeep(config.current.aggregates[aggrIndex]?.commands?.[commandName]),
    []
  );
  const setCommand = useCallback(
    (aggrIndex: number, commandName: string, command: CommandDefinition) =>
      setCommands(aggrIndex, { ...getCommands(aggrIndex), [commandName]: command }),
    [getCommands, setCommands]
  );

  const getCommandVersions = useCallback(
    (aggrIndex: number, commandName: string) =>
      cloneDeep(config.current.aggregates[aggrIndex]?.commands?.[commandName]?.versions),
    []
  );

  const setCommandVersions = useCallback(
    (aggrIndex: number, commandName: string, versions: CommandVersionDefinition[]) => {
      const command = cloneDeep(config.current).aggregates[aggrIndex].commands?.[commandName];
      if (command) {
        command.versions = versions;
        setCommand(aggrIndex, commandName, command);
      }
    },
    [setCommand]
  );

  const getCommandVersion = useCallback(
    (aggrIndex: number, commandName: string, versionIndex: number) =>
      cloneDeep(
        config.current.aggregates[aggrIndex]?.commands?.[commandName]?.versions[versionIndex]
      ),
    []
  );

  const setCommandVersion = useCallback(
    (
      aggrIndex: number,
      commandName: string,
      versionIndex: number,
      version: CommandVersionDefinition
    ) => {
      const versions = cloneDeep(config.current).aggregates[aggrIndex].commands?.[commandName]
        ?.versions;
      if (versions) {
        versions[versionIndex] = version;
        setCommandVersions(aggrIndex, commandName, versions);
      }
    },
    [setCommandVersions]
  );

  const setCommandInternalDescription = useCallback(
    (aggrIndex: number, commandName: string, comment: string) => {
      const command = cloneDeep(config.current).aggregates[aggrIndex].commands?.[commandName];
      if (command) {
        command.$internalDescription = comment;
        setCommand(aggrIndex, commandName, command);
      }
    },
    [setCommand]
  );

  const getUICustomizations = useCallback(
    (UIConfigType: UIConfigType) =>
      cloneDeep(config.current[UIConfigType].aggregateUICustomizations),
    []
  );
  const setUICustomizations = useCallback(
    (UIConfigType: UIConfigType, aggrUICustomizations: AggregateUICustomizations) => {
      const configCopy = cloneDeep(config.current);
      if (configCopy[UIConfigType].aggregateUICustomizations)
        (configCopy[UIConfigType]
          .aggregateUICustomizations as AggregateUICustomizations) = aggrUICustomizations;
      setConfig(configCopy);
    },
    [setConfig]
  );

  const getUICustomization = useCallback(
    (UIConfigType: UIConfigType, aggrUIName: string) =>
      cloneDeep(config.current[UIConfigType].aggregateUICustomizations?.[aggrUIName]),
    []
  );
  const setUICustomization = useCallback(
    (
      UIConfigType: UIConfigType,
      aggrUIName: string,
      aggrUICustomization:
        | MobileAggrUICustomization
        | WebAggrUICustomization
        | {
            mapLabelProperties: string[];
            showMapLabelTitles: boolean;
            showEmptyMapLabels: boolean;
          }
    ) => {
      const configCopy = cloneDeep(config.current);
      const currentConfig = configCopy[UIConfigType];
      if (currentConfig) {
        const aggregateUICustomizations = currentConfig.aggregateUICustomizations;
        if (aggregateUICustomizations)
          aggregateUICustomizations[aggrUIName] = {
            ...aggregateUICustomizations[aggrUIName],
            ...aggrUICustomization,
          };
        setConfig(configCopy);
      }
    },
    [setConfig]
  );

  const getFabActions = useCallback(
    (UIConfigType: UIConfigType) =>
      cloneDeep(config.current[UIConfigType].fabActions) as FabActions,
    []
  );
  const setFabActions = useCallback(
    (UIConfigType: UIConfigType, fabActions: FabActions) => {
      const configCopy = cloneDeep(config.current);
      if (configDetails.fabActions.type === configType.ALL.type) {
        configCopy.mobileUIConfig.fabActions = (fabActions as unknown) as Array<{
          aggregateName?: string;
          button: MobileActionButton;
        }>;
        configCopy.webUIConfig.fabActions = fabActions;
        setConfig(configCopy);
        return;
      }
      const uiConfigType = configCopy[UIConfigType];
      uiConfigType.fabActions = fabActions;
      setConfig(configCopy);
    },
    [setConfig, configDetails]
  );

  const getImportActions = useCallback(
    (_UIConfigType?: UIConfigType) =>
      cloneDeep(config.current.webUIConfig.importActions) as FabActions,
    []
  );
  const setImportActions = useCallback(
    (_UIConfigType: UIConfigType, fabActions: FabActions) => {
      const configCopy = cloneDeep(config.current);
      const uiConfigType = configCopy.webUIConfig;
      uiConfigType.importActions = fabActions;
      setConfig(configCopy);
    },
    [setConfig]
  );

  const getActionButtons = useCallback(
    (UIConfigType: UIConfigType, aggrUIName: string, actionButtonType: ActionButtonType) =>
      extractActionButtons(cloneDeep(config.current), UIConfigType, aggrUIName, actionButtonType),
    []
  );

  const setActionButtons = useCallback(
    (
      UIConfigType: UIConfigType,
      aggrUIName: string,
      actionButtonType: ActionButtonType,
      actionButton: ActionButtons,
      isMergedConfig?: boolean
    ) => {
      const configCopy = setExtractedActionButtons(
        cloneDeep(config.current),
        UIConfigType,
        aggrUIName,
        actionButtonType,
        isMergedConfig || false,
        actionButton
      );
      if (configCopy) setConfig(configCopy);
    },
    [setConfig]
  );

  const getCardDefinition = useCallback(
    (UIConfigType: UIConfigType, aggrName: string) =>
      cloneDeep(
        config.current[UIConfigType]?.aggregateUICustomizations?.[aggrName]?.cardDefinition
      ),
    []
  );

  const setCardDefinition = useCallback(
    (
      UIConfigType: UIConfigType,
      aggrName: string,
      webCardDefinition: WebCardDefinition,
      mobileCardDefinition: MobileCardDefinition
    ) => {
      const configCopy = cloneDeep(config.current);
      const mobUIConfig = configCopy.mobileUIConfig.aggregateUICustomizations;
      const webUIConfig = configCopy.webUIConfig.aggregateUICustomizations;
      if (
        configDetails[`${aggrName}.cardDefinition`]?.type === configType.ALL.type &&
        mobUIConfig &&
        webUIConfig
      ) {
        mobUIConfig[aggrName].cardDefinition = mobileCardDefinition;
        webUIConfig[aggrName].cardDefinition = webCardDefinition;
        setConfig(configCopy);
        return;
      }
      const aggrUICustomizations = configCopy[UIConfigType].aggregateUICustomizations;
      if (aggrUICustomizations) {
        aggrUICustomizations[aggrName].cardDefinition =
          UIConfigType === 'mobileUIConfig' ? mobileCardDefinition : webCardDefinition;
        setConfig(configCopy);
      }
    },
    [setConfig, configDetails]
  );

  const getCustomPageFabActions = useCallback(
    (pageName: string) => cloneDeep(config.current.pagesConfig?.[pageName].fabActions ?? []),
    []
  );
  const setCustomPageFabActions = useCallback(
    (pageName: string, fabActions: FabAction[]) => {
      const page = getCustomPage(pageName);
      page.fabActions = fabActions;
      setCustomPage(pageName, page);
    },
    [getCustomPage, setCustomPage]
  );

  const getScheduleActions = useCallback((recordName: string) => {
    const schedules = cloneDeep(config.current.schedules) || {};
    return schedules[recordName] || [];
  }, []);

  const setScheduleActions = useCallback(
    (recordName: string, newSchedule: TimerAction[]) => {
      const configCopy = cloneDeep(config.current) || {};
      if (!configCopy.schedules) configCopy.schedules = {};
      const schedules = configCopy.schedules;
      schedules[recordName] = newSchedule;
      setConfig(configCopy);
    },
    [setConfig]
  );

  const getDefaultLanguage = useCallback(
    () => cloneDeep(config.current.translations?.languages?.default) || '',
    []
  );

  const setDefaultLanguage = useCallback(
    (inputLanguage: string) => {
      const configCopy = cloneDeep(config.current) || {};
      if (!configCopy.translations) configCopy.translations = {};
      if (!configCopy.translations.languages) configCopy.translations.languages = {};
      configCopy.translations.languages.default = inputLanguage;
      setConfig(configCopy);
    }, [setConfig]
  );

  const getSupportedLanguages = useCallback(
    () => cloneDeep(config.current.translations?.languages?.supportedLanguages) ?? [],
    []
  );

  const setSupportedLanguages = useCallback(
    (inputSupportedLanguages: string[]) => {
      const configCopy = cloneDeep(config.current) || {};
      if (!configCopy.translations) configCopy.translations = {};
      if (!configCopy.translations.languages) configCopy.translations.languages = {};
      configCopy.translations.languages.supportedLanguages = inputSupportedLanguages;
      setConfig(configCopy);
    },
    [setConfig]
  );

  const getTranslationsDictionary = useCallback(
    () => cloneDeep(config.current.translations?.dictionary) || {},
    []
  );

  const pruneTranslations = (translations: { [label: string]: { [language: string]: string } }) => {
    return Object.fromEntries(
      Object.entries(translations).filter(([, langObj]) => {
        const langKeys = Object.keys(langObj);
        return langKeys.length > 1 || (langKeys.length === 1 && langKeys[0] !== 'English');
      })
    );
  };
  

  const setTranslationsDictionary = useCallback(
    (translations: { [label: string]: {[language: string]: string}}) => {
      const configCopy = cloneDeep(config.current) || {};
      if (!configCopy.translations) configCopy.translations = {};
      
      configCopy.translations.dictionary = pruneTranslations(translations);
      setConfig(configCopy);
    },
    [setConfig]
  );

  const setFullTranslations = useCallback(
    (language: string, languages: string[], translations: { [label: string]: { [language: string]: string } }) => {
      const configCopy = cloneDeep(config.current) || {};
      if (!configCopy.translations) configCopy.translations = {};

      configCopy.translations.languages = { default: language, supportedLanguages: languages };
  
      configCopy.translations.dictionary = pruneTranslations(translations);
      setConfig(configCopy);
    },
    [setConfig]
  );
  

  return (
    <ConfigContext.Provider
      value={{
        config: conf,
        getConfig,
        setConfig,
        getAggregates,
        setAggregates,
        getAggregate,
        setAggregate,
        getAggregateProperties,
        setAggregateProperties,
        getDerivedPropertyMapping,
        setDerivedPropertyMapping,
        getEvent,
        setEvent,
        getEvents,
        setEvents,
        getEventVersions,
        setEventVersions,
        getEventVersion,
        setEventVersion,
        getEventSchema,
        setEventSchema,
        getAggregateMap,
        setAggregateMap,
        getCommands,
        setCommands,
        getCommand,
        setCommand,
        getCommandVersions,
        setCommandVersions,
        getCommandVersion,
        setCommandVersion,
        getUICustomizations,
        setUICustomizations,
        getUICustomization,
        setUICustomization,
        getFabActions,
        setFabActions,
        getImportActions,
        setImportActions,
        getActionButtons,
        setActionButtons,
        getCardDefinition,
        setCardDefinition,
        configName,
        setConfigName,
        setEventInternalDescription,
        setCommandInternalDescription,
        setFunctionInternalDescription,
        setLegalDisclaimer,
        getLegalDisclaimer,
        setGeographicMobile,
        getGeographicMobile,
        setMapOptions,
        getNearMaps,
        getMapServices,
        getFunction,
        setFunction,
        getFunctions,
        setFunctions,
        setFunctionMap,
        getCustomPage,
        setCustomPage,
        getCustomPages,
        setCustomPages,
        getGeographicWeb,
        setGeographicWeb,
        getLinkUnLinkStatus,
        handleConfigSave,
        handleConfigTypeChange,
        configDetails,
        setConfigDetails,
        getCustomPageFabActions,
        setCustomPageFabActions,
        getScheduleActions,
        setScheduleActions,
        getDefaultLanguage,
        setDefaultLanguage,
        getSupportedLanguages,
        setSupportedLanguages,
        getTranslationsDictionary,
        setTranslationsDictionary,
        setFullTranslations
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): ContextProps => useContext<ContextProps>(ConfigContext);
