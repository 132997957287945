import { Modal, Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { TextInputTG } from '../../../../../components/formElements';

interface EditLanguageProps {
    isOpen: boolean;
    closeModal: () => void;
    editedLanguage: string;
    setEditedLanguage: (value: string) => void;
    saveEditedLanguage: () => void;
}

const EditLanguageModal: React.FC<EditLanguageProps> = ({
    isOpen,
    closeModal,
    editedLanguage,
    setEditedLanguage,
    saveEditedLanguage,
}) => {
    const classes = useStyles();

    const newLanguageChange = (newEditLanguage: string) => {
        setEditedLanguage(newEditLanguage);
    };

    return (
        <Modal open={isOpen} onClose={closeModal}>
            <Box className={classes.modal}>
                <Typography variant="h6" className={classes.modalHeader}>
                    Edit Language Name
                </Typography>
                <TextInputTG
                    className={classes.modalInput}
                    value={editedLanguage}
                    onChange={newLanguageChange}
                    placeholder="Enter new language name"
                />
                <Box className={classes.modalActions}>
                    <Button onClick={closeModal} color='primary' className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveEditedLanguage}
                        disabled={!editedLanguage.trim()}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(4),
            boxShadow: theme.shadows[5],
            outline: 'none',
        },
        modalHeader: {
            marginBottom: theme.spacing(2),
        },
        modalInput: {
            width: '100%',
        },
        modalActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
        },
        cancelButton: {
            marginRight: theme.spacing(2),
        },
    })
);

export default EditLanguageModal;
