import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Divider, Typography, Button, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@material-ui/core';
import { colors } from 'utils/colors';
import _ from 'lodash';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';
import { PROPERTIES_FILTER_TITLE } from '../../../../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      '@media all and (-ms-high-contrast: none)': {
        display: 'none',
      },
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(2px)',
    },
    paper: {
      width: 413,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
    },
    header: {
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    modalDataContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    modalDataMain: {
      maxHeight: '70vh',
      overflowY: 'scroll',
    },
    headerText: {
      fontWeight: 500,
      fontSize: 20,
      marginLeft: 30,
    },
    iconCloseContainer: {
      position: 'relative',
      right: 15,
      width: 48,
      height: 48,
    },
    closeIcon: {
      fontSize: 24,
    },
    bottomContainer: {
      display: 'flex',
      padding: '13px 26px',
      justifyContent: 'space-between',
      gap: 15,
      boxShadow: `0px -2px 4px 0px ${colors.black5}`,
    },
    buttonContainer: {
      padding: '10px 34px 11px 33px',
      width: 173,
      borderRadius: 4,
      fontSize: 18,
      fontWeight: 500,
      height: 40,
    },
    checkBoxColor: {
      color: colors.blueBorder,
    },
    text: {
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
    },
  })
);
interface filterModalProps {
  filterModal: boolean;
  setFilterModal: (val: boolean) => void;
  data: object[];
  setCustomizedColumns: (data: object[]) => void;
  customizedColumns?: object[];
}
export default function FilterModal(props: filterModalProps) {
  const classes = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { filterModal, setFilterModal, data, setCustomizedColumns, customizedColumns } = props;
  const closeModal = () => {
    setFilterModal && setFilterModal(!filterModal);
  };
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [list, setList] = useState<object[]>([]);
  const [listFilter, setListFilter] = useState<object[] | undefined>(customizedColumns);
  const filteredColumns = _.filter(listFilter, (obj: object) => {
    const objResponse = obj as { field: string };
    return isCheck.includes(objResponse.field);
  });
  const columnsHeaders = _.uniqWith(filteredColumns, _.isEqual);
  useEffect(() => {
    setList(data as object[]);
  }, [list, data]);
  const handleSave = () => {
    setCustomizedColumns(columnsHeaders as object[]);
    StorageHelper.setByKey(StorageKeys.propertyColumn, [...isCheck]);
    closeModal();
  };

  useEffect(() => {
    if (customizedColumns?.length !== 0) {
      const temp = _.map(customizedColumns, (item: object) => {
        const tempColumns = item as { field: string };
        return tempColumns.field;
      });
      setIsCheck(temp as string[]);
    }
  }, [customizedColumns]);

  const handleSelectAll = () => {
    setIsCheck(
      _.map(list, (item: object) => {
        const columnHeaders = item as { field: string };
        return columnHeaders.field;
      })
    );
    setListFilter(data);
    if ((isCheck.length !== 0) === (data.length !== 0)) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: { target: { id: string; checked: boolean } }) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (checked) {
      const response =
        data &&
        _.find(data, (item: object) => {
          const temp = item as { field: string };
          return temp.field === id;
        });
      setListFilter([...(listFilter as object[]), response] as object[]);
    }
    if (!checked) {
      setIsCheck(isCheck.filter((item: string) => item !== id));
    }
  };
  const isPartialSelect = isCheck?.length > 0 && data?.length !== isCheck.length;
  const isAllChecked = isCheck?.length > 0 && data?.length === isCheck.length;
  return (
    <ModalComponent className={classes.root} ref={rootRef}>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={filterModal}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
      >
        <ModalSubComponent>
          <Box className={classes.paper}>
            <Box className={classes.header}>
              <Typography className={classes.headerText}>{PROPERTIES_FILTER_TITLE}</Typography>
              <IconButton className={classes.iconCloseContainer}>
                <FontAwesomeIcon
                  icon={faXmark}
                  color="grey"
                  className={classes.closeIcon}
                  onClick={closeModal}
                />
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.modalDataMain}>
              <Box className={classes.modalDataContainer}>
                <Checkbox
                  name="selectAll"
                  id="selectAll"
                  onClick={() => handleSelectAll()}
                  color="primary"
                  indeterminate={isPartialSelect}
                  checked={isAllChecked || isPartialSelect}
                  defaultChecked={isAllChecked || isPartialSelect}
                />
                <Typography>(Select)</Typography>
              </Box>
              {data &&
                data.map((column: object, index: number) => {
                  const result = column as { field: string; label: string };
                  return (
                    <Box className={classes.modalDataContainer} key={index}>
                      <Checkbox
                        key={index}
                        name={result.field}
                        id={result.field}
                        color="primary"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                          handleClick(e as never)
                        }
                        checked={isCheck.includes(result.field)}
                        defaultChecked={isCheck.includes(result.field)}
                      />
                      <Typography className={classes.text}>{result.label}</Typography>
                    </Box>
                  );
                })}
            </Box>
            <Box className={classes.bottomContainer}>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                className={classes.buttonContainer}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.buttonContainer}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </ModalSubComponent>
      </Modal>
    </ModalComponent>
  );
}
const ModalComponent = styled.div``;
const ModalSubComponent = styled.div``;
