import React, { useContext, useCallback, useMemo, useRef, useEffect, useState } from 'react';
import _, { cloneDeep, map } from 'lodash';
import { useBlocker, useParams } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { ActionButton, BuiltinMobileActions } from '@terragotech/gen5-config-lib';
import {
  getActionButtonsColumns,
  getCommandReferenceArr,
  commandRefObjToList,
  commandActionButtonActionToRef,
  actionButtonsToRows,
} from '../../utils/jsonPartsGenerators';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { useUICustomizationAPI } from '../../context/fakeAPIHooks/useUICustomizationAPI';
import { getAdditionalElements } from './ActionButtonEditorUtils';
import { ActionButtonToolbarItem } from './ActionButtonToolbarItem';
import {
  ActionButtonRow,
  AggrPropertyRow,
  UIConfigType,
  ActionButton as ActionBtn,
} from '../../utils/types';
import { extractActionButtons } from '../../context/contextUtils';
import useRouteBlocker from 'common/useBlocker';
import { CELL_STYLE, configType } from 'utils/Utils';
import useCommonStyles from 'views/useCommonStyles';
import { Box } from '@material-ui/core';

type MobileActionButton = ActionButton<BuiltinMobileActions>;
export type ActionButtonType =
  | 'editorWorkflows'
  | 'editorActions'
  | 'multiSelectActions'
  | 'tableActions';

type MobileActionButtonWithID = MobileActionButton & { tableData: { id: number } };

export type ActionButtonEditor =
  | 'editorWorkflows'
  | 'editorActions'
  | 'multiSelectActions'
  | 'tableActions';

export const ActionButtonEditor: React.FC = () => {
  const commonClasses = useCommonStyles();
  const { config, configDetails } = useContext(ConfigContext);
  const UICustomizationAPI = useUICustomizationAPI();
  const tableRef = useRef<MaterialTableRef>(null);
  const { action, aggrUICustomization: aggregateUICustomization } = useParams() as {
    device: string;
    action: string;
    aggrUICustomization: string;
  };
  const type = `${_.camelCase(action)}` as ActionButtonEditor;
  const KEY = `${aggregateUICustomization}.${type}`;
  const uiType = (configDetails[KEY]?.config as UIConfigType) || 'webUIConfig';
  const [actionButtons, setActionButtons] = useState(
    cloneDeep(extractActionButtons(config, uiType, aggregateUICustomization, type) || [])
  );
  useEffect(() => {
    setActionButtons(
      cloneDeep(extractActionButtons(config, uiType, aggregateUICustomization, type) || [])
    );
  }, [config, uiType, aggregateUICustomization, type]);

  const isButtonInvalid = useCallback((row: object) => {
    if (!(row as ActionButtonRow).action) {
      errorMsg('Property "Action" is required');
      return true;
    }
    return false;
  }, []);

  const addActionButton = async (
    row: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(row)) return reject();
    const actionButton = commandActionButtonActionToRef(row as ActionButtonRow);
    const isMergedConfig = configDetails[KEY].type === configType.ALL.type;
    const { error } = await UICustomizationAPI.addActionButton(
      uiType,
      aggregateUICustomization,
      type,
      isMergedConfig,
      actionButton
    );
    if (error) reject();
    successMsg('Action button has been successfully added');
    resolve(null);
  };

  const updateActionButton = async (
    newRow: object,
    oldRow: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(newRow)) return reject();
    const actionButton = commandActionButtonActionToRef(newRow as ActionButtonRow);
    const actionButtonIndex = (oldRow as MobileActionButtonWithID).tableData.id;
    const isMergedConfig = configDetails[KEY].type === configType.ALL.type;
    const { error } = await UICustomizationAPI.updateActionButton(
      uiType,
      aggregateUICustomization,
      type as ActionButtonType,
      actionButtonIndex,
      actionButton,
      isMergedConfig
    );
    if (error) reject();
    successMsg('Action button has been successfully updated');
    resolve(null);
  };

  const deleteActionButton = async (rowToDel: object) => {
    const actionButtonIndex = (rowToDel as MobileActionButtonWithID).tableData.id;
    const isMergedConfig = configDetails[KEY].type === configType.ALL.type;
    const { error } = await UICustomizationAPI.deleteActionButton(
      uiType,
      aggregateUICustomization,
      type as ActionButtonType,
      actionButtonIndex,
      isMergedConfig,
    );
    if (error) return;
    successMsg(`Action button has been successfully deleted`);
  };

  const handleReorder = async (newData: unknown) => {
    const isMergedConfig = configDetails[KEY].type === configType.ALL.type;
    const { error } = await UICustomizationAPI.reOrderActionButton(
      uiType,
      aggregateUICustomization,
      type,
      isMergedConfig,
      newData as ActionBtn[]
    );
    if (error) return;
    successMsg('Action buttons has been reordered');
  };

  const getActionButtonColumns = useMemo(
    () =>
      getActionButtonsColumns(
        commandRefObjToList(
          getCommandReferenceArr(
            config,
            false,
            getAggregateIndex(config, aggregateUICustomization)
          ),
          getAdditionalElements(type, configDetails[KEY]?.type)
        ),
        config.aggregates[getAggregateIndex(config, aggregateUICustomization)].properties
      ),
    [config, type, aggregateUICustomization, KEY, configDetails]
  );

  const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && Boolean(lastEditingRow || showAddRow)
  );

  const onSave = () => {};

  useRouteBlocker({ blocker, onSave });

  const getToolbarItem = (
    <ActionButtonToolbarItem
      currentType={(configDetails[KEY]?.type as 'mobile' | 'web') || 'web'}
      type={type}
      excludedActionButtons={actionButtons}
      uiType={uiType}
      isMergedConfig={configDetails[KEY]?.type === configType.ALL?.type || false}
      aggregateUICustomization={aggregateUICustomization}
    />
  );
  return (
    <Box className={commonClasses.actionsInnerContainer}>
      <EditableTable
        title={action === 'editorActions' ? 'Editor/Table Actions' : _.startCase(action)}
        columns={map(getActionButtonColumns, (o) => ({
          ...o,
          cellStyle: CELL_STYLE,
        }))}
        data={actionButtonsToRows(actionButtons)}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addActionButton}
        onUpdate={updateActionButton}
        onDelete={deleteActionButton}
        options={tableOptions}
        toolbarItem={getToolbarItem}
        tableRef={tableRef}
        configKey={KEY}
        onReOrder={handleReorder}
      />
    </Box>
  );
};

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  sorting: false,
  draggable: false,
};
