import React, { useState } from 'react';
import { IconButton, makeStyles, Button, Box, Typography, Tooltip } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {
  MapScenarioType,
  NodeMapDefinition,
  mapScenarios,
} from '@terragotech/gen5-datamapping-lib';
import { cloneDeep, isEmpty } from 'lodash';
import { errorMsg, successMsg } from '../SnackbarUtilsConfigurator';
import DataMapperDialog from '../FormDialog/DataMapperDialog';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION, ADD_MODAL_WIDTH } from 'utils/Utils';
import { ValidationMapperForm } from 'components/FormDialog/ValidationMapperForm';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  title: string;
  data: NodeMapDefinition[];
  setData: (data: NodeMapDefinition[] | undefined) => void;
  style?: React.CSSProperties;
  mapScenario: keyof MapScenarioType;
  localSchemas?: LocalSchemaDefinition;
  containerStyle?: string;
}

export const MapperList = ({
  title,
  data,
  setData,
  mapScenario,
  localSchemas,
  containerStyle,
}: Props) => {
  const classes = useStyles();
  const [openedIndex, setOpenedIndex] = useState<number | undefined>(undefined);
  const { openConfirmation } = useConfirmDialog();
  const formDialog = useFormDialog();
  const handleOpenMapper = (index: number) => {
    setOpenedIndex(index);
  };

  const handleSaveMapperData = (dataItem: NodeMapDefinition | undefined) => {
    if (dataItem) {
      const newData = cloneDeep(data);
      newData[openedIndex || 0] = {name: newData[openedIndex || 0].name, description: newData[openedIndex || 0].description, ...dataItem};
      setData(newData);
      successMsg('The mapping has been successfully saved.');
    }
  };

  const handleDeleteMappingItem = async (index: number) => {
    const status = await openConfirmation(CONFIRMATION.mapperList);
    if (status === 'confirm') {
      const newData = cloneDeep(data);
      newData.splice(index, 1);
      setData(newData.length !== 0 ? newData : undefined);
      successMsg('New mapping has been successfully deleted');
    }
  };

  const handleAddNewMappingItem = async () => {
    const mapping = await formDialog<typeof ValidationMapperForm>(
      (props) => <ValidationMapperForm title={'Create New ' + title} submitText='Create' {...props} />,
      true,
      true,
        ADD_MODAL_WIDTH
    );
    if (!isValid(mapping.name)) return;
    const newData = cloneDeep(data);
    newData.push({ name: mapping.name, description: mapping.description, ...mapScenarios[mapScenario].defaultNodeMap });
    setData(newData);
    successMsg('New mapping has been successfully added');
  };

  const handleEditMappingItem = async (index: number) => {
    const newData = cloneDeep(data);
    const mapping = await formDialog<typeof ValidationMapperForm>(
      (props) => <ValidationMapperForm initialValues={{name: newData[index].name || '', description: newData[index].description || ''}} title={'Edit '+ title} submitText='Modify' {...props} />,
      true,
      true,
        ADD_MODAL_WIDTH
    );
    if (newData[index].name !== mapping.name) {
      if (!isValid(mapping.name)) return;
    }
    newData[index] = { ...newData[index] , name: mapping.name, description: mapping.description};
    setData(newData);
    successMsg('The mapping has been successfully saved.');
  };

  const isValid = (name: string) => {
    if (ifMappingNameExists(name)) {
      errorMsg(`${title} named "${name}" already exists`);
      return false;
    }
    return true;
  };

  const ifMappingNameExists = (name: string) =>
    data.some((e) => (e.name === undefined ? '' : e.name?.toUpperCase().trim()) === name.toUpperCase().trim());

  // Sets default name and description for preexisting mappers
  const setDefaultMapperLabel = (title: string, index: number, name: string | undefined) => {
    if (isEmpty(name) || name === '') {
      const newData = cloneDeep(data);
      newData[index] = { ...newData[index] , name: title + ' - ' + (index+1), description: ''};
      setData(newData);
    }
  };

  const defaultWarningMapping = {
    nodes: {
      FORM: { type: 'FORM', config: {}, inputs: {} },
      FORM_WARNING: {
        type: 'FORM-WARNING',
        config: {},
        inputs: {
          warningMessage: {
            sourceObject: 'newNode5',
            sourcePath: '$.output',
          },
        },
      },
      newNode1: { type: 'METADATA', config: {} },
      newNode2: {
        type: 'DISTANCE-CALC',
        config: { distanceUnit: 'feet' },
        inputs: {
          secondLatitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lat',
          },
          secondLongitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lon',
          },
          firstLatitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.latitude',
          },
          firstLongitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.longitude',
          },
        },
      },
      newNode3: { type: 'STATE', config: {} },
      newNode4: {
        type: 'NUMBER-GREATER-THAN',
        config: {},
        inputs: {
          compareNumber: '75',
          baseNumber: {
            sourceObject: 'newNode2',
            sourcePath: '$.output',
          },
        },
      },
      newNode5: {
        type: 'CONDITIONAL-MAPPER',
        config: {},
        inputs: {
          booleanSource: {
            sourceObject: 'newNode4',
            sourcePath: '$.isGreater',
          },
          trueSource:
            'You are more than 75 Feet from the selected feature. Please verify your location.',
        },
      },
    },
    outputDefinition: { outputNode: 'FORM_WARNING' },
  } as const;

  const addNewDefaultMappingItem = async () => {
    const newData = cloneDeep(data);
    const mapping = await formDialog<typeof ValidationMapperForm>(
      (props) => <ValidationMapperForm initialValues={{name: 'Proximity Warning Mapping - ' + (newData.length+1), description: ''}} title="Edit mapper" {...props} />,
      true,
      true,
        ADD_MODAL_WIDTH
    );
    if (!isValid(mapping.name)) return;
    newData.push({name: mapping.name, description: mapping.description, ...defaultWarningMapping});
    setData(newData);
    successMsg('New mapping has been successfully added');
  };

  const renderMappingList = () => {
    return data.map((_, index) => (setDefaultMapperLabel(title, index, _.name), 
      <Box
        key={index}
        className={`${classes.row} ${data.length - 1 !== index && classes.border}`}
      >
        <IconButton
          component="span"
          onClick={() => handleEditMappingItem(index)}
        >
          <FontAwesomeIcon icon={faPenToSquare} className={classes.icon} />
        </IconButton>
        <Tooltip title={_.description || 'No description available'}>
            <FontAwesomeIcon icon={faCircleInfo} className={classes.icon} />
        </Tooltip>
        <Button
          color="primary"
          onClick={() => handleOpenMapper(index)}
          className={classes.mapperButton}
        >
          {_.name} 
        </Button>
      <IconButton
          className={`${classes.mapperIcon}`}
          component="span"
          onClick={() => handleDeleteMappingItem(index)}
        >
          <FontAwesomeIcon icon={faMinus} className={classes.icon} />
        </IconButton>
      </Box>
    ))};

  return (
    <Box className={`${classes.container} ${containerStyle ?? ''}`}>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
        <Box>
          <IconButton onClick={handleAddNewMappingItem} >
            <FontAwesomeIcon icon={faPlus} className={classes.icon} />
          </IconButton>
          {title === 'Warning Mapping' && (
            <Tooltip title="Proximity Mapping">
              <IconButton
                onClick={addNewDefaultMappingItem}
                className={`${classes.mapperIcon}`}
              >
                <MyLocationIcon className={classes.addIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {data.length === 0 ? (
        <p className={classes.emptyContainer}>Mapping list is empty</p>
      ) : ( 
        <Box className={classes.table}>{renderMappingList()}</Box>
      )}
      <DataMapperDialog
        onClose={() => {
          setOpenedIndex(undefined);
        }}
        open={typeof openedIndex === 'number'}
        map={data[openedIndex || 0] as NodeMapDefinition}
        setMap={(dataItem) => handleSaveMapperData(dataItem)}
        mapScenario={mapScenario}
        localSchemaDefinitions={localSchemas}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    margin: '10px 0',
    overflow: 'hidden',
  },
  emptyContainer: {
      fontSize: 14,
      color: colors.black54,
      padding: '1px 23px',
    },
  header: {
    backgroundColor: colors.black5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 23,
    paddingRight: 7,
    height: 45,
  },
  headerText: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
  },
  table: {
    backgroundColor: colors.grayBackground,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 7px 3px 16px',
    backgroundColor: colors.white,
    height: 'auto',
  },
  icon: {
    color: colors.black54,
    fontSize: 20,
    fontWeight: 500,
  },
  border: {
    borderBottom: `1px solid ${colors.black10}`,
  },
  addIcon: {
    fontSize: 20,
    color: colors.black54,
  },
  mapperButton: {
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: 10,
    justifyContent: 'flex-start',
    width: 'auto',
  },
  mapperIcon: {
    marginLeft: 'auto',
  },
});
