import React, { useState, useContext, useRef, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckboxInput } from '../FormElements';
import { V2GroupComponentDef } from '@terragotech/page-renderer';
import { isEqual } from 'lodash';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import {
  CustomPageEditor,
  CustomPageEditorRef,
} from 'views/pages/customPages/pages/CustomPageEditor';
import { PageDialogServiceProvider } from './PageDialogService';
import usePageRedirect from './hooks/usePageRedirect';
import { TextInputTG } from 'views/components/formElements';
import { nameHelperTxt, useCustomPageStyles } from './commonStyles';
import { useConfirmDialog } from 'context/ConfirmContext';
import ModalFrame from './ModalFrame';
import MapperItem from 'components/FormDialog/MapperItem';
import { CONFIRMATION, getPresentValue } from 'utils/Utils';

export type GroupComponentDefWithName = V2GroupComponentDef & {
  name: string;
};

interface GroupEditFormFormProps {
  onSubmit: (result: GroupComponentDefWithName) => void;
  onClose: () => void;
  component: GroupComponentDefWithName;
}

export const GroupEditForm: React.FC<GroupEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [repeats, setRepeats] = useState(component.repeats);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const customPageEditorRef = useRef<CustomPageEditorRef>();
  const { openConfirmation } = useConfirmDialog();
  const [existingNameError, setExistingNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [emptyLabelError, setEmptyLabelError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  
  useEffect(() => {
    setExistingNameError(checkDuplicatePageName(name, component, pageDefinition.elements));
    setEmptyNameError(checkEmptyText(name));
  }, [name, component, pageDefinition.elements]);

  useEffect(() => {
    setEmptyLabelError(checkEmptyText(label));
  }, [label]);

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };

  const getValue = (value: unknown, key: string) => getPresentValue(component, value, key);

  const getFormValues = () => ({
    template: component.template,
    type: component.type,
    name: name || '',
    label: label || '',
    ...(displayOptions && { displayOptions }),
    ...getValue(repeats, 'repeats'),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () => {
    if (customPageEditorRef.current) {
      await customPageEditorRef.current.onClose({ handleSubmit });
    }
    return (
      (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
      onClose()
    );
  };

  const handleSubmit = async () => {
    if (customPageEditorRef.current) {
      let result = await customPageEditorRef.current.save();
      if (!result.success) return;
    }

    if (existingNameError || emptyNameError || emptyLabelError) {
      return;
    } else onSubmit(getFormValues());
  };

  const styles = {
    textInput: {
      margin: '10px 0',
      width: '100%',
    },
  };

  return (
    <ModalFrame
      {...{
        name: component.name,
        type: component.type,
        classes,
        handleClose,
        handleSubmit,
      }}
    >
      <TextInputTG
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError || emptyNameError}
        helperText={emptyNameError ? 'Name cannot be empty' : existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      <TextInputTG
        id="Label"
        label="Label"
        error={emptyLabelError}
        helperText={emptyLabelError ? 'Label cannot be empty' : ''}
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      {/* Disabling for now, until Repeatable rendering is implemented. */}
      <Typography className={classes.text}>Additional Options</Typography>
      <CheckboxInput title="Repeatable" checked={repeats} onChange={(value) => setRepeats(value)} />

      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: displayOptions !== undefined,
          clearMapper: handleClearDisplayMapper,
          openDataMap: conditionalOpen,
          dataMap: displayOptions,
          setDataMap: setDisplayOptions,
          localSchemaDefinition: pageSchemas,
          title: 'Display Options',
          mapScenario: 'DISPLAY_OPTIONS',
          containerStyle: classes.mapperContainer,
        }}
      />
      <PageDialogServiceProvider>
        <Box className={classes.customEditor}>
          <Typography className={[classes.text, classes.bottomContainer].join(' ')}>
            Table Options
          </Typography>
          <CustomPageEditor
            ref={customPageEditorRef}
            editPage={component.template}
            aggType={pageDefinition.aggregateType}
            fullPage={pageDefinition}
            modalView
          />
        </Box>
      </PageDialogServiceProvider>
    </ModalFrame>
  );
};
