import React from 'react';
import {
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  FormHelperText,
  useTheme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { ReactComponent as RightClickIcon } from '../../resources/images/icon-right-click.svg';
import { ReactComponent as LeftClickIcon } from '../../resources/images/icon-left-click.svg';

interface Props {
  onClose: () => void;
}

export const MapperShortCutInfoDialog: React.FC<Props> = ({ onClose }) => {
  const { palette } = useTheme();
  const primaryColor = palette.primary.main;
  return (
    <>
      <DialogTitle>Keyboard Shortcuts</DialogTitle>

      <DialogContent>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Select</Typography>
            <FormHelperText>Left click on node or link </FormHelperText>
          </LeftContainer>

          <StyledLeftClickIcon bgColor={primaryColor} />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Show Menu</Typography>
            <FormHelperText>Right click on node or link </FormHelperText>
          </LeftContainer>
          <StyledRightClickIcon bgColor={primaryColor} />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Multi-select</Typography>
            <FormHelperText>Shift + left drag on background</FormHelperText>
          </LeftContainer>
          <div style={styles.iconContainer}>
            <TextIconContainer bgColor={primaryColor}>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <StyledLeftClickIcon bgColor={primaryColor} />
            <PlusSeperator> + </PlusSeperator>
            <TextIconContainer bgColor={primaryColor}>
              <p>drag</p>
            </TextIconContainer>
          </div>
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Add to selection</Typography>
            <FormHelperText>Shift + left click on node or link</FormHelperText>
          </LeftContainer>
          <div style={styles.iconContainer}>
            <TextIconContainer bgColor={primaryColor}>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <StyledLeftClickIcon bgColor={primaryColor} />
          </div>
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Delete link </Typography>
            <FormHelperText>Right click on link(line) </FormHelperText>
          </LeftContainer>
          <StyledRightClickIcon bgColor={primaryColor} />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Delete selection set</Typography>
            <FormHelperText>Shift + delete with node(s) or link(s) selected</FormHelperText>
          </LeftContainer>
          <div style={styles.iconContainer}>
            <TextIconContainer bgColor={primaryColor}>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <TextIconContainer bgColor={primaryColor}>
              <p>delete</p>
            </TextIconContainer>
          </div>
        </ShortcutElement>
      </DialogContent>

      <DialogActions style={styles.cancelButton}>
        <Button color="primary" onClick={onClose}>
          CLOSE
        </Button>
      </DialogActions>
    </>
  );
};
const styles = {
  cancelButton: { marginRight: 10 },
  iconContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
};

const ShortcutElement = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledRightClickIcon = styled(RightClickIcon)<{ bgColor: string }>`
  height: 50px;
  width: 50px;
  padding: 10px;
  background-color: ${({ bgColor }) => bgColor};
`;
const StyledLeftClickIcon = styled(LeftClickIcon)<{ bgColor: string }>`
  height: 50px;
  width: 50px;
  padding: 10px;
  background-color: ${({ bgColor }) => bgColor};
`;
const PlusSeperator = styled.span`
  position: relative;
  font-size: large;
  color: ${colors.silverChalice};
  margin-left: 2px;
  margin-right: 2px;
`;

const TextIconContainer = styled.span<{ bgColor: string }>`
  height: 50px;
  width: 50px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-size: 13px;
    bottom: 3px;
  }
`;

const LeftContainer = styled.div`
  & p {
    margin-top: 0px;
  }
`;
