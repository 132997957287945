import React, { ReactNode } from 'react';
import { Box, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'utils/colors';

interface TitleToolTipProps {
  title: string;
  tooltipText?: string | ReactNode;
  minWidth?: number | null;
  titleStyle?: string | undefined | any[];
  titleContainer?: string;
  disabled?: boolean;
}

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.charcoal,
    color: colors.white,
    fontSize: 13,
  },
  arrow: {
    color: colors.charcoal,
  },
}))(Tooltip);

export const TitleToolTip: React.FC<TitleToolTipProps> = ({
  title,
  tooltipText,
  titleStyle = '',
  titleContainer = '',
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.rootContainer} ${titleContainer} ${disabled ? classes.disabled : ''}`}
    >
      <Typography className={`${classes.titleWrap} ${titleStyle}`}>{title}</Typography>
      {tooltipText ? (
        <DarkTooltip title={tooltipText} arrow>
          <FontAwesomeIcon icon={faCircleQuestion} className={classes.icon} />
        </DarkTooltip>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
  },
  icon: { fontSize: 16, color: colors.black },
  titleWrap: { whiteSpace: 'nowrap', fontSize: 15, fontWeight: 500 },
  disabled: {
    '& p.MuiTypography-root, svg': {
      color: colors.black54,
    },
  },
});
