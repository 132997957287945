import { Box, Typography } from '@material-ui/core';
import { V2LinkComponent } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useConfirmDialog } from '../../context/ConfirmContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { CONFIRMATION, expandPath, getPresentValue } from '../../utils/Utils';
import { TextInputTG as TextInput } from '../../views/components/formElements';
import MapperItem from '../FormDialog/MapperItem';
import { CheckboxInput } from '../FormElements';
import { useCustomPageStyles } from './commonStyles';
import { PageContext } from './contexts/PageContext';
import usePageRedirect from './hooks/usePageRedirect';
import { usePageSchemas } from './hooks/usePageSchemas';
import ModalFrame from './ModalFrame';

export type LinkTemplateWithName =
  | (V2LinkComponent & {
      name: string;
      droppableId: string;
    });

interface LinkEditFormProps {
  onSubmit: (result: LinkTemplateWithName) => void;
  onClose: () => void;
  component: LinkTemplateWithName;
}

export const LinkEditForm: React.FC<LinkEditFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [repeats, setRepeats] = useState(component.repeats);
  const [oneLine, setOneLine] = useState(component.oneLine);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const [existingNameError, setExistingNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [emptyLabelError, setEmptyLabelError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const { openConfirmation } = useConfirmDialog();

  const nameHelperTxt =
    'Duplicate name error. The name must be unique across all workflow elements';

  useEffect(() => {
    setExistingNameError(checkDuplicatePageName(name, component, pageDefinition.elements));
    setEmptyNameError(checkEmptyText(name));
  }, [name, component, pageDefinition.elements]);

  useEffect(() => {
    setEmptyLabelError(checkEmptyText(label));
  }, [label]);

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'page'
      ? []
      : expandPath(parts, pageSchemas.FORM.schema.properties as JSONSchema6);

  let pageValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    pageValue = { ...pageValue, ...t[i] };
  }

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: pageValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...pageSchemas,
  };

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };

  const getValue = (value: unknown, key: string) => getPresentValue(component, value, key);

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    ...getValue(repeats, 'repeats'),
    ...getValue(oneLine, 'oneLine'),
    ...(displayOptions && { displayOptions }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (existingNameError || emptyNameError || emptyLabelError) {
      return;
    } else onSubmit(getFormValues());
  };

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError || emptyNameError}
        helperText={emptyNameError ? 'Name cannot be empty' : existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={styles.textInput}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      <TextInput
        id="Label"
        label="Label"
        error={emptyLabelError}
        helperText={emptyLabelError ? 'Label cannot be empty' : ''}
        value={label}
        onChange={(value) => setLabel(value || '')}
        fullWidth={false}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      <Typography className={classes.text}>Additional Options</Typography>
      <CheckboxInput title="One Line" checked={oneLine} onChange={(value) => setOneLine(value)} />
      {/* Disabling for now, until Repeatable rendering is implemented. */}
      <Box style={{ position: 'relative', bottom: 12 }}>
        <CheckboxInput
          title="Repeatable"
          checked={repeats}
          onChange={(value) => setRepeats(value)}
        />
      </Box>
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: displayOptions !== undefined,
          clearMapper: handleClearDisplayMapper,
          openDataMap: conditionalOpen,
          dataMap: displayOptions,
          setDataMap: setDisplayOptions,
          localSchemaDefinition: errorWarningSchemas,
          title: 'Display Options',
          mapScenario: 'DISPLAY_OPTIONS',
          containerStyle: classes.mapperContainer,
        }}
      />
    </ModalFrame>
  );
};

const styles = {
  textInput: {
    margin: '10px 0',
    width: '100%',
  },
};
