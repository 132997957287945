import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell as TCell,
  TableContainer,
  TableHead,
  TableRow as TRow,
  Radio,
  styled,
  Grid,
} from '@material-ui/core';
import _, { isEqual, isNil } from 'lodash';
import { useBlocker, useParams } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg, warningMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { RadioInput } from '../FormElements';
import { ActionButton, WebCardDefinition } from '@terragotech/gen5-config-lib';
import { getAggregatePropertiesList } from '../../utils/jsonPartsGenerators';
import { MaterialTableRef } from '../EditableTable';
import { ConditionalCardDefinition } from '@terragotech/gen5-config-lib/dist/CardDefinitions/ConditionalCardDefinition';
import { useUICustomizationAPI } from '../../context/fakeAPIHooks/useUICustomizationAPI';
import { BuiltinWebActions, BuiltinMobileActions } from '@terragotech/gen5-config-lib';
import { CardDefinitionConditionalCard } from './CardDefinitionConditionalCard';
import { CardDefinitionOtherAttributes } from './CardDefinitionOtherAttributes';
import { CardDefinitionOtherAttribute, CombinedBuiltinActions, UIConfigType } from '../../utils/types';
import { MobileCardDefinition } from '@terragotech/gen5-config-lib/dist/CardDefinitions/MobileCardDefinition';
import { getCustomPageList } from '../../utils/helpers';
import useRouteBlocker from 'common/useBlocker';
import PageHeader from 'views/components/PageHeader';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { categoryColors, colors } from 'utils/colors';
import { configType } from 'utils/Utils';
import useCommonStyles from 'views/useCommonStyles';
import AccordionCard from 'components/AccordionCard';
import { TGSelect, TextInputTG } from 'views/components/formElements';

export type MobileActionButtonWithID = ActionButton<BuiltinMobileActions> & {
  tableData: { id: number };
};
export type WebActionButtonWithID = ActionButton<BuiltinWebActions> & { tableData: { id: number } };
export type ActionButtonWithID = MobileActionButtonWithID | WebActionButtonWithID;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type confType = 'mobile' | 'web' | undefined;

const TableCell = styled(TCell)({
  padding: '6px 16px',
  borderBottom: `1px solid ${colors.black10}`,
});

const TableRow = styled(TRow)({
  backgroundColor: colors.white1,
  '&:last-child td.MuiTableCell-root': {
    borderBottom: 'none',
  },
});

export const CardDefinition: React.FC = () => {
  const commonClasses = useCommonStyles();
  const { aggrUICustomization: aggrUIName } = useParams() as {
    aggrUICustomization: string;
  };
  const { config, getCardDefinition, configDetails } = useContext(ConfigContext);
  const customPageData = config.pagesConfig;

  const UICustomizationAPI = useUICustomizationAPI();
  const otherAttributesTableRef = useRef<MaterialTableRef>(null);
  const basicCardTableRef = useRef<MaterialTableRef>(null);
  const conditionalCardTableRef = useRef<MaterialTableRef>(null);
  const [configTypeState, setConfigTypeState] = useState<confType>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [uiConfigType, setUIConfigType] = useState<UIConfigType>(
    configTypeState === 'mobile' ? 'mobileUIConfig' : 'webUIConfig'
  );
  const mobileCardDefinition = useMemo(() => getCardDefinition('mobileUIConfig', aggrUIName), [
    getCardDefinition,
    aggrUIName,
  ]);
  const [cardDefinition, setCardDefinition] = useState(getCardDefinition(uiConfigType, aggrUIName));
  const [template, setTemplate] = useState<'conditionalCard' | undefined>(
    cardDefinition?.template || 'conditionalCard'
  );
  const [titleKey, setTitleKey] = useState(cardDefinition?.titleKey || '');
  const [cardHeight, setCardHeight] = useState(
    (cardDefinition as MobileCardDefinition)?.cardHeight || 370
  );
  const [otherAttributes, setOtherAttributes] = useState(cardDefinition?.otherAttributes || []);

  const KEY = `${aggrUIName}.cardDefinition`;
  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  useEffect(() => {
    let currentConfig: confType = 'mobile';
    const aggrNameConfigDetails = configDetails[KEY];

    if (aggrNameConfigDetails) {
      if (
        aggrNameConfigDetails.type === configType.ALL.type ||
        aggrNameConfigDetails.type === configType.WEB.type
      ) {
        currentConfig = 'web';
      } else {
        currentConfig = aggrNameConfigDetails.type as confType;
      }
    }
    const cardDef =
      aggrNameConfigDetails?.config &&
      getCardDefinition(aggrNameConfigDetails?.config as UIConfigType, aggrUIName);
    const otherCardAttr = cardDef?.otherAttributes || [];
    const otherAttribute: CardDefinitionOtherAttribute[] = [];
    for (const attribute of otherCardAttr) {
      const attrType = _.find(aggregateProps, { name: attribute.itemKey });
      otherAttribute.push({ ...attribute, type: attribute.type ? attribute.type : attrType?.type });
    }
    setConfigTypeState(currentConfig);
    setUIConfigType(aggrNameConfigDetails?.config as UIConfigType);
    setCardDefinition(cardDef);
    setOtherAttributes(otherAttribute);
  }, [configDetails, KEY, aggrUIName, aggregateProps, getCardDefinition]);

  const [secondaryButtonOptions, setSecondaryButtonOptions] = useState(
    (mobileCardDefinition as MobileCardDefinition)?.secondaryButtonOptions || {
      groupButtons: false,
      groupButtonLabel: 'Actions',
    }
  );

  const [hasUserModifiedOtherAttributes, setHasUserModifiedOtherAttributes] = useState<boolean>(
    false
  );

  const { lastEditingRow: basicLastEditingRow, showAddRow: basicShowAddRow } =
    basicCardTableRef?.current?.state || {};
  const { lastEditingRow: conditionalLastEditingRow, showAddRow: conditionalShowAddRow } =
    conditionalCardTableRef?.current?.state || {};
  const { lastEditingRow: otherLastEditingRow, showAddRow: otherShowAddRow } =
    otherAttributesTableRef?.current?.state || {};

  const [buttons, setButtons] = useState(
    (cardDefinition as ConditionalCardDefinition<CombinedBuiltinActions>)?.buttons || []
  );

  const [showTitleLabel, setShowTitleLabel] = useState(cardDefinition?.showTitleLabel);

  const [attributesType, setAttributesType] = useState(
    !!cardDefinition?.pageName ? 'customPage' : 'otherAttributes'
  );
  const [pageName, setPageName] = useState(cardDefinition?.pageName ?? '');

  useEffect(() => {
    setTemplate(cardDefinition?.template || 'conditionalCard');
    setTitleKey(cardDefinition?.titleKey || '');
    setCardHeight((cardDefinition as MobileCardDefinition)?.cardHeight || 370);
    setOtherAttributes(cardDefinition?.otherAttributes || []);
    setButtons((cardDefinition as ConditionalCardDefinition<CombinedBuiltinActions>)?.buttons || []);
    setShowTitleLabel(cardDefinition?.showTitleLabel);
    setAttributesType(!!cardDefinition?.pageName ? 'customPage' : 'otherAttributes');
    setPageName(cardDefinition?.pageName ?? '');
  }, [cardDefinition]);

  useEffect(() => {
    setSecondaryButtonOptions(
      (mobileCardDefinition as MobileCardDefinition)?.secondaryButtonOptions || {
        groupButtons: false,
        groupButtonLabel: 'Actions',
      }
    );
  }, [mobileCardDefinition]);

  const getCardDefinitionForm = () => ({
    template,
    titleKey,
    ...(!isNil(showTitleLabel) ? { showTitleLabel } : null),
    ...(uiConfigType === 'mobileUIConfig' && { cardHeight }),
    ...(uiConfigType === 'mobileUIConfig' && secondaryButtonOptions && { secondaryButtonOptions }),
    ...(hasUserModifiedOtherAttributes
      ? { otherAttributes }
      : { otherAttributes: cardDefinition?.otherAttributes }),
    ...(attributesType === 'customPage' && { pageName }),
    ...(template === 'conditionalCard' && { buttons }),
  });

  const handleSave = async () => {
    if (!titleKey) return errorMsg('Title Key field is required');
    if (secondaryButtonOptions.groupButtons) {
      if (!secondaryButtonOptions.groupButtonLabel?.trim().length)
        return errorMsg('Group button label field is required');
    } else {
      delete secondaryButtonOptions.groupButtonLabel;
    }
    if (attributesType === 'customPage' && !pageName)
      return errorMsg('Custom Page name is required');

    if (
      basicLastEditingRow ||
      basicShowAddRow ||
      conditionalLastEditingRow ||
      conditionalShowAddRow ||
      otherLastEditingRow ||
      otherShowAddRow
    )
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );

    const webDefinition = getCardDefinitionForm() as WebCardDefinition;
    const mobileDefinition = {
      ...webDefinition,
      ...((uiConfigType === 'mobileUIConfig' ||
        configDetails[KEY]?.type === configType.ALL.type) && { secondaryButtonOptions }),
    } as MobileCardDefinition;
    const { error } = await UICustomizationAPI.updateCardDefinition(
      uiConfigType,
      aggrUIName,
      webDefinition,
      mobileDefinition
    );
    if (error) return;
    setIsDirty(false);
    return successMsg('Updated successfully');
  };

  const result = Boolean(
    !isEqual(cardDefinition, getCardDefinitionForm()) ||
      basicLastEditingRow ||
      basicShowAddRow ||
      conditionalLastEditingRow ||
      conditionalShowAddRow ||
      otherLastEditingRow ||
      otherShowAddRow ||
      isDirty
  );
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && result
  );

  useRouteBlocker({ blocker, onSave: handleSave });

  const handleSecondaryButtonOptionsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setSecondaryButtonOptions({
      ...secondaryButtonOptions,
      groupButtons: !secondaryButtonOptions.groupButtons,
    });
  };
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleChanges = (item: string) => {
    setPageName(item);
  };
  const renderTable = () => {
    return (
      <>
        {getCustomPageList(config, aggrUIName).map((item) => (
          <TableRow key={item} style={{ ...styles.commonText, ...styles.bodyTxt }}>
            <TableCell scope="row">
              <Radio
                checked={pageName === item}
                onChange={() => handleChanges(item)}
                value={pageName}
                name="radio-button-demo"
                color="primary"
                inputProps={{ 'aria-label': 'A' }}
              />
            </TableCell>
            <TableCell align="left" style={{ ...styles.commonText, ...styles.tableTxt }}>
              {item}
            </TableCell>
            <TableCell
              align="left"
              style={{ ...styles.commonText, ...styles.tableTxt }}
            >{`page/${item}/{id}`}</TableCell>
            <TableCell align="left" style={{ ...styles.commonText, ...styles.tableTxt }}>
              {customPageData && customPageData[item]?.rows}
            </TableCell>
            <TableCell align="left" style={{ ...styles.commonText, ...styles.tableTxt }}>
              {customPageData && customPageData[item]?.columns}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };
  const filteredAggregateProps = _.filter(
    aggregateProps,
    (obj) => obj.name !== undefined && obj.type !== ''
  );
  return (
    <Box className={commonClasses.innerContainer}>
      <PageHeader
        title="Card View"
        configKey={KEY}
        icon={faFloppyDisk}
        onSave={handleSave}
        buttonText="Save"
      />
      <Box className={classes.root}>
        <AccordionCard header="Options">
          <Grid container style={styles.AccordionDetail}>
            <Grid item xs={12} md={4} className={classes.titleContainer}>
              <Typography style={styles.tableTxt}>Title Key</Typography>
              <TGSelect
                id="titleKey"
                selectedValue={titleKey}
                value={
                  _.find(filteredAggregateProps, (element) => element.name == titleKey)?.label || ''
                }
                onChange={(value: string) => {
                  setTitleKey(value);
                }}
                options={_.map(filteredAggregateProps, (element) => {
                  return element;
                })}
                containerStyle={classes.selectContainer}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={styles.checkboxContainer}>
                <Checkbox
                  checked={showTitleLabel}
                  color="primary"
                  onChange={() => setShowTitleLabel(!showTitleLabel)}
                />
                <Typography
                  className={[classes.addContainer, classes.bodyText].join(' ')}
                  style={styles.commonText}
                >
                  Show a label for the card's title
                </Typography>
              </div>
            </Grid>
            {(configTypeState === 'mobile' || configDetails[KEY]?.type === configType.ALL.type) && (
              <Grid item xs={12} md={4} style={styles.optionsContainerRoot}>
                <div style={styles.optionContainer}>
                  <div style={styles.checkboxContainer}>
                    <Checkbox
                      checked={secondaryButtonOptions.groupButtons}
                      color="primary"
                      onChange={handleSecondaryButtonOptionsToggle}
                    />
                    <Typography
                      className={[classes.addContainer, classes.bodyText].join(' ')}
                      style={styles.commonText}
                    >
                      Group secondary action buttons (Mobile)
                    </Typography>
                  </div>
                  {secondaryButtonOptions.groupButtons && (
                    <TextInputTG
                      placeholder="Grouped secondary action button label"
                      margin="dense"
                      className={classes.textfield}
                      value={secondaryButtonOptions.groupButtonLabel || ''}
                      onChange={(value) => {
                        setIsDirty(true);
                        setSecondaryButtonOptions({
                          ...secondaryButtonOptions,
                          groupButtonLabel: value,
                        });
                      }}
                    />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </AccordionCard>
      </Box>
      <Box className={classes.root}>
        <AccordionCard header="Layout">
          <RadioInput
            value={attributesType}
            onChange={(value) => setAttributesType(value)}
            options={[
              { value: 'otherAttributes', label: 'Other Attributes' },
              { value: 'customPage', label: 'Custom Page' },
            ]}
            enableColor
            radioFormStyle={classes.radioFormStyle}
          />
        </AccordionCard>
      </Box>
      <AppBar position="static" color="default" style={styles.tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Card Description" style={styles.tabText} />
          <Tab label="Buttons" style={styles.tabText} />
        </Tabs>
      </AppBar>
      <>
        <TabPanel value={value} index={0}>
          {attributesType === 'otherAttributes' ? (
            <CardDefinitionOtherAttributes
              otherAttributesTableRef={otherAttributesTableRef}
              otherAttributes={otherAttributes}
              setOtherAttributes={(otherAttributes) => {
                setOtherAttributes(otherAttributes);
                setHasUserModifiedOtherAttributes(true);
              }}
              style={classes.titleBarContainer}
              showLinkUnlink
            />
          ) : (
            <Box style={styles.tableOuter}>
              <PageHeader
                title="Custom Pages"
                enableSaveButton
                style={classes.titleBarContainer}
                showLinkUnlink
                titleStyle={classes.titleStyle}
              />
              <TableContainer style={styles.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={styles.tableHeader}>
                    <TableRow>
                      <TableCell style={{ ...styles.commonText, ...styles.headerCell }}>
                        Select
                      </TableCell>
                      <TableCell style={{ ...styles.commonText, ...styles.headerCell }}>
                        Name
                      </TableCell>
                      <TableCell style={{ ...styles.commonText, ...styles.headerCell }}>
                        Page URL
                      </TableCell>
                      <TableCell style={{ ...styles.commonText, ...styles.headerCell }}>
                        Rows
                      </TableCell>
                      <TableCell style={{ ...styles.commonText, ...styles.headerCell }}>
                        Columns
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTable()}</TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CardDefinitionConditionalCard
            type={configTypeState || 'mobile'}
            buttons={buttons}
            setButtons={setButtons}
            conditionalCardTableRef={conditionalCardTableRef}
            titleBarContainer={classes.titleBarContainer}
          />
        </TabPanel>
      </>
    </Box>
  );
};
const styles = {
  AccordionDetail: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: -3,
  },
  checkboxContainer: { display: 'flex' },
  optionsContainerRoot: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
  },
  optionsContainer: {
    position: 'relative',
    top: 19,
    flexShrink: 0,
  },
  tabContainer: {
    marginTop: 20,
    borderBottom: `1px solid ${colors.black10}`,
    boxShadow: 'none',
  },
  tabText: {
    textTransform: 'capitalize' as const,
    fontSize: 15,
    fontWeight: 400,
  },
  commonText: {
    color: colors.black,
    fontStyle: 'normal',
    lineHeight: '100%',
  },
  headerTxt: {
    fontWeight: 400,
    fontSize: 14,
  },
  bodyTxt: {
    fontWeight: 500,
    fontSize: 15,
  },
  headerCell: {
    fontWeight: 500,
    fontSize: 15,
    padding: '1px 16px',
  },
  tableOuter: {
    marginTop: 35,
    paddingBottom: 28,
  },
  tableContainer: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    marginTop: 28,
  },
  tableHeader: {
    backgroundColor: colors.greyBackground,
    height: 45,
  },
  tableTxt: {
    fontSize: 15,
    fontWeight: 400,
  },
  card: {
    display: 'flex' as const,
    alignItems: 'flex-start',
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
  },
  optionContainer: {
    width: '100%',
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: 10,
    },
    titleBarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleStyle: {
      fontSize: 21,
    },
    addContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    textfield: {
      marginLeft: 43,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      width: '75%',
      '& .MuiInputBase-input': {
        fontSize: 14,
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
        paddingInline: 14,
        height: 30,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.5px solid ${categoryColors.defaultColor}`,
        borderRadius: 5,
      },
    },
    bodyText: {
      '&.MuiTypography-body1': {
        fontSize: 15,
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      marginTop: 7,
    },
    table: {
      minWidth: 650,
    },
    selectContainer: {
      '&.MuiFormControl-root': {
        width: 139,
      },
      '& .MuiSelect-root': {
        display: 'block !important',
        height: 23,
        fontSize: 15,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&.MuiSelect-select.MuiSelect-select': {
          paddingTop: 4,
          paddingBottom: 1,
          paddingRight: 24,
        },
      },
      margin: 0,
    },
    radioFormStyle: {
      height: 24,
    },
  })
);
