import { Box, Button, Typography, styled } from '@material-ui/core';
import { AggregateLoaderType, V2AggregateLoader } from '@terragotech/form-renderer';
import { V2MapComponent } from '@terragotech/page-renderer';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { SymbolProps } from 'components/UIGeneralEditor';
import { useConfig } from 'context/ConfigContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { CheckboxInput } from '../FormElements';
import { V2OptionsInput } from '../FormElements/V2OptionsInput';
import { PageContext } from './contexts/PageContext';
import { usePageSchemas } from './hooks/usePageSchemas';
import usePageRedirect from './hooks/usePageRedirect';
import { TextInputTG as TextInput } from 'views/components/formElements';
import { nameHelperTxt, useCustomPageStyles } from './commonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/pro-light-svg-icons';
import { isEqual } from 'lodash';
import _ from 'lodash';
import SymbolLegendView from 'views/pages/settings/components/SymbolLegendView';
import { useSymbolValue } from 'views/pages/settings/SymbolContext';
import ModalFrame from './ModalFrame';
import MapperItem from 'components/FormDialog/MapperItem';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION, getPresentValue } from 'utils/Utils';

export type MapComponentDefWithName = V2MapComponent & {
  name: string;
  symbolScaleFactor?: number | undefined;
};

interface MapEditFormFormProps {
  onSubmit: (result: MapComponentDefWithName) => void;
  onClose: () => void;
  component: MapComponentDefWithName;
}

const TextInputTG = styled(TextInput)({
  '&.MuiFormControl-root': {
    marginBottom: 29,
  },
  '& .MuiInputBase-input': {
    fontSize: 15,
    fontWeight: 400,
  },
});

export const MapEditForm: React.FC<MapEditFormFormProps> = ({ onSubmit, onClose, component }) => {
  const { config } = useConfig();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [aggregates, setAggregates] = useState(component.aggregates);
  const [height, setHeight] = useState(component.height);
  const [includeWMS, setIncludeWMS] = useState(component.includeWMS);
  const [showLocationSearch, setShowLocationSearch] = useState(component.showLocationSearch);
  const [latSplit, setLatSplit] = useState(component.latSplit);
  const [lonSplit, setLonSplit] = useState(component.lonSplit);
  const [limitPerSection, setLimitPerSection] = useState(component.limitPerSection);
  const [matchWeb, setMatchWeb] = useState(!component.symbols);
  const [symbolLegend, setSymbolLegend] = useState<SymbolProps[]>(
    component.symbols || config?.webUIConfig?.symbolLegend || []
  );
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const { setSelectedSymbol } = useSymbolValue();
  const [symbolScale, setSymbolScale] = useState<number>(
    component?.symbolScaleFactor ? Number((component?.symbolScaleFactor * 100).toFixed(2)) : 100
  );

  const { openConfirmation } = useConfirmDialog();

  const isSymbolInvalid = useCallback((row: object) => {
    if (!(row as SymbolProps).name) {
      errorMsg('Property "Name" is required');
      return true;
    }
    if (!(row as SymbolProps).symbolKey) {
      errorMsg('Property "Symbol Key" is required');
      return true;
    }
    return false;
  }, []);

  const handleAddSymbol = useCallback(
    (symbol: object) => {
      if (isSymbolInvalid(symbol)) return;
      setSymbolLegend([...symbolLegend, symbol as SymbolProps]);
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );

  const handleDeleteSymbol = useCallback(
    (symbol: object) => {
      setSymbolLegend(symbolLegend.filter((item) => item.name !== (symbol as SymbolProps).name));
      setSelectedSymbol(null);
    },
    [symbolLegend, setSymbolLegend, setSelectedSymbol]
  );

  const handleUpdateSymbol = useCallback(
    (symbol: object, oldSymbol: object) => {
      if (oldSymbol) {
        if (isSymbolInvalid(symbol)) return;
        const symbolLegendCopy = [...symbolLegend];
        const index = symbolLegendCopy.findIndex(
          (item) => item.name === (oldSymbol as SymbolProps).name
        );
        symbolLegendCopy[index] = symbol as SymbolProps;
        setSymbolLegend(symbolLegendCopy);
      }
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );

  const [existingNameError, setExistingNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [emptyLabelError, setEmptyLabelError] = useState(false);
  const { pageDefinition } = useContext(PageContext);

  useEffect(() => {
    setExistingNameError(checkDuplicatePageName(name, component, pageDefinition.elements));
    setEmptyNameError(checkEmptyText(name));
  }, [name, component, pageDefinition.elements]);

  useEffect(() => {
    setEmptyLabelError(checkEmptyText(label));
  }, [label]);

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };

  const getValue = (value: unknown, key: string) => getPresentValue(component, value, key);

  const getFormValues = () => ({
    type: component.type,
    name: name || '',
    label: label || '',
    ...getValue(aggregates, 'aggregates'),
    ...getValue(height, 'height'),
    ...getValue(includeWMS, 'includeWMS'),
    ...getValue(showLocationSearch, 'showLocationSearch'),
    ...getValue(latSplit, 'latSplit'),
    ...getValue(lonSplit, 'lonSplit'),
    ...getValue(limitPerSection, 'limitPerSection'),
    ...getValue(matchWeb ? undefined : symbolScale / 100, 'symbolScaleFactor'),
    ...getValue(
      matchWeb
        ? undefined
        : symbolLegend.map((symbol) => ({
            name: symbol.name,
            symbolKey: symbol.symbolKey,
          })),

      'symbols'
    ),
    ...(displayOptions && { displayOptions }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (existingNameError || emptyNameError || emptyLabelError) {
      return;
    } else onSubmit(getFormValues() as MapComponentDefWithName);
  };

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <TextInputTG
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError || emptyNameError}
        helperText={
          emptyNameError ? 'Name cannot be empty' : existingNameError ? nameHelperTxt : ''
        }
        value={name}
        onChange={(value) => setName(value || '')}
        style={styles.inputStyles}
        fullWidth
        labelRoot={classes.Root}
      />
      <TextInputTG
        id="Label"
        label="Label"
        error={emptyLabelError}
        helperText={emptyLabelError ? 'Label cannot be empty' : ''}
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={styles.inputStyles}
        fullWidth
        labelRoot={classes.Root}
      />
      <TextInputTG
        id="Height"
        label="Height"
        value={height}
        onChange={(value) => setHeight(value || '')}
        style={styles.inputStyles}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      <TextInputTG
        id="Lat Split"
        label="Lat Split"
        value={latSplit}
        onChange={(value) => {
          setLatSplit(value.length ? _.toNumber(value) : undefined);
        }}
        style={styles.inputStyles}
        fullWidth={false}
        labelRoot={classes.Root}
        type="number"
        placeholder="5"
      />
      <TextInputTG
        id="Lon Split"
        label="Lon Split"
        value={lonSplit}
        onChange={(value) => {
          setLonSplit(value.length ? _.toNumber(value) : undefined);
        }}
        style={styles.inputStyles}
        fullWidth={false}
        labelRoot={classes.Root}
        type="number"
        placeholder="5"
      />
      <TextInputTG
        id="Limit Per Section"
        label="Limit Per Section"
        value={limitPerSection}
        onChange={(value) => {
          setLimitPerSection(value.length ? _.toNumber(value) : undefined);
        }}
        style={styles.inputStyles}
        fullWidth={false}
        labelRoot={classes.Root}
        type="number"
        placeholder="100"
      />
      <Box className={classes.itemContainer}>
        <Typography className={classes.text} style={styles.additionalTop}>
          Additional Options
        </Typography>
        <CheckboxInput
          title="Include Layers"
          checked={includeWMS}
          onChange={setIncludeWMS}
          checkBoxContainer={classes.checkbox}
        />
        <CheckboxInput
          title="Show Location Search"
          checked={showLocationSearch}
          onChange={(value) => setShowLocationSearch(value)}
          checkBoxContainer={classes.checkbox}
        />
        <CheckboxInput
          title="Match Web Symbols"
          checked={matchWeb}
          onChange={(value) => setMatchWeb(value)}
          checkBoxContainer={classes.checkbox}
          style={styles.mt2}
        />
      </Box>
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: displayOptions !== undefined,
          clearMapper: handleClearDisplayMapper,
          openDataMap: conditionalOpen,
          dataMap: displayOptions,
          setDataMap: setDisplayOptions,
          localSchemaDefinition: pageSchemas,
          title: 'Display Options',
          mapScenario: 'DISPLAY_OPTIONS',
          containerStyle: matchWeb ? classes.aggregatesBottom : '',
        }}
      />
      {!matchWeb && (
        <Box className={classes.itemContainer}>
          <Typography className={`${classes.text} ${classes.titleText}`}>Symbol Legend</Typography>
          <SymbolLegendView
            {...{
              symbolLegend,
              handleAddSymbol,
              handleUpdateSymbol,
              handleDeleteSymbol,
              setSymbolScale,
              symbolScale,
              setSymbolLegend,
            }}
          />
        </Box>
      )}
      <Box className={classes.aggregatesHeader}>
        <Typography className={`${classes.text} ${classes.titleText}`} style={styles.titleHeader}>
          Aggregates Options
        </Typography>
        <Button
          disableElevation
          variant="outlined"
          color="primary"
          className={classes.addButton}
          onClick={() => {
            setAggregates((prev) =>
              prev.concat({
                type: AggregateLoaderType,
                aggregateType: '',
              })
            );
          }}
          startIcon={<FontAwesomeIcon icon={faAdd} />}
        >
          Add
        </Button>
      </Box>

      {aggregates.map((agg, index) => (
        <V2OptionsInput
          options={agg}
          setOptions={(newAgg) => {
            setAggregates((prev) =>
              prev.map((old, i) => (i === index ? (newAgg as V2AggregateLoader) : old))
            );
          }}
          aggregateOnly
          isMapEdit
          onDelete={() => setAggregates((prev) => prev.filter((_val, i) => i !== index))}
        />
      ))}
    </ModalFrame>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  inputStyles: {
    width: '100%',
  },
  mt2: {
    marginTop: 2,
  },
};
