import { Modal, Grid, Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { TextInputTG } from '../../../../../components/formElements';

interface AddLanguageProps {
  isOpen: boolean;
  closeModal: () => void;
  newLanguage: string;
  onChangeNewLanguage: (value: string) => void;
  saveNewLanguage: () => void;
}

const AddLanguageModal: React.FC<AddLanguageProps> = ({
  isOpen,
  closeModal,
  newLanguage,
  onChangeNewLanguage,
  saveNewLanguage,
}) => {
  const classes = useStyles();

  const handleClose = () => closeModal();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.modalContainer}
      >
        <Grid item xs={8} sm={5} md={3}>
          <Box className={classes.modalContent}>
            <Typography variant="h6" component="h2" gutterBottom>
              Add New Language
            </Typography>
            <TextInputTG
              label=""
              variant="filled"
              value={newLanguage}
              onChange={(value) => onChangeNewLanguage(value)}
              className={classes.languageInput}
            />
            <Box className={classes.buttonContainer}>
              <Button onClick={handleClose} color="primary" className={classes.cancelButton}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={saveNewLanguage}>
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      minHeight: '100vh',
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      width: '100%',
    },
    languageInput: {
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      marginRight: theme.spacing(2),
    },
  })
);

export default AddLanguageModal;
