import React, { useMemo, useState, useImperativeHandle } from 'react';
import { V2PageTemplate } from '@terragotech/page-renderer';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { cloneDeep } from 'lodash';
import { PageContextProvider } from '../../../../../components/PageDialog/contexts/PageContext';
import DataMapperDialog from '../../../../../components/FormDialog/DataMapperDialog';
import { useConfig } from '../../../../../context/ConfigContext';
import { metadataSchema } from '../../../../../utils/jsonPartsGenerators';
import { getAggregateIndex } from '../../../../../utils/navigationUtils';
import { PageLayoutEditor } from '../CustomPageEditor/PageLayoutEditor';
import { propertiesToSchema } from 'pages/aggregates/utils/PropertiesToSchemaConverter';
import { convertV2PageTemplateToJsonSchema } from 'pages/aggregates/utils/V2PageTemplateToJsonSchema';

interface PageEditorProps {
  pageDefinition: V2PageTemplate;
  setPageDefinition: React.Dispatch<React.SetStateAction<V2PageTemplate>>;
  isGroup: boolean;
  fullPageDefinition: V2PageTemplate;
  reset: number;
  isEditor?: boolean;
  ref?: React.Ref<pageEditorRef | undefined>;
}

export interface pageEditorRef {
  handleDataMap: () => void;
  handleClearInitialDataMapper: () => void;
}

export const PageEditor: React.ForwardRefExoticComponent<React.PropsWithoutRef<PageEditorProps> & React.RefAttributes<pageEditorRef | undefined>> = React.forwardRef((
  { pageDefinition, setPageDefinition, isGroup, fullPageDefinition, reset, isEditor },
  ref
) => {
  const { config } = useConfig();

  const aggrIndex = useMemo(
    () => getAggregateIndex(config, pageDefinition.aggregateType),
    [config, pageDefinition.aggregateType]
  );

  const [initialDataMapOpen, setInitialDataMapOpen] = useState(false);
  const stateSchema = useMemo(
    () => (aggrIndex > 0 ? propertiesToSchema(config?.aggregates?.[aggrIndex].properties) : {}),
    [config?.aggregates, aggrIndex]
  );
  const pageSchema = convertV2PageTemplateToJsonSchema(pageDefinition);

  const localSchemaDefinitions = {
    STATE: { schema: stateSchema, schemaLabel: 'Properties' },
    FORM: { schema: pageSchema, schemaLabel: 'Page State' },
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
  };

  const handleClearInitialDataMapper = async () => {
    handleSetInitialDataMap(undefined);
  };

  const handleSetInitialDataMap = (data: NodeMapDefinition | undefined) => {
    const pageDefinitionClone = cloneDeep(pageDefinition);
    pageDefinitionClone.initialDataMap = data;
    setPageDefinition(pageDefinitionClone);
  };

  const handleDataMap = () => {
    setInitialDataMapOpen(true);
  };

  useImperativeHandle(ref, () => ({
    handleDataMap,
    handleClearInitialDataMapper,
  }));

  return (
    <>
      <PageContextProvider pageDefinition={fullPageDefinition}>
        {!isGroup ? (
          <DataMapperDialog
            localSchemaDefinitions={localSchemaDefinitions}
            onClose={() => {
              setInitialDataMapOpen(false);
            }}
            open={initialDataMapOpen}
            map={pageDefinition.initialDataMap}
            setMap={(data) => handleSetInitialDataMap(data)}
            mapScenario={'INITIAL_DATA_MAPPING'}
          />
        ) : (
          <></>
        )}
        <PageLayoutEditor
          pageDefinition={pageDefinition}
          fullPageDefinition={fullPageDefinition}
          setPageDefinition={setPageDefinition}
          isGroup={isGroup}
          reset={reset}
          isEditor={isEditor}
        />
      </PageContextProvider>
    </>
  );
});

export default PageEditor;
