import React from 'react';
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { CSVResult } from '../EditableTable';

interface CSVUploadResultsDialogProps {
  CSVResultOpen: boolean;
  setCSVResultOpen: (CSVResultOpen: boolean) => void;
  CSVResultData?: CSVResult;
}

export const CSVUploadResultsDialog: React.FC<CSVUploadResultsDialogProps> = ({
  CSVResultOpen,
  setCSVResultOpen,
  CSVResultData,
}) => {
  const onCSVResultClose = () => {
    setCSVResultOpen(false);
  };

  return (
    <Dialog
      onClose={onCSVResultClose}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      open={CSVResultOpen}
    >
      <DialogTitle id="alert-dialog-title">Imported CSV Options</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          {`Valid Options Count: ${CSVResultData?.newRecordCount}`}
        </Typography>
        <Typography variant="subtitle1">
          {`Skipped Options Count: ${CSVResultData?.badRecordCount}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCSVResultClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CSVUploadResultsDialog;
