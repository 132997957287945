"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logPerformance = exports.logPerformanceLog = exports.logPerformanceEnd = exports.logPerformanceBegin = void 0;
const performanceLoggingEnabled = process.env.PERFORMANCE_LOGGING_ENABLED || false;
const performanceStart = {};
const logPerformanceBegin = (performanceLogEntry) => {
    switch (performanceLogEntry.method) {
        case 'performance':
            const start = performanceStart[performanceLogEntry.name];
            if (start) {
                console.warn(`Timer '${performanceLogEntry.name}' already exists.`);
            }
            else {
                performanceStart[performanceLogEntry.name] = performance.now();
            }
            break;
        default:
            console.time(performanceLogEntry.name);
            break;
    }
    console.info(`${performanceLogEntry.name} - timer started`);
};
exports.logPerformanceBegin = logPerformanceBegin;
const logPerformanceEnd = (performanceLogEntry) => {
    switch (performanceLogEntry.method) {
        case 'performance':
            const start = performanceStart[performanceLogEntry.name];
            if (!start) {
                console.warn(`Timer '${performanceLogEntry.name}' does not exist`);
            }
            else {
                console.log(`${performanceLogEntry.name}: ${performance.now() - start}ms - timer ended`);
                performanceStart[performanceLogEntry.name] = null;
            }
            break;
        default:
            console.timeEnd(performanceLogEntry.name);
            break;
    }
};
exports.logPerformanceEnd = logPerformanceEnd;
const logPerformanceLog = (performanceLogEntry) => {
    switch (performanceLogEntry.method) {
        case 'performance':
            const start = performanceStart[performanceLogEntry.name];
            if (!start) {
                console.warn(`Timer '${performanceLogEntry.name}' does not exist`);
            }
            else {
                console.log(`${performanceLogEntry.name}: ${performance.now() - start}ms${' - ' + performanceLogEntry.data}`);
            }
            break;
        default:
            console.timeLog(performanceLogEntry.name, performanceLogEntry.data);
            break;
    }
};
exports.logPerformanceLog = logPerformanceLog;
const logPerformance = (performanceLogEntry) => {
    if (!performanceLoggingEnabled) {
        return;
    }
    if (performanceLogEntry.begin) {
        (0, exports.logPerformanceBegin)(performanceLogEntry);
        return;
    }
    if (performanceLogEntry.end) {
        (0, exports.logPerformanceEnd)(performanceLogEntry);
        return;
    }
    (0, exports.logPerformanceLog)(performanceLogEntry);
};
exports.logPerformance = logPerformance;
