import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult, DragStart } from 'react-beautiful-dnd';
import { V2PageTemplate } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { createPageItem, generatePageItemName } from './defaultPageItems';
import { PagePalette } from './PagePalette';
import { PageLayoutList } from './PageLayoutList';
import { cloneDeep, pick } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import { Box, Modal, Theme, createStyles, makeStyles } from '@material-ui/core';
interface PageLayoutEditorProps {
  pageDefinition: V2PageTemplate;
  fullPageDefinition: V2PageTemplate;
  setPageDefinition: React.Dispatch<React.SetStateAction<V2PageTemplate>>;
  isGroup: boolean;
  reset: number;
  isEditor?: boolean;
}

const elementLocationProps: Array<keyof V2PageTemplate['elements'][string]> = [
  'row',
  'column',
  'columnSpan',
];

const getGridAreaId = (row: number, column: number) => `${row}-${column}`;
const getGridAreaRowColumn = (droppableId: string) => {
  const [row, column] = [...(droppableId?.split('-').map((x) => +x) ?? [undefined])] as const;
  return { row, column };
};

const emptySelectedItems: V2PageTemplate = {
  rows: 1,
  columns: 1,
  allowDynamicSizing: true,
  elements: {},
};

const defaultGridSpan = {
  rowSpan: 1,
  columnSpan: 1,
} as const;

export const PageLayoutEditor: React.FC<PageLayoutEditorProps> = ({
  pageDefinition,
  setPageDefinition,
  fullPageDefinition,
  isGroup,
  reset,
  isEditor,
}) => {
  const [selectedItems, setSelectedItems] = useState<V2PageTemplate>(emptySelectedItems);
  const [lastPastedPageTemplate] = useState<V2PageTemplate | null>(null);
  const [refresh, setRefresh] = useState(true);
  const [focusedItem, setFocusedItem] = useState('');
  const [dragSourceDroppableId, setDragSourceDroppableId] = useState<string | null>(null);
  const [paletteModal, setPaletteModal] = useState(false);
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState('');
  const defaultGridSpans = useCallback(
    () =>
      Object.entries(pageDefinition.elements).reduce((acc, [_, e]) => {
        const droppableId = getGridAreaId(e.row, e.column);
        const span = acc[droppableId] ?? defaultGridSpan;
        acc[droppableId] = {
          rowSpan: Math.max(span.rowSpan, /*e.rowSpan*/ 1),
          columnSpan: Math.max(span.columnSpan, e.columnSpan),
        };
        return acc;
      }, {} as Record<string, { rowSpan: number; columnSpan: number }>),
    [pageDefinition]
  );
  const [gridSpans, setGridSpans] = useState(defaultGridSpans());

  useEffect(() => {
    if (!refresh) {
      setRefresh(true);
    }
  }, [refresh]);

  useEffect(() => {
    if (reset > 0) {
      setGridSpans(defaultGridSpans());
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const addPageItem = ({
    destination,
    draggableId,
  }: {
    destination: string;
    draggableId: string;
  }) => {
    if (!destination) return;

    const destinationLocation = getLocationInfo(destination);

    const destinationHasElements = Object.entries(pageDefinition.elements).some(
      ([_, e]) => e.row === destinationLocation.row && e.column === destinationLocation.column
    );
    if (destinationHasElements) return;

    const addNewItemToPage = () => {
      const name = generatePageItemName(draggableId, Object.keys(page.elements));
      page.elements[name] = {
        ...page.elements[name],
        ...pick(destinationLocation, elementLocationProps),
        component: createPageItem(draggableId, name),
      };
    };

    const page = cloneDeep(pageDefinition);
    addNewItemToPage();
    return setPageDefinition(page);
  };

  const moveItemFromPage = ({ draggableId, source, destination }: DropResult) => {
    if (!destination || destination.droppableId === source.droppableId) return;

    const reorderItemInPage = () => {
      const destinationLocation = getLocationInfo(destination.droppableId);
      const destinationElements = Object.entries(pageDefinition.elements)
        .filter(
          ([_, e]) => e.row === destinationLocation.row && e.column === destinationLocation.column
        )
        .map(([key, _]) => key);

      // Move destination element(s) to source
      if (destinationElements.length) {
        destinationElements.forEach((key) => {
          page.elements[key] = {
            ...page.elements[key],
            ...pick(getLocationInfo(source.droppableId), elementLocationProps),
          };
        });
      }

      // Move dragged element to destination
      page.elements[draggableId] = {
        ...page.elements[draggableId],
        ...pick(destinationLocation, elementLocationProps),
      };
    };

    let page = cloneDeep(pageDefinition);
    if (destination.droppableId !== 'palette') {
      reorderItemInPage();
      return setPageDefinition(page);
    }
  };

  const handleDragStart = (initial: DragStart) => {
    setDragSourceDroppableId(initial.source.droppableId);
  };

  const getLocationInfo = (droppableId: string) => {
    const { row, column } = getGridAreaRowColumn(droppableId);
    const { rowSpan, columnSpan } = gridSpans[droppableId] ?? defaultGridSpan;

    const pageElementEntries = Object.entries(pageDefinition.elements);

    const hasElements = pageElementEntries.some(([_, e]) => e.row === row && e.column === column);

    const { hideGridArea, extendRowSpanConflict, extendColumnSpanConflict } = [
      ...Array(pageDefinition.rows).keys(),
    ].reduce(
      (rAcc, gaRow) =>
        [...Array(pageDefinition.columns).keys()].reduce((cAcc, gaColumn) => {
          const gs = gridSpans[getGridAreaId(gaRow, gaColumn)] || defaultGridSpan;
          const hideGridArea =
            cAcc.hideGridArea ||
            ((gaRow !== row || gaColumn !== column) &&
              (gaRow === row || (gaRow < row && gaRow + gs.rowSpan > row)) &&
              (gaColumn === column || (gaColumn < column && gaColumn + gs.columnSpan > column)));
          const extendRowSpanConflict =
            cAcc.extendRowSpanConflict ||
            (gaRow === row + rowSpan &&
              gaColumn >= column &&
              gaColumn < column + columnSpan &&
              (gs.columnSpan > 1 ||
                gs.rowSpan > 1 ||
                pageElementEntries.some(([_, e]) => e.row === gaRow && e.column === gaColumn)));
          const extendColumnSpanConflict =
            cAcc.extendColumnSpanConflict ||
            (gaColumn === column + columnSpan &&
              gaRow >= row &&
              gaRow < row + rowSpan &&
              (gs.columnSpan > 1 ||
                gs.rowSpan > 1 ||
                pageElementEntries.some(([_, e]) => e.row === gaRow && e.column === gaColumn)));
          return { hideGridArea, extendRowSpanConflict, extendColumnSpanConflict };
        }, rAcc),
      { hideGridArea: false, extendRowSpanConflict: false, extendColumnSpanConflict: false }
    );

    return {
      row,
      column,
      rowSpan,
      columnSpan,
      canShrinkRowSpan: rowSpan > 1,
      canExtendRowSpan: !extendRowSpanConflict && row + rowSpan < pageDefinition.rows,
      canShrinkColumnSpan: columnSpan > 1,
      canExtendColumnSpan:
        !extendColumnSpanConflict && column + columnSpan < pageDefinition.columns,
      hasElements,
      hideGridArea,
    };
  };

  const handleDragEnd = (result: DropResult) => {
    const { reason, source, destination } = result;

    if (reason !== 'DROP' || !destination || destination.droppableId === source.droppableId) return;
    moveItemFromPage(result);
    setDragSourceDroppableId(null);
  };
  const handlePaletteClick = (item: string) => {
    if (selectedId) {
      addPageItem({ destination: selectedId, draggableId: item });
      setSelectedId('');
      handlePaletteClose();
    }
    return;
  };

  const setGridSpan = (droppableId: string, newSpan: Partial<typeof gridSpans[string]>) => {
    if (!newSpan.columnSpan && !newSpan.rowSpan) return;

    setGridSpans((spans) => {
      const spansCopy = cloneDeep(spans);
      spansCopy[droppableId] = {
        ...(spans[droppableId] ?? defaultGridSpan),
        ...newSpan,
      };
      return spansCopy;
    });
  };

  useEffect(() => {
    const { rows, columns } = pageDefinition;
    setGridSpans((val) => {
      const spans = cloneDeep(val);
      Object.entries(gridSpans).forEach(([key, { rowSpan, columnSpan }]) => {
        const { row, column } = getGridAreaRowColumn(key);
        if (spans[key]) {
          if (row + rowSpan > rows) spans[key].rowSpan = Math.max(1, rows - row);
          if (column + columnSpan > columns) spans[key].columnSpan = Math.max(1, columns - column);
        }
      });
      return spans;
    });
  }, [pageDefinition.rows, pageDefinition.columns, setGridSpans]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageDefinition((val) => {
      const page = cloneDeep(val);
      Object.entries(pageDefinition.elements).forEach(([key, e]) => {
        page.elements[key] = {
          ...page.elements[key],
          ...pick(gridSpans[getGridAreaId(e.row, e.column)], elementLocationProps),
        };
      });
      return page;
    });
  }, [gridSpans, setPageDefinition]); // eslint-disable-line react-hooks/exhaustive-deps

  const shrinkColumnSpan = (droppableId: string) => {
    const { columnSpan, canShrinkColumnSpan } = getLocationInfo(droppableId);
    if (!canShrinkColumnSpan) return;

    const newSpans = { columnSpan: columnSpan - 1 };
    setGridSpan(droppableId, newSpans);
  };

  const extendColumnSpan = (droppableId: string) => {
    const { columnSpan, canExtendColumnSpan } = getLocationInfo(droppableId);
    if (!canExtendColumnSpan) return;

    setGridSpan(droppableId, { columnSpan: columnSpan + 1 });
  };
  const handlePaletteClose = () => {
    setPaletteModal(false);
  };

  return refresh ? (
    <>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <FlexRowContainer>
          <PageContainer rows={pageDefinition.rows} columns={pageDefinition.columns}>
            {pageDefinition.rows &&
              [...Array(pageDefinition.rows).keys()].map((r) => (
                <React.Fragment key={r}>
                  {pageDefinition.columns &&
                    [...Array(pageDefinition.columns).keys()].map((c) => {
                      const droppableId = getGridAreaId(r, c);
                      const {
                        rowSpan,
                        columnSpan,
                        hasElements,
                        hideGridArea,
                        canShrinkColumnSpan,
                        canExtendColumnSpan,
                      } = getLocationInfo(droppableId);
                      return (
                        <Droppable
                          key={c}
                          droppableId={droppableId}
                          isDropDisabled={hasElements && dragSourceDroppableId === 'palette'}
                        >
                          {(droppableProvided, snapshot) => (
                            <PageInner
                              {...droppableProvided.droppableProps}
                              ref={droppableProvided.innerRef}
                              row={r}
                              column={c}
                              rowSpan={rowSpan}
                              columnSpan={columnSpan}
                              hidden={hideGridArea}
                            >
                              <PageLayoutList
                                pageDefinition={pageDefinition}
                                fullPageDefinition={fullPageDefinition}
                                setPageDefinition={setPageDefinition}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                lastPastedPageTemplate={lastPastedPageTemplate}
                                setRefresh={setRefresh}
                                groupDragging={false}
                                focusedItem={focusedItem}
                                setFocusedItem={setFocusedItem}
                                row={r}
                                column={c}
                              />
                              {!hasElements && (
                                <AddButtonContainer
                                  hasBottomContainer={
                                    !canShrinkColumnSpan && !canExtendColumnSpan ? true : false
                                  }
                                >
                                  <Box
                                    className={classes.addIconContainer}
                                    onClick={() => {
                                      setPaletteModal(true);
                                      setSelectedId(droppableId);
                                    }}
                                  >
                                    <Box className={classes.circle}>
                                      <FontAwesomeIcon icon={faPlus} className={classes.plus} />
                                    </Box>
                                  </Box>
                                </AddButtonContainer>
                              )}
                              {(canShrinkColumnSpan || canExtendColumnSpan) && (
                                <SpanButtonContainer>
                                  <SpanButton
                                    onClick={() => shrinkColumnSpan(droppableId)}
                                    hidden={!canShrinkColumnSpan}
                                  >
                                    &lt; Shrink
                                  </SpanButton>
                                  <span hidden={!canShrinkColumnSpan || !canExtendColumnSpan}>
                                    &nbsp;&nbsp;
                                  </span>
                                  <SpanButton
                                    onClick={() => extendColumnSpan(droppableId)}
                                    hidden={!canExtendColumnSpan}
                                  >
                                    Extend &gt;
                                  </SpanButton>
                                </SpanButtonContainer>
                              )}
                              {droppableProvided.placeholder}
                            </PageInner>
                          )}
                        </Droppable>
                      );
                    })}
                </React.Fragment>
              ))}
          </PageContainer>
        </FlexRowContainer>
      </DragDropContext>
      <Modal
        hideBackdrop
        open={paletteModal}
        onClose={() => {
          setPaletteModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.rootModal}
      >
        <Box className={classes.palette}>
          <PagePalette
            isGroup={isGroup}
            handlePaletteClose={handlePaletteClose}
            handleOptionClick={handlePaletteClick}
          />
        </Box>
      </Modal>
    </>
  ) : null;
};

const PageContainer = styled.div<{ isEditor?: boolean; rows: number; columns: number }>(
  ({ style, rows, columns, isEditor }) => ({
    flex: 1,
    display: 'grid',
    gridAutoRows: 'minmax(0, 1em)',
    padding: '22px 20px',
    gap: '8px',
    gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
    alignItems: 'normal',
    background: `${isEditor ? colors.gray99 : colors.lotion}`,
    ...style,
  })
);

const FlexRowContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
`;

const PageInner = styled.div<{
  row: number;
  rowSpan: number;
  column: number;
  columnSpan: number;
}>`
  width: 100%;
  box-sizing: border-box;
  grid-row: ${(props) => props.row + 1} / ${(props) => props.row + 1 + props.rowSpan};
  grid-column: ${(props) => props.column + 1} / ${(props) => props.column + 1 + props.columnSpan};
  border: 1px solid ${colors.black20};
  border-radius: 4px;
  height: auto;
  min-height: 125px;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-content: normal;
`;
const AddButtonContainer = styled.div<{ hasBottomContainer: boolean }>`
  flex-basis: 80%;
  padding-top: ${(props) => (props.hasBottomContainer ? '0px' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SpanButtonContainer = styled.div`
  width: 100%;
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black5};
`;
const SpanButton = styled.span`
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  color: ${colors.black};
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    palette: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colors.white,
      width: 350,
      minHeight: '150px',
      maxHeight: '100%',
      borderRadius: '10px',
      boxShadow: `0px 4px 10px 6px ${colors.black10}`,
    },
    rootModal: {
      backgroundColor: colors.black50,
      border: 'none',
    },
    addIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circle: {
      width: '28px',
      height: '28px',
      color: colors.black25,
      border: `1px dashed ${colors.black25}`,
      borderRadius: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    plus: {
      color: theme.palette.primary.main,
    },
    addIconContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
