"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryableLocationProps = exports.getQueryableStPoint = exports.convertDistance = exports.getDistanceConversionFactor = exports.isCoordinatesValid = void 0;
const lodash_1 = require("lodash");
const isCoordinatesValid = (latitude, longitude) => {
    if (latitude === null || latitude === undefined || longitude === null || longitude === undefined) {
        return false;
    }
    return isFinite(latitude) && Math.abs(latitude) <= 90 && isFinite(longitude) && Math.abs(longitude) <= 180;
};
exports.isCoordinatesValid = isCoordinatesValid;
//#region Distance
const SRID_LON_LAT = 4326; // GEOGRAPHIC_2D_CRS (https://spatialreference.org/ref/epsg/4326/)
// Adapted from: https://blog.probirsarkar.com/how-to-convert-length-units-in-javascript-with-ease-and-precision-895bc8b07393
const distanceUnits = {
    meters: 1,
    kilometers: 1e3,
    feet: 0.3048,
    yards: 0.9144,
    miles: 1609.344,
    degrees: 111111, // 1 degree lat = 111,111 m; 1 degree lon = cos(lat) * 111,111 m  // https://gis.stackexchange.com/a/2964
};
const getDistanceConversionFactor = (from, to) => {
    if (!distanceUnits[from] || !distanceUnits[to]) {
        throw 'Invalid units specified.';
    }
    return distanceUnits[from] / distanceUnits[to];
};
exports.getDistanceConversionFactor = getDistanceConversionFactor;
const convertDistance = (value, from, to) => value * (0, exports.getDistanceConversionFactor)(from, to);
exports.convertDistance = convertDistance;
const handleEWKTParam = (propOrVal, defaultVal) => !(0, lodash_1.isEmpty)(propOrVal) && isNaN(parseFloat(`${propOrVal}`))
    ? `' || COALESCE(${propOrVal}, ${defaultVal}) || '`
    : propOrVal !== null && propOrVal !== void 0 ? propOrVal : defaultVal;
const getQueryableStPoint = (lon, lat, srid) => 
// POINT: If location cannot be determined, set outside of reasonable bounds to avoid exception.
`ST_GeogFromText('SRID=${handleEWKTParam(srid, SRID_LON_LAT)};POINT(${handleEWKTParam(lon, 0)} ${handleEWKTParam(lat, -90)})')`;
exports.getQueryableStPoint = getQueryableStPoint;
const getQueryableLocationProps = (locationProp) => typeof locationProp === 'string'
    ? {
        lonProp: `ST_X("${locationProp}"::geometry)`,
        latProp: `ST_Y("${locationProp}"::geometry)`,
    }
    : typeof locationProp === 'object'
        ? {
            lonProp: `"${locationProp.longitude}"`,
            latProp: `"${locationProp.latitude}"`,
        }
        : {
            lonProp: 'lon',
            latProp: 'lat',
        };
exports.getQueryableLocationProps = getQueryableLocationProps;
//#endregion
