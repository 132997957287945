import { Box, makeStyles } from '@material-ui/core';
import { TitleToolTip } from 'pages/aggregates/components/TitleToolTip';
import { ReactNode } from 'react';
import { colors } from 'utils/colors';

interface Props {
  title: string;
  tooltip?: string;
  hasTooltip?: boolean;
  rightContent?: null | ReactNode;
  body: ReactNode;
  legendContainer?: string;
  headerContainer?: string;
  disabled?: boolean;
  titleStyle?: string;
}

const FormLegend = ({
  title,
  tooltip,
  rightContent = null,
  body = null,
  legendContainer = '',
  headerContainer = '',
  disabled = false,
  titleStyle,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.container} ${legendContainer}`}>
      <Box className={`${classes.headerContainer} ${headerContainer}`}>
        <TitleToolTip
          title={title}
          tooltipText={tooltip}
          titleStyle={`${classes.title} ${titleStyle || ''}`}
          disabled={disabled}
        />
        {rightContent}
      </Box>
      <Box>{body}</Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    margin: '10px 0',
    overflow: 'hidden',
  },
  headerContainer: {
    position: 'relative',
    backgroundColor: colors.black5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 17,
    height: 45,
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
    lineHeight: '100%',
  },
});

export default FormLegend;
