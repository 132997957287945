import { createStyles, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

export interface LoginField {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
}

interface LoginFieldsProps {
  errorMessage: string | undefined;
  fields: LoginField[];
}

const LoginFields: React.FC<LoginFieldsProps> = (props: LoginFieldsProps) => {
  const { fields, errorMessage } = props;
  const classes = useStyles();

  return (
    <>
      {fields.map((field: LoginField, index: number) => {
        return (
          <TextField
            className={classes.input}
            label={field.label}
            value={field.value}
            onChange={field.onChange}
            error={!!errorMessage}
            type={field.type || ''}
            key={index}
          />
        );
      })}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
  })
);

export default LoginFields;
