import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { MaterialTableRef } from 'components/EditableTable';
import { ValidOptions } from 'pages/aggregates/components/ValidOptionsCustomInput';
import { ActionButtonRow, AggrPropertyRow, FabAction, UIConfigType } from 'utils/types';
import { cloneDeep, get, map } from 'lodash';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { PropertyEditableTable } from 'components/PageDialog/PropertyEditableTable';
import { MobileActionButton } from '@terragotech/gen5-config-lib/dist/MobileUIConfig';
import { useUICustomizationAPI } from 'context/fakeAPIHooks/useUICustomizationAPI';
import {
  commandActionButtonActionToRef,
  convertCommandRefToString,
  getAggregateList,
  getFabActionColumns,
  getScheduleActionColumns,
} from 'utils/jsonPartsGenerators';
import { ConfigContext } from 'context/ConfigContext';
import { CELL_STYLE, UI_ACTIONS_LEFT_MENU } from 'utils/Utils';
import { useParams } from 'react-router-dom';
import {
  CommandReference,
  ScheduleActionRow,
  ScheduleActionRowWithID,
} from '@terragotech/gen5-config-lib';

const FAB_TABLE_HEIGHT = 226;

export interface OtherProps {
  tableData?: number;
  validOptions: ValidOptions | string;
}
export type FabActionRow = MobileActionButton & { aggregateName?: string };
type FabActionRowWithID = FabActionRow & { tableData: { id: number } };
type FabActionProp = {
  aggregateName: string | undefined;
  button: ActionButtonRow & {
    action: CommandReference;
  };
};

export default function ActionsContainer() {
  const UICustomizationAPI = useUICustomizationAPI();
  const { currentActionPage: KEY } = useParams() as {
    currentActionPage: string;
  };
  const tableRef = useRef<MaterialTableRef>(null);
  const { config, configDetails } = useContext(ConfigContext);
  const UIAction = UI_ACTIONS_LEFT_MENU.find((o) => o.keyValue === KEY);
  const [title, setTitle] = useState(UIAction?.title || '');
  const [type, setType] = useState(
    KEY === 'fabActions' ? 'mobile' : KEY === 'schedule' ? 'neither' : 'webImport'
  );
  const UIConfig =
    type === 'webImport' ? 'webUIConfig' : (configDetails[KEY]?.config as UIConfigType);
  const UIConfigPath =
    type === 'webImport'
      ? 'webUIConfig.importActions'
      : `${configDetails?.fabActions?.config}.${KEY}`;
  const validDays = ['Sa', 'M', 'Tu', 'W', 'Th', 'F', 'Su'];

  const updateCurrentAction = useCallback(() => {
    if (UIAction) {
      setTitle(UIAction.title);
      setType(KEY === 'fabActions' ? 'mobile' : KEY === 'schedule' ? 'neither' : 'webImport');
    }
  }, [UIAction, KEY]);

  useEffect(() => {
    updateCurrentAction();
  }, [KEY, updateCurrentAction]);

  const updateFunction =
    type === 'webImport'
      ? UICustomizationAPI.updateImportAction
      : UICustomizationAPI.updateFabAction;
  const addFunction =
    type === 'webImport' ? UICustomizationAPI.addImportAction : UICustomizationAPI.addFabAction;
  const deleteFunction =
    type === 'webImport'
      ? UICustomizationAPI.deleteImportAction
      : UICustomizationAPI.deleteFabAction;

  const fabActions = cloneDeep(get(config, UIConfigPath) || []);
  const timerActions = cloneDeep(get(config, 'schedules') || {});

  const getFabActions = () =>
    (fabActions as FabAction[]).map((item) => ({
      aggregateName: item.aggregateName,
      ...item.button,
      action: convertCommandRefToString(item.button?.action),
    }));

  const FabActions = getFabActions();

  const getTimerActions = () => {
    return Object.entries(timerActions).flatMap(([recordName, actions]) =>
      actions.map((action) => ({
        recordName,
        timer: action.timer,
        startTime: action.startTime,
        repeat: action.repeat,
        days: action.days,
        endTime: action.endTime || '-',
      }))
    );
  };

  const TimerActions = getTimerActions();

  const actions = useCallback(() => {
    if (type === 'neither') {
      return TimerActions;
    }
    return FabActions;
  }, [type, FabActions, TimerActions]);

  const convertRowToFabAction = ({ aggregateName, ...rest }: FabActionRow) => ({
    aggregateName,
    button: commandActionButtonActionToRef({ ...rest } as ActionButtonRow),
  });

  const convertRowToScheduleAction = ({ recordName, ...rest }: ScheduleActionRow) => {
    return { recordName, ...rest } as ScheduleActionRow;
  };

  const moveRowFabAction = async (row: FabActionProp[]) => {
    const { error } = await UICustomizationAPI.reOrderFabActionRow(
      UIConfig,
      UIAction?.keyValue as 'fabActions' | 'importActions',
      row
    );
    if (error) return;
    successMsg('Actions have been reordered.');
  };

  const reOrderData = (newData: unknown) => {
    if (type === 'neither') {
      console.log('No reordering implemented');
    } else {
      const newActions = map(newData as FabActionRow[], (data) => {
        return convertRowToFabAction(data);
      });
      moveRowFabAction(newActions);
    }
  };

  const isButtonInvalid = (row: object) => {
    if (!(row as FabActionRow).aggregateName) {
      errorMsg('Aggregate name must be defined');
      return true;
    }
    if (!(row as FabActionRow).action) {
      errorMsg('Action must be defined');
      return true;
    }
    return false;
  };

  const isScheduleActionRow = (row: object): row is ScheduleActionRow => {
    return 'recordName' in row && 'timer' in row;
  };

  const validateTime = (time: string, field: string): boolean => {
    const customRegex = /^(\d?\d):(\d\d)$/;
    const match = time.match(customRegex);
    if (!match) {
      errorMsg(`${field} not in valid format XX:XX AM/PM`);
      return false;
    }
    const hour = Number(match[1]);
    const minute = Number(match[2]);
    if (hour < 0 || hour > 23) {
      errorMsg(`${field} must have a valid hour value`);
      return false;
    }
    if (minute < 0 || minute > 59) {
      errorMsg(`${field} must have a valid minute value`);
      return false;
    }
    return true;
  };

  const validTimeBounds = (startTime: string, endTime: string): boolean => {
    const customRegex = /^(\d\d?):(\d\d)$/;
    const startMatch = startTime.match(customRegex);
    const endMatch = endTime.match(customRegex);

    if (startMatch && endMatch) {
      const [startHour, startMinute] = [Number(startMatch[1]), Number(startMatch[2])];
      const [endHour, endMinute] = [Number(endMatch[1]), Number(endMatch[2])];

      return startHour < endHour || (startHour === endHour && startMinute <= endMinute);
    }

    return false;
  };

  const isTimerInvalid = (row: object): boolean => {
    if (!isScheduleActionRow(row)) {
      errorMsg('Missing Record Name and/or Action');
      return true;
    }

    const { recordName, repeat, days, timer, startTime, endTime } = row as ScheduleActionRow;

    if (!recordName || !timer || !repeat || !startTime || !days) {
      errorMsg('Missing required fields');
      return true;
    }

    if (repeat !== 'Daily') {
      const customRegex = /^(\d+)H (\d+)M$/;
      const match = repeat.match(customRegex);
      if (!match) {
        errorMsg('Custom Repeat Value must be in format (\\d+)H (\\d+)M (integer values only)');
        return true;
      }
      const hours = parseInt(match[1]);
      const mins = parseInt(match[2]);
      if (hours < 0 || hours > 12 || mins < 0 || mins > 59) {
        errorMsg(
          'Custom Repeat Value must have hours between 0 and 12 and minutes between 0 and 59'
        );
        return true;
      } else if (hours === 0 && mins === 0) {
        errorMsg('Repeat Value can not repeat at 0 hours and 0 minutes');
        return true;
      }
    }

    if (!validateTime(startTime, 'Starting Time')) {
      return true;
    }

    if (endTime && endTime !== '-' && !validateTime(endTime, 'Ending Time')) {
      return true;
    }

    if (endTime && endTime !== '-' && !validTimeBounds(startTime, endTime)) {
      errorMsg('Invalid Time Bounds, Starting Time must be before Ending Time');
      return true;
    }

    const arrayDays = days.split(', ');
    if (arrayDays.some((day) => !validDays.includes(day))) {
      errorMsg('Invalid Day Value: ' + arrayDays.find((day) => !validDays.includes(day)));
      return true;
    }

    return false;
  };

  const addAction = async (
    row: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    const isScheduleAction = type === 'neither';
    const isValid = isScheduleAction ? !isTimerInvalid(row) : !isButtonInvalid(row);

    if (!isValid) {
      return reject();
    }

    const action = isScheduleAction ? row : convertRowToFabAction(row as FabActionRow);
    
    const { error } = isScheduleAction
      ? await UICustomizationAPI.addScheduleAction(
          action as ScheduleActionRow,
          (action as ScheduleActionRow).recordName
        )
      : await addFunction(UIConfig, action as FabAction);

    if (error) {
      return reject();
    }

    successMsg('Command action has been successfully added');
    resolve(null);
  };

  const updateAction = async (
    newRow: object,
    oldRow: object,
    resolve: (data: Object | null) => void,
    reject: () => void
  ) => {
    const isScheduleAction = type === 'neither';

    const isValid = isScheduleAction ? !isTimerInvalid(newRow) : !isButtonInvalid(newRow);

    if (!isValid) {
      return reject();
    }

    const action = isScheduleAction
      ? convertRowToScheduleAction(newRow as ScheduleActionRow)
      : convertRowToFabAction(newRow as FabActionRow);

    const index = (oldRow as FabActionRowWithID | ScheduleActionRowWithID).tableData.id;

    const { error } = isScheduleAction  
      ? await UICustomizationAPI.updateScheduleAction(
          action as ScheduleActionRow,
          (action as ScheduleActionRow).recordName
        )
      : await updateFunction(UIConfig, index, action as FabAction);

    if (error) {
      return reject();
    }

    successMsg('Editor action has been successfully updated');
    resolve(null);
  };

  const deleteAction = async (rowToDel: object) => {
    const index = (rowToDel as FabActionRowWithID | ScheduleActionRowWithID).tableData.id;
    const { error } =
      type === 'neither'
        ? await UICustomizationAPI.deleteScheduleAction(
            (rowToDel as ScheduleActionRow).recordName,
            (rowToDel as ScheduleActionRow).timer
          )
        : await deleteFunction(UIConfig, index);
    if (!error) {
      successMsg('Editor action has been successfully deleted');
    }
  };

  const getEditorColumns = (type: string) => {
    const aggregateList = getAggregateList(config);
    const isWebImport = type === 'webImport';
    const isScheduleAction = type === 'neither';

    const baseColumns = isScheduleAction
      ? getScheduleActionColumns(aggregateList)
      : getFabActionColumns(aggregateList, isWebImport);

    return baseColumns.map((column) => ({ ...column, cellStyle: CELL_STYLE }));
  };

  const columns = getEditorColumns(type);

  const classes = useStyles();
  const tableHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: colors.greyBackground,
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <PropertyEditableTable
          title={title}
          hideExport
          hideFilter
          options={{
            maxBodyHeight: `calc(100vh - ${FAB_TABLE_HEIGHT}px)`,
            headerStyle: tableHeaderStyle,
            search: false,
            sorting: false,
            draggable: false,
            paging: false,
            exportButton: false,
            exportAllData: false,
            showTitle: false,
            toolbar: false,
          }}
          enableBlurBackground
          columns={columns}
          data={actions()}
          onAdd={addAction}
          fileType="propertyList"
          onUpdate={updateAction}
          onDelete={deleteAction}
          tableRef={tableRef}
          configKey={UIAction?.isLink ? KEY : ''}
          reOrderData={reOrderData}
        />
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      padding: '0px 50px',
      backgroundColor: colors.white,
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.greyBorder,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    mainContainer: {
      height: '100%',
      backgroundColor: colors.white,
      width: '100%',
    },
    icon: {
      height: 20,
      width: 20,
    },
  })
);
