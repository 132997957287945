import React, { useMemo, useContext, useCallback } from 'react';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import {
  actionButtonsToRows,
  getCardActionButtonsColumns,
  commandRefObjToList,
  getCommandReferenceArr,
  getAggregatePropertiesList,
  commandActionButtonActionToRef,
} from '../../utils/jsonPartsGenerators';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { ConfigContext } from '../../context/ConfigContext';
import { ConditionalCardActionButton } from '@terragotech/gen5-config-lib';
import { useParams } from 'react-router-dom';
import { chain, cloneDeep } from 'lodash';
import { MobileActionButtonWithID, ActionButtonWithID } from './CardDefinition';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ActionButtonPicker } from '../FormDialog/ActionButtonPicker';
import { LibraryAdd } from '@material-ui/icons';
import { IconButton, makeStyles } from '@material-ui/core';
import { useFormDialog } from '../FormDialog/FormDialogService';
import { ActionButtonRow, ActionButton, CombinedBuiltinActions } from '../../utils/types';
import { CELL_STYLE } from '../../utils/Utils';

interface CardDefinitionConditionalCardProps {
  type: 'mobile' | 'web';
  buttons: Array<ConditionalCardActionButton<CombinedBuiltinActions>>;
  setButtons: (buttons: Array<ConditionalCardActionButton<CombinedBuiltinActions>>) => void;
  conditionalCardTableRef: React.RefObject<MaterialTableRef>;
  titleBarContainer?: string;
}

export const CardDefinitionConditionalCard: React.FC<CardDefinitionConditionalCardProps> = ({
  type,
  buttons,
  setButtons,
  conditionalCardTableRef,
  titleBarContainer,
}) => {
  const clx = useLocalStyles();
  const { config } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const getButtonsArray = useMemo(() => actionButtonsToRows(buttons), [buttons]);
  const { aggrUICustomization: aggrUIName } = useParams() as { aggrUICustomization: string };

  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  const getButtonsColumns = getCardActionButtonsColumns(
    commandRefObjToList(
      getCommandReferenceArr(config, false, getAggregateIndex(config, aggrUIName)),
      additionalActionOptions(type)
    ),
    config.aggregates[getAggregateIndex(config, aggrUIName)]?.properties,
    aggregateProps
  );

  const addButton = useCallback(
    (row: object, resolve: (data: any) => void, reject: () => void) => {
      const newButtons = cloneDeep(buttons);

      newButtons.push(commandActionButtonActionToRef(row as ActionButtonRow));
      setButtons(newButtons);
      resolve(null);
      successMsg('Secondary Button has been successfully added');
    },
    [buttons, setButtons]
  );

  const updateButton = useCallback(
    (newRow: object, oldRow: object, resolve: (data: any) => void, reject: () => void) => {
      const newButtons = cloneDeep(buttons);
      newButtons[
        (oldRow as MobileActionButtonWithID).tableData.id
      ] = commandActionButtonActionToRef(newRow as ActionButtonRow);
      setButtons(newButtons);
      resolve(null);
      successMsg(`Secondary button has been successfully updated`);
    },
    [buttons, setButtons]
  );

  const deleteButton = useCallback(
    (rowToDel: object) => {
      const newButtons = cloneDeep(buttons);
      newButtons.splice((rowToDel as ActionButtonWithID).tableData.id, 1);
      setButtons(newButtons);
      successMsg(`Secondary button has been successfully deleted`);
    },
    [buttons, setButtons]
  );

  const moveRowButton = useCallback(
    (row: ActionButtonWithID, direction: 'UP' | 'DOWN') => {
      const newButtons = cloneDeep(buttons);
      const rowIndex = row.tableData.id;
      if (direction === 'UP') {
        if (rowIndex === 0) return errorMsg(`Secondary button is already at the top`);
        newButtons.splice(rowIndex - 1, 2, newButtons[rowIndex], newButtons[rowIndex - 1]);
        successMsg(`Secondary button item has been moved up`);
      } else {
        if (rowIndex === newButtons.length - 1)
          return errorMsg(`Secondary button item is already at the bottom`);
        newButtons.splice(rowIndex, 2, newButtons[rowIndex + 1], newButtons[rowIndex]);
        successMsg(`Secondary button item has been moved down`);
      }
      setButtons(newButtons);
    },
    [buttons, setButtons]
  );

  const actionButtonsActions = useMemo(
    () => [
      {
        icon: () => <KeyboardArrowUpIcon />,
        tooltip: 'Move up',
        onClick: (_: object, rowData: object) =>
          moveRowButton(rowData as MobileActionButtonWithID, 'UP'),
      },
      {
        icon: () => <KeyboardArrowDownIcon />,
        tooltip: 'Move down',
        onClick: (_: object, rowData: object) =>
          moveRowButton(rowData as MobileActionButtonWithID, 'DOWN'),
      },
    ],
    [moveRowButton]
  );

  const getToolbarItem = useMemo(
    () => (
      <IconButton
        className={clx.toolbarIcon}
        component="span"
        onClick={async () => {
          const actionButtons = await formDialog<typeof ActionButtonPicker>((props) => (
            <ActionButtonPicker
              {...props}
              scanOnlyAggregateName={aggrUIName}
              excludeActionsButtons={buttons as ActionButton[]}
              webOrMobile={type}
            />
          ));
          const newButtons = cloneDeep(buttons);
          newButtons.push(...actionButtons);
          setButtons(newButtons);
          successMsg('Secondary Button has been successfully added');
        }}
      >
        <LibraryAdd />
      </IconButton>
    ),
    [formDialog, buttons, setButtons, aggrUIName, type, clx.toolbarIcon]
  );

  const columns = chain(getButtonsColumns)
    .map((o) => ({
      ...o,
      cellStyle: CELL_STYLE,
    }))
    .value();

  const handleReorder = useCallback(
    (newData: unknown) => {
      setButtons(newData as Array<ConditionalCardActionButton<CombinedBuiltinActions>>);
    },
    [setButtons]
  );

  return (
    <div className={clx.mainContainer}>
      <EditableTable
        title="Buttons"
        columns={columns}
        data={getButtonsArray}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addButton}
        onUpdate={updateButton}
        onDelete={deleteButton}
        options={tableOptions}
        actions={actionButtonsActions}
        toolbarItem={getToolbarItem}
        tableRef={conditionalCardTableRef}
        onReOrder={handleReorder}
        showLinkUnlink
        style={titleBarContainer}
      />
    </div>
  );
};

const additionalActionOptions = (type: 'mobile' | 'web') =>
  type === 'mobile'
    ? ['directions', 'streetView', 'assign', 'updateLocation']
    : ['csvExport', 'directions', 'streetView', 'pdfExport'];

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  sorting: false,
  draggable: false,
};

const useLocalStyles = makeStyles({
  container: {
    marginTop: 20,
  },
  toolbarIcon: { padding: 0, marginRight: 20 },
  mainContainer: { paddingTop: 29, paddingBottom: 18 },
});
